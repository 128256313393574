import { Button, Icon, Heading } from '@amzn/storm-ui';
import { check } from '@amzn/storm-ui-icons';
import { FC, memo, useContext, useEffect, useRef, useState } from 'react';
import CardLoader from 'src/components/common/storm/CardLoader';
import { FeedbackContext, FeedbackScope, SubmittedFeedback, useFeedbackContext } from 'src/components/feedback/FeedbackContext';
import { FeedbackThumbsControl, IconColor } from 'src/components/feedback/FeedbackThumbsControl';
import { Image, ImageGroup, ImageState, ImageWithThumbnails, ImageWithThumbnailsRef } from 'src/components/image/ImageWithThumbnails';
import { ImageModalContext } from 'src/components/imageModal';
import { ImageModalErrorCard } from 'src/components/imageModal/components/ImageModalErrorCard';
import { AssetType, WorkflowId } from 'src/components/imageModal/components/utils';
import { getAssetTypeText } from 'src/components/utils/assetUtils';
import { WorkflowSubmission } from 'src/components/utils/WorkflowSubmission';
import styled from 'styled-components';
import { v6 as uuidV6 } from 'uuid';
import styles from './ImageModalImageViewer.module.scss';

export const PrimaryButton = styled(Button)`
  && {
    border: none;
    background-color: var(--text-color-variant-6);
    color: var(--standard-button-color-dark);
    transition: background-colour 0.3s;
  }

  &&:hover {
    background-color: var(--AI-purple, #4305f4);
    color: white;
  }

  &&:disabled {
    background-color: var(--disabled-button-color);
  }
`;

export interface DefaultImageModalImageViewerProps {
  sourceImageUrl: string;
  handleAcceptEdits?: () => void;
  reframeAspectRatio?: string;
  reframeDirection?: string;
}

const DefaultImageModalImageViewer: FC<DefaultImageModalImageViewerProps> = ({
  sourceImageUrl,
  handleAcceptEdits,
  reframeAspectRatio,
  reframeDirection,
}) => {
  const imageWithThumbnailsRef = useRef<ImageWithThumbnailsRef | null>(null);
  const [imageWithThumbnailsIsLoading, setImageWithThumbnailsIsLoading] = useState<boolean>(true);
  const imageModalContext = useContext(ImageModalContext);
  const activeEditResults = imageModalContext.activeEditResults;
  const activeEditErrorMessage = imageModalContext.activeEditErrorMessage;
  const job = activeEditResults?.jobs?.[0];
  const jobHasResults = !!job?.urls?.length;
  const isJobFailed = job?.status === WorkflowSubmission.FAILED;
  const isJobCompleted = job?.status === WorkflowSubmission.COMPLETED;
  const isGeneratingResults = imageModalContext.pendingGeneration;
  const isIdle = !activeEditResults && !isGeneratingResults && !isJobFailed && !activeEditErrorMessage;
  const canAccept = isJobCompleted && !!imageModalContext.activeEditsImageUrl;
  const isPublishingAsset = imageModalContext.isPublishingAsset;
  const [isOriginalSelected, setIsOriginalSelected] = useState(false);

  const onSubmittedFeedback = (submittedFeedback: SubmittedFeedback) => {
    if (imageModalContext.activeEditsImageReferenceId === submittedFeedback.feedbackMetadata?.referenceId) {
      imageModalContext.setActiveEditsImageFeedback(submittedFeedback.feedback);
    }
  };

  const feedbackContext = useFeedbackContext({
    feedbackScope: FeedbackScope.ASSET,
  });

  useEffect(() => {
    if (isGeneratingResults) return;
    if (job && job.urls?.length && job.originalUrls?.length) {
      const images: Image[] = job.urls.map((url: string, index: number) => ({
        // Generating an asset ID since job.ids is no longer populated
        referenceId: uuidV6(),
        originalUrl: job.originalUrls![index],
        url,
      }));

      imageWithThumbnailsRef.current?.setOriginalImage({ url: sourceImageUrl, referenceId: 'originalImage', originalUrl: '' });
      imageWithThumbnailsRef.current?.setGeneratedImages(images);
    } else {
      imageWithThumbnailsRef.current?.setGeneratedImages([]);
    }
  }, [job]);

  const onChangeImageWithThumbnailsIsLoading = (isLoading: boolean) => {
    setImageWithThumbnailsIsLoading(isLoading);
  };

  const onChangeSelectedImage = (selectedImage: ImageState | undefined) => {
    if (selectedImage) {
      setIsOriginalSelected(selectedImage.group === ImageGroup.ORIGINAL);
      imageModalContext.setActiveEditsImageFeedback(selectedImage.feedback);
      imageModalContext.setActiveEditsImageReferenceId(selectedImage.image.referenceId);
      imageModalContext.setActiveEditsImageUrl(selectedImage.image.url);
      imageModalContext.setActiveEditsOriginalImageUrl(selectedImage.image.originalUrl);
    } else {
      imageModalContext.setActiveEditsImageFeedback(undefined);
      imageModalContext.setActiveEditsImageReferenceId(undefined);
      imageModalContext.setActiveEditsImageUrl(undefined);
      imageModalContext.setActiveEditsOriginalImageUrl(undefined);
    }
  };

  const getReframeDirectionStyleFlexAlign = (direction: string | undefined) => {
    if (!direction) return 'center';
    if (direction.endsWith('right') || direction.endsWith('down')) {
      return 'flex-start';
    }
    if (direction.endsWith('left') || direction.endsWith('up')) {
      return 'flex-end';
    }
    return 'center';
  };

  return (
    <FeedbackContext.Provider value={feedbackContext}>
      <div className={styles.container} data-testid="ImageModalImageViewer-container">
        {isIdle && (
          <div
            data-testid="ImageModalImageViewer-aspectRatioWrapper"
            className={reframeAspectRatio ? styles.reframeWrapper : undefined}
            style={
              reframeAspectRatio
                ? {
                    aspectRatio: reframeAspectRatio,
                    justifyContent: getReframeDirectionStyleFlexAlign(reframeDirection),
                    alignItems: getReframeDirectionStyleFlexAlign(reframeDirection),
                  }
                : undefined
            }
          >
            <img className={styles.originalImage} src={sourceImageUrl} alt="Image modal main image" />
          </div>
        )}
        {isGeneratingResults && <CardLoader style={{ alignItems: 'flex-start' }} />}
        {isJobFailed && <ImageModalErrorCard message={job?.message || 'Something went wrong'} />}
        {!!activeEditErrorMessage && <ImageModalErrorCard message={activeEditErrorMessage || 'Something went wrong'} />}
        {isJobCompleted && !jobHasResults && (
          <ImageModalErrorCard message={`We're sorry, your images were filtered out due to policy. Please reformulate your inputs and try again.`} />
        )}
        {isJobCompleted && (
          <div className={styles.resultsContainer}>
            <div className={styles.header}>
              <div className={styles.headerDetails}>
                <Heading className={styles.headerText} renderAs="h3">
                  {getAssetTypeText(imageModalContext.activeEditsAssetType, { useCapital: true })} results
                </Heading>
                {imageModalContext.activeEditsAssetType === AssetType.VIDEO && <FeedbackThumbsControl iconColor={IconColor.BLACK} />}
              </div>
              {isOriginalSelected ? (
                <div className={styles.resultButtons}>
                  <PrimaryButton
                    className={styles.primaryButton}
                    data-testid={'studio-reset-to-original-button'}
                    disabled={isPublishingAsset}
                    onClick={() => {
                      imageModalContext.clearActiveEdit();
                    }}
                  >
                    <Icon type={check} />
                    Reset to original
                  </PrimaryButton>
                </div>
              ) : (
                <div className={styles.resultButtons}>
                  <PrimaryButton
                    className={styles.resetButton}
                    disabled={isPublishingAsset}
                    onClick={() => {
                      imageModalContext.clearActiveEdit();
                    }}
                  >
                    Reset
                  </PrimaryButton>
                  <PrimaryButton
                    className={styles.primaryButton}
                    data-testid={'studio-edit-accept-button'}
                    disabled={!canAccept || imageWithThumbnailsIsLoading || isPublishingAsset}
                    onClick={() => {
                      handleAcceptEdits?.();
                    }}
                  >
                    {isPublishingAsset ? (
                      <>Saving</>
                    ) : (
                      <>
                        <Icon type={check} /> Save {getAssetTypeText(imageModalContext.activeEditsAssetType)}
                      </>
                    )}
                  </PrimaryButton>
                </div>
              )}
            </div>
            <ImageWithThumbnails
              assetType={imageModalContext.activeEditsAssetType || AssetType.IMAGE}
              workflowId={imageModalContext.activeEditsWorkflowId || WorkflowId.IMAGE_EDITOR}
              onChangeIsLoading={onChangeImageWithThumbnailsIsLoading}
              onChangeSelectedImage={onChangeSelectedImage}
              onSubmittedFeedback={onSubmittedFeedback}
              ref={imageWithThumbnailsRef}
            />
          </div>
        )}
      </div>
    </FeedbackContext.Provider>
  );
};

export const ImageModalImageViewer = memo(DefaultImageModalImageViewer);
