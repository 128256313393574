import { Icon as StormIconComponent } from '@amzn/storm-ui';
import { useEffect, useRef, useState } from 'react';
import { checkColorStringFormat, getDataTestId } from 'src/v2/utils/utils';
import styled from 'styled-components';
import { iconTypes } from './iconTypes';
import { IconProps } from './types';

export const IconWrapper = styled.div<{ size?: string; matchFontSize?: boolean }>`
  font-size: ${({ size, matchFontSize }) => {
    if (matchFontSize) {
      return 'inherit';
    }
    return size ?? '24px';
  }};
`;

export const Icon = (props: IconProps) => {
  const {
    backgroundColor = '#F5F3EF',
    borderRadius = 999,
    color = '#000000',
    darkMode = false,
    dataTestId,
    matchFontSize,
    paddingPercent = 30,
    showBackground,
    size,
    type,
  } = props;
  const iconName = typeof type === 'string' ? type : type.iconName;
  const stringAsIcon = typeof type === 'string' ? Object.values(iconTypes).find((value) => value.iconName === type) : null;
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  // TODO: refactor into styled-components format and convert color codes to theme config when ready
  const style = {
    ...(showBackground
      ? {
          color: checkColorStringFormat(color),
          backgroundColor: checkColorStringFormat(backgroundColor),
          padding: `${(paddingPercent / 100) * height}px`,
          borderRadius: `${borderRadius}px`,
        }
      : {
          color: darkMode ? '#161D26' : '#000000',
          background: 'none',
        }),
    ...(darkMode && {
      color: !showBackground && darkMode ? '#ffffff' : '#000000',
    }),
  };

  const otherIconProps = {
    size,
    type: stringAsIcon || type,
    dataTestId,
    style,
  };

  // gets the SVG icon element size for calculating the proper padding percent overrides
  useEffect(() => {
    if (ref.current) {
      const hasDataTestId = dataTestId && dataTestId.length > 0;
      const iconElement = document.querySelector(`[data-testid='${hasDataTestId ? dataTestId : `storm-ui-icon-${iconName}`}']`)?.firstElementChild;
      const iconElementSize = iconElement?.nodeName === 'svg' && iconElement?.clientWidth > 0 ? iconElement?.clientWidth : 0;
      setHeight(iconElementSize);
    }
  }, [size, type, paddingPercent, borderRadius]);

  return (
    <IconWrapper size={size} matchFontSize={matchFontSize} ref={ref}>
      <StormIconComponent data-testid={getDataTestId(dataTestId) || undefined} {...otherIconProps} />
    </IconWrapper>
  );
};
