import { TextAreaFormGroup } from '@amzn/storm-ui';
import { FocusEvent, MouseEvent, useContext, useEffect, useState } from 'react';
import { AnimatedButton } from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/AnimatedButton';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import { ImageModalContext } from 'src/components/imageModal/ImageModalContext';
import { HEADING_TEXT_SIZE } from 'src/constants';
import { sanitiseText } from 'src/helpers';
import { ImageCategory, StyleAction, useWorkflow, WorkflowId } from '../utils';
import styles from './RemixControls.module.scss';
import { AppContext } from 'src/../AppContext';

export const RemixControls = () => {
  const { imageReferenceId, imageUrl, savedEditsImageUrl, clearActiveEdit, setActiveEditResults, setActiveEditErrorMessage, setPendingGeneration } =
    useContext(ImageModalContext);

  const imageRef = savedEditsImageUrl === '' && !imageUrl.includes('catwalk-generated-assets') ? imageReferenceId : savedEditsImageUrl || imageUrl;
  const { activePage, userDetails } = useContext(AppContext);
  const [prompt, setPrompt] = useState<string>();

  const {
    updateWorkflowOptions,
    submissionQuery: { data: result },
    submitWorkflow,
    isPending,
    error,
    isError,
  } = useWorkflow<StyleAction>({
    workFlowId: WorkflowId.IMAGE_THEMING,
    userAlias: userDetails?.alias,
    pageName: activePage,
    defaultWorkflowOptions: {
      theme: 'no_theme',
      input_image: '2770e255-1253-46a0-8524-32fc98a757a3', // needed for testing purposes only on local environment
    },
    setPendingGeneration,
  });

  useEffect(() => {
    if (result) {
      setActiveEditResults(result);
    } else {
      setActiveEditResults(undefined);
    }
  }, [result?.jobs?.[0].status]);

  useEffect(() => {
    if (isError) {
      setActiveEditErrorMessage(error?.message);
    }
  }, [isError]);

  const generateResults = async () => {
    clearActiveEdit();
    await submitWorkflow({
      input_image: {
        urlOrFile: imageRef,
        contentCategory: ImageCategory.PRODUCT_IMAGE,
        fileTypeOverride: 'image/png',
      },
    });
  };

  const handleTextPrompt = (e: FocusEvent | MouseEvent) => {
    const sanitizedPrompt = sanitiseText((e.target as HTMLTextAreaElement).value);
    setPrompt(sanitizedPrompt);
    updateWorkflowOptions({ theme: prompt });
  };

  return (
    <div className={styles.remixControlsWrapper}>
      <div className={styles.promptInputWrapper}>
        <ControlLabel title={'Prompt'} subTitle="Remix your image with new concepts by entering a prompt." titleSize={HEADING_TEXT_SIZE.SMALL} />
        <div className={styles.promptInput}>
          <TextAreaFormGroup
            id="studio-edit-remix-prompt"
            data-testid="studio-edit-remix-prompt"
            onBlur={handleTextPrompt}
            onMouseLeave={handleTextPrompt}
            label=""
            hideLabel={true}
            inline={false}
            placeholder="Add a prompt"
          />
        </div>
      </div>

      <div className={styles.generateButtonWrapper}>
        <AnimatedButton
          dataTestId="studio-edit-generate-button"
          animate={isPending}
          disabled={!prompt || isPending}
          clickHandler={generateResults}
          text={isPending ? 'Generating' : 'Generate'}
          fullscreen
        ></AnimatedButton>
      </div>
    </div>
  );
};
