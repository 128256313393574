import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/v2/redux/store';
import { PreviewSliceState } from './previewTypes';

const initialState: PreviewSliceState = {
  activeAssetId: '',
};

export const previewSlice = createSlice({
  name: 'preview',
  initialState,
  reducers: {
    setActiveAssetId: (state, action) => {
      state.activeAssetId = action.payload;
    },
  },
  selectors: {
    getActiveAssetId: (state) => state.activeAssetId,
  },
});

export const { setActiveAssetId } = previewSlice.actions;
export const { getActiveAssetId } = previewSlice.selectors;

export default previewSlice.reducer;

export const previewActiveAssetId = (state: RootState) => state.preview.activeAssetId;
export const feedContentEntities = (state: RootState) => state.feedContent.entities;

export const getActiveAsset = createSelector(previewActiveAssetId, feedContentEntities, (id, entities) => {
  return entities[id] ?? undefined;
});
