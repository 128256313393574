import { AsinCategoryEnum } from '@amzn/genaihub-typescript-client';
import { ThemeProvider } from '@amzn/storm-ui';
import { useContext, useState, useEffect, memo } from 'react';
import ContentTilesContainer from 'src/components/pages/studio/contentTile/ContentTilesContainer';
import EffectsSetting from 'src/components/pages/studio/effectSettings/EffectsSetting';
import { ASINItem, JobStatusEnum, StudioContext, useStudioContext } from 'src/components/pages/studio/StudioContext';
import StyleSetting from 'src/components/pages/studio/StyleSetting';
import UserInputContainer from 'src/components/pages/studio/UserInputContainer';
import { getAllQueryStringParams } from 'src/components/utils/getQueryStringParams';
import { restrictedJobStatusMessage, US_MARKETPLACE_ID } from 'src/constants';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import SliderIcon from 'src/icons/SliderIcon.svg';
import { StudioFileUploadDropzone } from 'src/v2/components/studio/fileUploadDropzone';
import { StudioImportImageButton } from 'src/v2/components/studio/importImageButton';
import { useImportImage } from 'src/v2/hooks/useImportImage/useImportImage';
import styles from './StudioPage.module.scss';

const StudioPageContent = () => {
  const [showPanel, toggleSidePanel] = useState<boolean>(false);
  const backendClient = useAIBackendHubClient();
  const importImageHook = useImportImage();
  const studioContext = useContext(StudioContext);

  useEffect(() => {
    const urlProps = getAllQueryStringParams();
    if (urlProps.generate && (urlProps.prompt || urlProps.ASIN)) {
      studioContext.setAutoGenerate(true);
      if (urlProps.prompt) {
        studioContext.setTextPrompt(urlProps.prompt);
      } else if (urlProps.ASIN) {
        fetchASIN(urlProps.ASIN);
      }
      history.replaceState({}, document.title, window.location.origin + window.location.pathname);
    }
  }, []);

  const fetchASIN = async (asin: string) => {
    try {
      const fetchedAsin = (
        await backendClient.retrieveASINMetadataByASINId({
          asinId: asin,
          marketplaceId: US_MARKETPLACE_ID,
        })
      ).body;
      const newAsinItem: ASINItem = {
        asin: asin,
        metadata: fetchedAsin,
        selectedImageIndex: 0,
      };

      if (fetchedAsin.asinCategory === AsinCategoryEnum.RESTRICTED) {
        const jobStatus = studioContext.jobStatus ?? { status: JobStatusEnum.LOADING_ASIN };
        if (jobStatus?.status === JobStatusEnum.LOADING_ASIN) {
          studioContext.setJobStatus({
            ...jobStatus,
            message: restrictedJobStatusMessage,
          });
        }
        throw new Error('Restricted ASIN');
      }

      studioContext.setAsin(newAsinItem);
      studioContext.setAutoGenerate(false);
    } catch (err) {
      console.error('Error while loading ASIN from Hero', err);
      studioContext.setAutoGenerate(false);
    }
  };

  return (
    <div className={`${styles.pageContainer} ${styles.bringYourOwnImage}`} data-testid="studio-container">
      <div className={styles.toolbar}>
        <StudioImportImageButton onSelectFiles={importImageHook.handleImportFiles} />
      </div>
      <div className={`${styles.panel} ${showPanel ? styles.show : ''}`} data-testid="studio-style-container">
        <div className={styles.panelToggle}>
          <div
            data-testid="studio-style-container-toggle-button"
            role="button"
            className={styles.panelToggleButton}
            onClick={() => toggleSidePanel((v) => !v)}
          >
            <SliderIcon />
          </div>
        </div>
        <StyleSetting />
        <div style={{ height: '30px' }}></div>
        <EffectsSetting />
      </div>
      <div className={styles.canvas} data-testid="studio-canvas-container">
        <ContentTilesContainer />
        <StudioFileUploadDropzone onDropFiles={importImageHook.handleImportFiles} />
        <div className={styles.userInputs} data-testid="studio-inputs-container">
          <UserInputContainer ref={studioContext.userInputContainerRef} />
        </div>
      </div>
    </div>
  );
};

const StudioPage = memo(() => {
  const studioContext = useStudioContext();
  return (
    <StudioContext.Provider value={studioContext}>
      <ThemeProvider>
        <StudioPageContent />
      </ThemeProvider>
    </StudioContext.Provider>
  );
});
StudioPage.displayName = 'StudioPage';

export default StudioPage;
