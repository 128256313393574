import { Feedback as FeedbackModel } from '@amzn/genaihub-typescript-client';
import { ContentItem, Message } from '@amzn/red-carpet-service-client';
import { Button, Icon, Menu, MenuItemButton, Modal, Text, useUID } from '@amzn/storm-ui';
import { ellipsisV, IconDefinition, thumbsDownSolid, thumbsUpSolid, thumbsDown, thumbsUp } from '@amzn/storm-ui-icons';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect, useRef, useState } from 'react';
import Lottie, { Options } from 'react-lottie';
import starsAnimation from 'src/animations/Star C Generating.json';
import Feedback from 'src/components/assistant/components/Feedback';
import { FEEDBACK_BODY, FEEDBACK_TITLE } from 'src/components/assistant/components/Feedback/constants';
import useFeedback from 'src/components/assistant/queries/useFeedback';
import { CHAT_THEME } from 'src/components/assistant/theme';
import { Metrics } from 'src/constants';
import styled from 'styled-components';
import { AppContext } from '../../../../../AppContext';

const IconButtonsWrapper = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  pointer-events: auto;
`;

const IconButton = styled(Button).attrs({ transparent: true })`
  pointer-events: auto;
  && {
    color: #ffffff;
    margin: -5px -14px;

    background: none !important;
    background-color: none !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  &&:hover,
  &&:active {
    color: #ced3dc;
    background: none;
    border: none !important;
    outline: none !important;
  }
`;

const ControlsWrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 18px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  pointer-events: none;
`;

const ImageCaptions = styled(Text)`
  pointer-events: auto;
  font-size: 18px;
  font-style: italic;
  line-height: 21.55px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  line-clamp: 9;
  -webkit-box-orient: vertical;
  user-select: none;
  pointer-events: none;
`;

const StyledImage = styled.img`
  height: auto;
  width: 100%;
  border-radius: ${CHAT_THEME.border.rounded.radius};
`;

const StyledLoadingText = styled(Text)`
  font-size: 15px;
  font-weight: 100;
  line-height: 18px;
  text-align: left;
  color: #ffffff;
`;

const LoadingMessagePrefix = styled.span`
  font-weight: 800;
`;

const ImageWrapper = styled.div<{ loaded: boolean }>`
  position: relative;
  border-radius: ${CHAT_THEME.border.rounded.radius};
  width: calc(50% - 6px);
  aspect-ratio: 1.91/1;
  overflow: hidden;

  ${ControlsWrapper} {
    visibility: hidden;
    border-radius: ${CHAT_THEME.border.rounded.radius};
    pointer-events: none;
  }

  :hover,
  :focus-within {
    ${ControlsWrapper} {
      visibility: unset;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(0deg, rgba(67, 5, 244, 0.3), rgba(67, 5, 244, 0.3));
    }
  }
`;

const Loading = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(105deg, #6442ce 0%, #25a5e1 98%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${CHAT_THEME.border.rounded.radius};
  color: #ffffff;
`;

const ModalImage = styled.img`
  width: 100%;
  max-height: calc(100vh - 13.5rem);
`;

const ImageModal = styled(Modal)`
  cursor: auto;

  div:has(${ModalImage}) {
    padding: 0;
    display: flex;
    position: relative;

    ${ControlsWrapper} {
      opacity: 0;
      transition: opacity 0.3s ease-in;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, 0.5) 100%),
        linear-gradient(0deg, rgba(67, 5, 244, 0.5) 0%, rgba(67, 5, 244, 0) 35%, rgba(67, 5, 244, 0) 85%, rgba(67, 5, 244, 0.5) 100%);

      ${ImageCaptions} {
        text-shadow: #000 0px 0px 5px;
      }
    }

    :hover {
      ${ControlsWrapper} {
        opacity: 1;
      }
    }

    :focus-within {
      #isInitiated {
        opacity: 1;
      }
    }
  }
`;

const ModalTrigger = styled(Button).attrs({ transparent: true })`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: ${CHAT_THEME.border.rounded.radius};

  background: none !important;
  background-color: none !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;

  :active,
  :hover {
    background: none !important;
    background-color: none !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
`;

interface LoadingCardProps {
  loadingMessagePrefix?: string;
  loadingMessage?: string;
}

function LoadingCard({ loadingMessagePrefix = 'Generating', loadingMessage }: LoadingCardProps) {
  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: starsAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Loading>
      <Lottie options={defaultOptions} style={{ margin: 0 }} isClickToPauseDisabled width={40} height={40} />
      <StyledLoadingText>
        <LoadingMessagePrefix>{loadingMessagePrefix}</LoadingMessagePrefix> {loadingMessage}
      </StyledLoadingText>
    </Loading>
  );
}

interface ImageControlsProps {
  message: Message;
  contentItemIndex: number;
  onReply?: (contentItem: ContentItem[]) => void;
  onSave: (contentItem: ContentItem[]) => void;
}

function ImageControls({ message, contentItemIndex, onReply, onSave }: ImageControlsProps) {
  const { metrics } = useContext(AppContext);
  const imageMenuUid = useUID('image-menu');
  const [menuOpen, setMenuOpen] = useState(false);
  const [isInitiated, setIsInitiated] = useState(false);
  const { feedback, setFeedback } = useFeedback(message, contentItemIndex);
  const contentItem = message.content && message.content[contentItemIndex];
  const isEntityIdSelected = !!useContext(AppContext)?.selectedAdvertisingAccount?.alternateIds?.[0];

  const onDownload = () => {
    if (!contentItem?.image?.source) return;

    const link = document.createElement('a');
    link.download = contentItem.image.source;
    link.href = contentItem.image.source;
    link.target = '_blank';
    link.click();

    metrics.trackMetrics(
      Metrics.Methods.ChatImage,
      {},
      { [Metrics.Counters.Download]: 1 },
      { [Metrics.Dimensions.ConversationId]: message.conversationId || '' },
    );
  };

  const saveImage = () => {
    if (!contentItem) return;

    setIsInitiated(true);
    onSave([contentItem]);

    metrics.trackMetrics(
      Metrics.Methods.ChatImage,
      {},
      { [Metrics.Counters.Save]: 1 },
      { [Metrics.Dimensions.ConversationId]: message.conversationId || '' },
    );
  };

  const onSubmit = (feedback: FeedbackModel) => {
    setIsInitiated(true);
    setFeedback(feedback);
  };

  const onMenuOpen = () => {
    setIsInitiated(true);
    setMenuOpen(true);
  };

  if (!contentItem) {
    return null;
  }

  return (
    <ControlsWrapper id={isInitiated ? 'isInitiated' : ''}>
      <Header>
        <IconButtonsWrapper>
          {onReply && (
            <IconButton onClick={() => onReply([contentItem])}>
              <Icon type={faReply as IconDefinition} size="lg" />
            </IconButton>
          )}
          <Feedback
            icon={feedback == 'LIKE' ? thumbsUpSolid : thumbsUp}
            title={FEEDBACK_TITLE}
            body={FEEDBACK_BODY}
            feedback={'LIKE'}
            message={message}
            onSubmit={onSubmit}
            imageSource={contentItem.image?.source}
            inverted
          />
          <Feedback
            icon={feedback == 'DISLIKE' ? thumbsDownSolid : thumbsDown}
            title={FEEDBACK_TITLE}
            body={FEEDBACK_BODY}
            feedback={'DISLIKE'}
            message={message}
            onSubmit={onSubmit}
            imageSource={contentItem.image?.source}
            inverted
          />
        </IconButtonsWrapper>
        <Menu
          id={imageMenuUid}
          onClick={onMenuOpen}
          onClose={() => setMenuOpen(false)}
          isOpen={menuOpen}
          trigger={
            <IconButton data-testid="image-menu-button">
              <Icon type={ellipsisV} size="lg" />
            </IconButton>
          }
        >
          <MenuItemButton data-testid="image-menu-download" onClick={onDownload}>
            Download
          </MenuItemButton>
          {isEntityIdSelected ? <MenuItemButton onClick={saveImage}>Save to my Ads account</MenuItemButton> : <></>}
        </Menu>
      </Header>
      <ImageCaptions>{contentItem.image?.description}</ImageCaptions>
    </ControlsWrapper>
  );
}

interface Props {
  message: Message;
  contentItemIndex: number;
  onReply: (contentItem: ContentItem[]) => void;
  onSave: (contentItem: ContentItem[]) => void;
}

export default function Image({ contentItemIndex, onReply, onSave, message }: Props) {
  const contentItem = message.content && message.content[contentItemIndex];
  const [modalOpan, setModalOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const modalTriggerRef = useRef<HTMLButtonElement>();

  if (!contentItem) {
    return null;
  }

  useEffect(() => {
    modalTriggerRef.current?.blur();
  }, [modalOpan]);

  return (
    <ImageWrapper data-testid="image-wrapper" loaded={loaded}>
      {!contentItem.image?.source && <LoadingCard loadingMessage={contentItem.image?.description} />}
      <ModalTrigger disabled={!loaded} onClick={() => setModalOpen(true)} buttonRef={(el) => (modalTriggerRef.current = el)}>
        <Text srOnly>Open image</Text>
      </ModalTrigger>
      {contentItem.image?.source && (
        <>
          <StyledImage src={contentItem.image?.source} onLoad={() => setLoaded(true)} />
          <ImageControls onReply={onReply} onSave={onSave} message={message} contentItemIndex={contentItemIndex} />
        </>
      )}
      <ImageModal isOpen={modalOpan} onClose={() => setModalOpen(false)} hasCloseButton={false} toggleEl={modalTriggerRef.current}>
        <ModalImage src={contentItem.image?.source} />
        <ImageControls
          onSave={(contentItems: ContentItem[]) => {
            setModalOpen(false);
            onSave(contentItems);
          }}
          message={message}
          contentItemIndex={contentItemIndex}
        />
      </ImageModal>
    </ImageWrapper>
  );
}
