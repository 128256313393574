import React from 'react';

export enum SnackbarType {
  ERROR = 'error',
  INFO = 'info',
  LOADING = 'loading',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export interface SnackbarNotification {
  id: string;
  disableAutoDismissal?: boolean;
  type: SnackbarType;
  SnackbarContent: React.FC;
}

export interface TimeoutRef {
  [key: string]: {
    id: NodeJS.Timeout;
    readyToClear: boolean;
  };
}

export enum SnackbarActionType {
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
  REMOVE_ALL_NOTIFICATIONS = 'REMOVE_ALL_NOTIFICATIONS',
  SET_EXPANDED = 'SET_EXPANDED',
}

export interface SnackbarState {
  notifications: SnackbarNotification[];
  expanded: boolean;
}
export interface SnackbarActionAddNotification {
  type: SnackbarActionType.ADD_NOTIFICATION;
  payload: SnackbarNotification;
}

export interface SnackbarActionRemoveNotification {
  type: SnackbarActionType.REMOVE_NOTIFICATION;
  payload: string;
}

export interface SnackbarActionRemoveAllNotifications {
  type: SnackbarActionType.REMOVE_ALL_NOTIFICATIONS;
}

export interface SnackbarActionSetExpanded {
  type: SnackbarActionType.SET_EXPANDED;
  payload: boolean;
}

export type SnackbarAction =
  | SnackbarActionAddNotification
  | SnackbarActionRemoveNotification
  | SnackbarActionRemoveAllNotifications
  | SnackbarActionSetExpanded;

export type ActionHandlerReturn = void | SnackbarState;
