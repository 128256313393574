import { Draft, produce } from 'immer';
import React, { createContext, useCallback, useEffect, useReducer, useRef, Dispatch } from 'react';
import { handleActionAddNotification } from 'src/v2/contexts/snackbar/actions/AddNotification';
import { handleActionRemoveAllNotifications } from 'src/v2/contexts/snackbar/actions/RemoveAllNotifications';
import { handleActionRemoveNotification } from 'src/v2/contexts/snackbar/actions/RemoveNotification';
import { handleActionSetExpanded } from 'src/v2/contexts/snackbar/actions/SetExpanded';
import { SnackbarState, SnackbarAction, SnackbarActionType, TimeoutRef } from 'src/v2/contexts/snackbar/types';

export const PROCESSING_TIMEOUT = 3000;
export const SNACKBAR_DISMISSAL_TIMEOUT = 5000;

const initialState: SnackbarState = {
  notifications: [],
  expanded: false,
};

export const useSnackbarContext = (props?: {
  dismissalTimeout?: number;
}): {
  state: SnackbarState;
  dispatch: Dispatch<SnackbarAction>;
} => {
  const { dismissalTimeout = SNACKBAR_DISMISSAL_TIMEOUT } = props || {};
  const timeoutsRef = useRef<TimeoutRef>({});
  const expandedRef = useRef<boolean>(false);
  const dispatchRef = useRef<Dispatch<SnackbarAction>>();

  const snackbarReducer = (state: SnackbarState, action: SnackbarAction): SnackbarState => {
    return produce(state, (draft: Draft<SnackbarState>) => {
      switch (action.type) {
        case SnackbarActionType.ADD_NOTIFICATION:
          return handleActionAddNotification({
            action,
            state: draft,
            timeoutsRef: timeoutsRef.current,
            dismissalTimeout,
            dispatch: dispatchRef.current!,
            expandedRef,
          });
        case SnackbarActionType.REMOVE_NOTIFICATION:
          return handleActionRemoveNotification({
            action,
            state: draft,
            timeoutsRef: timeoutsRef.current,
          });
        case SnackbarActionType.REMOVE_ALL_NOTIFICATIONS:
          return handleActionRemoveAllNotifications({
            state: draft,
            timeoutsRef: timeoutsRef.current,
          });
        case SnackbarActionType.SET_EXPANDED:
          expandedRef.current = action.payload;
          return handleActionSetExpanded({
            action,
            state: draft,
            timeoutsRef: timeoutsRef.current,
            dispatch: dispatchRef.current!,
          });
      }
    });
  };

  const [state, dispatch] = useReducer(snackbarReducer, initialState);

  useEffect(() => {
    dispatchRef.current = dispatch;
  }, [dispatch]);

  useEffect(() => {
    expandedRef.current = state.expanded;
  }, [state.expanded]);

  useEffect(() => {
    return () => {
      Object.values(timeoutsRef.current).forEach(({ id }) => clearTimeout(id));
    };
  }, []);

  const memoDispatch = useCallback(dispatch, []);

  return {
    state,
    dispatch: memoDispatch,
  };
};

export type SnackbarStateContextType = ReturnType<typeof useSnackbarContext>['state'];
export type SnackbarDispatchContextType = ReturnType<typeof useSnackbarContext>['dispatch'];

export const SnackbarStateContext = createContext<SnackbarStateContextType>({
  notifications: [],
  expanded: false,
});
export const SnackbarDispatchContext = createContext<SnackbarDispatchContextType>(() => {});
interface SnackbarProviderProps {
  children: React.ReactNode;
  dismissalTimeout?: number;
}

export const SnackbarContextProvider: React.FC<SnackbarProviderProps> = ({ children, dismissalTimeout }): React.JSX.Element => {
  const { state, dispatch } = useSnackbarContext({ dismissalTimeout });

  return (
    <SnackbarStateContext.Provider value={state}>
      <SnackbarDispatchContext.Provider value={dispatch}>{children}</SnackbarDispatchContext.Provider>
    </SnackbarStateContext.Provider>
  );
};
