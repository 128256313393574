import {
  ContentItem,
  ListConversationMessagesCommand,
  ListConversationsCommand,
  Persona,
  QuotedMessage,
  RedCarpetClient as RedCarpetClientBase,
  SendConversationMessageCommand,
  ServiceInputTypes,
  StartConversationCommand,
  Topic,
} from '@amzn/red-carpet-service-client';
import { HttpRequest, HttpResponse } from '@aws-sdk/protocol-http';
import { BuildHandlerArguments, DeserializeHandlerArguments } from '@aws-sdk/types';
import { apiConversationToConversationModel, ConversationModel } from 'src/components/assistant/model';
import {
  addNewMessages,
  LoadingMessageConfig,
  moveNewChatQueryData,
  setQueryEnabled,
  updateLoadingMessageConfig,
} from 'src/components/assistant/queries/conversationMessages';
import { updateConversation } from 'src/components/assistant/queries/conversationsList';
import { NEW_CHAT_PARAM_KEY, CHAT_ID_PARAM_KEY } from 'src/components/assistant/utils/useConversationId';
import { CSRF_META_NAME } from 'src/constants';
import { router } from 'src/index';
import { CsrfTokenUtils } from 'src/util/csrfTokenUtils';
import { getApiBaseUrl } from 'src/util/util';
import getAssetUri from './utils/getAssetUri';

export class RedCarpetClient {
  BASE_URL =
    (typeof process !== 'undefined' && process?.env?.CREATIVE_AGENT_LOCAL === 'true' ? 'http://localhost:8000' : getApiBaseUrl()) +
    '/api/conversational';
  client: RedCarpetClientBase;

  constructor() {
    this.client = new RedCarpetClientBase({ endpoint: this.BASE_URL });
    this.client.middlewareStack.remove('awsAuthMiddleware');

    this.client.middlewareStack.add(
      (next) => async (args: BuildHandlerArguments<ServiceInputTypes>) => {
        if (HttpRequest.isInstance(args.request)) {
          args.request.headers[CSRF_META_NAME] = CsrfTokenUtils.getCSRFTokenFromMetadata();
        }

        return await next(args);
      },
      {
        step: 'build',
        name: 'csrfMiddleware',
      },
    );

    this.client.middlewareStack.add(
      (next) => async (args: DeserializeHandlerArguments<ServiceInputTypes>) => {
        const result = await next(args);
        if (HttpResponse.isInstance(result.response)) {
          CsrfTokenUtils.renewCSRFTokenMetadata(new Headers(result.response.headers));
        }
        return result;
      },
      {
        step: 'deserialize',
        name: 'csrfRefreshMiddleware',
      },
    );
  }

  public async listConversations(userId?: string): Promise<ConversationModel[]> {
    const res = await this.client.send(new ListConversationsCommand({ userId }));
    return res.conversations?.map(apiConversationToConversationModel) || [];
  }

  public async listConversationMessages(userId?: string, chatId?: string) {
    const { messages } = await this.client.send(new ListConversationMessagesCommand({ userId, conversationId: chatId }));
    return messages || [];
  }

  public async sendMessage(content: ContentItem[], conversationId: string, userId?: string, quotedMessage?: QuotedMessage, entityId?: string) {
    const response = await this.client.send(
      new SendConversationMessageCommand({
        userId,
        entityId,
        conversationId: conversationId === NEW_CHAT_PARAM_KEY ? undefined : conversationId,
        content,
        quotedMessage: quotedMessage ? { ...quotedMessage, content: this.convertImageContentItemsIntoURI(quotedMessage.content) } : undefined,
        model: 'CreativeAgent-1.0',
      }),
    );

    const newConversationId = response.conversationId;
    if (!newConversationId) {
      throw new Error('Invalid conversation id');
    }

    let newConversation = false;

    if (conversationId === NEW_CHAT_PARAM_KEY) {
      newConversation = true;
      const pathname = window.location.pathname;
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(CHAT_ID_PARAM_KEY, newConversationId);
      await router.navigate(`${pathname}?${searchParams.toString()}`);

      setQueryEnabled(newConversationId, false);
      moveNewChatQueryData(newConversationId);
    }

    let imagesCount = 0;
    let textMessagesCount = 0;

    for await (const event of response.events || []) {
      if (event.responseEvent) {
        addNewMessages(newConversationId, [event.responseEvent]);
        imagesCount += event.responseEvent.content?.filter((item) => item.image)?.length || 0;
        if (event.responseEvent.content?.filter((item) => item.text)?.length) {
          textMessagesCount++;
        }
      } else if (event.updateConversationMetadataEvent) {
        updateConversation(apiConversationToConversationModel(event.updateConversationMetadataEvent));
      } else if (event.finalResponseEvent) {
        addNewMessages(newConversationId, [event.finalResponseEvent]);
        updateLoadingMessageConfig(newConversationId, { active: false, loadingImages: false });
        imagesCount += event.finalResponseEvent.content?.filter((item) => item.image)?.length || 0;
        if (event.finalResponseEvent.content?.filter((item) => item.text)?.length) {
          textMessagesCount++;
        }
      } else if (event.progressEvent) {
        event.progressEvent.content?.forEach((content) => {
          if (content.metadata?.name === 'update_loading_message' && content.metadata?.content) {
            updateLoadingMessageConfig(newConversationId, this.getLoadingConfig(content.metadata?.content as string));
          }

          if (content.metadata?.name === 'update_persona' && content.metadata?.content) {
            updateLoadingMessageConfig(newConversationId, { persona: content.metadata?.content as Persona });
          }
        });
      }
    }

    return { imagesCount, textMessagesCount, newConversation };
  }

  public async startConversation(userId: string, topic: Topic) {
    return this.client.send(new StartConversationCommand({ userId, topic }));
  }

  private convertImageContentItemsIntoURI(content?: ContentItem[]): ContentItem[] | undefined {
    return content?.map((item) => {
      if (item.image) {
        return {
          ...item,
          image: {
            ...item.image,
            source: getAssetUri(item.image.source),
          },
        };
      }
      return item;
    });
  }

  private getLoadingConfig(content: string): Partial<LoadingMessageConfig> {
    if (content === 'product_images') {
      return { loadingImages: true, text: 'product image' };
    }
    if (content === 'extracting_asin') {
      return { text: 'Extracting asin data' };
    }
    if (content === 'analysing_asin') {
      return { text: 'Analysing asin data' };
    }
    if (content === 'concepts') {
      return { text: 'Generating concepts' };
    }
    if (content === 'headlines') {
      return { text: 'Generating headlines' };
    }
    if (content === 'lifestyle_images') {
      return { loadingImages: true, text: 'lifestyle image' };
    }
    return {};
  }
}

export const redCarpetClient = new RedCarpetClient();
