import { combineReducers, configureStore } from '@reduxjs/toolkit';
import feedContentReducer from './slices/feedContent/feedContentSlice';
import productReducer, { productListenerMiddleware } from './slices/product/productSlice';
import previewReducer from './slices/studio/previewSlice';
import { themeReducer } from './slices/theme/themeSlice';
import { userInputReducer } from './slices/userInput/userInputSlice';

const rootReducer = combineReducers({
  product: productReducer,
  feedContent: feedContentReducer,
  userInput: userInputReducer,
  preview: previewReducer,
  theme: themeReducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    preloadedState,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(productListenerMiddleware.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
