import Genaihubbackend, {
  AsinCategory,
  AsinCategoryEnum,
  RetrieveResultByWorkflowIdAndBatchIdOutput,
  SubmitWorkflowByIdInput,
  WorkflowId,
} from '@amzn/genaihub-typescript-client';
import { ASINItem } from 'src/components/pages/studio/StudioContext';
import { WorkflowSubmission } from 'src/components/utils/WorkflowSubmission';
import { sanitiseText } from 'src/helpers';
import { MetricsProvider } from 'src/metrics';

export interface WorkflowInvocationJob {
  input: SubmitWorkflowByIdInput;
  prompt?: string;
  output: RetrieveResultByWorkflowIdAndBatchIdOutput;
}

export interface TextToImageWorkflowProps {
  asinItem: ASINItem;
  asinCategory?: AsinCategory;
  client: Genaihubbackend;
  productImage: string;
  aspectRatio?: string;
  effects?: string;
  outputCount?: number;
  prompt?: string;
  theme?: string;
  adsEntityId?: string;
}

export interface LifestyleImageryWorkflowProps {
  client: Genaihubbackend;
  asinItem?: ASINItem;
  asinCategory?: AsinCategory;
  aspectRatio?: string;
  effects?: string;
  outputCount?: number;
  prompt?: string;
  theme?: string;
  rewriteUserCustomPrompt?: boolean;
  adsEntityId?: string;
  asinImageReferenceId?: string;
}

export interface TextGenerationWorkflowProps {
  client: Genaihubbackend;
  asinItem?: ASINItem;
  outputCount?: number;
  isProductLessLifestyle?: boolean;
  prompt?: string;
  theme?: string;
  asinImageReferenceId?: string;
}

export class WorkflowService {
  private readonly metrics?: MetricsProvider;
  private currentSubmissions: WorkflowSubmission[];

  constructor(metrics?: MetricsProvider) {
    this.currentSubmissions = [];
    this.metrics = metrics;
  }

  async invokeTextToImageWorkflow(props: TextToImageWorkflowProps): Promise<WorkflowInvocationJob> {
    const { asinItem, asinCategory, client, productImage, aspectRatio, effects, outputCount = 1, prompt, theme, adsEntityId } = props;
    const textToImageWorkflowId: WorkflowId = 'TEXT_TO_IMAGE';
    const apparelWorkflowId: WorkflowId = 'APPAREL';
    const workflowId = asinCategory === AsinCategoryEnum.APPAREL ? apparelWorkflowId : textToImageWorkflowId;

    let textPrompt = prompt;

    // If no text prompt then generate one
    if (!textPrompt) {
      const jobs = (
        await this.invokeTextGenerationWorkflow({
          asinItem,
          client,
          asinImageReferenceId: productImage,
        })
      ).output.body.jobs;
      if (jobs && jobs.length > 0 && jobs[0].urls && jobs[0].urls.length > 0) {
        textPrompt = jobs[0].urls[0];
      }
    }
    const sanitisedPrompt = sanitiseText(textPrompt || '');
    const constructedPrompt = this.constructInputPrompt(sanitisedPrompt, effects);
    const workflowOptions = {
      text_prompt: constructedPrompt,
      product_image: productImage,
      reference_image: '',
      themes: theme || 'no_theme',
      aspect_ratio: aspectRatio || '1:1',
      scaling: '',
      rotate: '',
      num_of_images: outputCount,
      asin: asinItem?.asin || '',
      asin_type: asinCategory || AsinCategoryEnum.NONE,
    };

    const input: SubmitWorkflowByIdInput = {
      workflowId,
      studioRequest: true,
      body: {
        workflowOptions,
      },
    };
    const currentSubmission = new WorkflowSubmission({
      workflowId,
      client,
      metrics: this.metrics,
      entityId: adsEntityId,
      studioRequest: true,
    }).verbose();
    this.currentSubmissions.push(currentSubmission);

    try {
      const summary = await currentSubmission.submitJob(workflowOptions);
      this.removeSubmission(currentSubmission);
      return {
        input,
        prompt: textPrompt,
        output: summary.output,
      };
    } catch (error) {
      this.removeSubmission(currentSubmission);
      throw error;
    }
  }

  async invokeLifestyleImageryWorkflow(props: LifestyleImageryWorkflowProps): Promise<WorkflowInvocationJob> {
    const {
      asinItem,
      asinCategory,
      client,
      aspectRatio,
      effects,
      outputCount = 1,
      prompt,
      theme,
      rewriteUserCustomPrompt = true,
      adsEntityId,
      asinImageReferenceId,
    } = props;
    const workflowId: WorkflowId = 'LIFESTYLE_IMAGERY';
    const sanitisedPrompt = prompt && sanitiseText(prompt);
    const workflowOptions = {
      asin: asinItem?.asin || ' ',
      asin_ref: asinItem?.asin || ' ',
      asin_type: asinCategory || AsinCategoryEnum.NONE,
      ...(asinImageReferenceId ? { product_image: asinImageReferenceId } : {}),
      feature_bullets: (asinItem?.metadata?.featureBullets || [' ']).map((item) => sanitiseText(item)),
      image_count: outputCount,
      prompt: this.constructInputPrompt(sanitisedPrompt, effects),
      rewriteUserCustomPrompt: rewriteUserCustomPrompt.toString(),
      theme: theme || 'no_theme',
      aspect_ratio: aspectRatio || '1:1',
      title: sanitiseText(asinItem?.metadata?.title || ' '),
    };
    const input: SubmitWorkflowByIdInput = {
      workflowId,
      studioRequest: true,
      body: {
        workflowOptions,
      },
    };
    const currentSubmission = new WorkflowSubmission({
      workflowId,
      client,
      metrics: this.metrics,
      entityId: adsEntityId,
      studioRequest: true,
    }).verbose();
    this.currentSubmissions.push(currentSubmission);

    try {
      const summary = await currentSubmission.submitJob(workflowOptions);
      this.removeSubmission(currentSubmission);
      return {
        input,
        prompt: sanitisedPrompt,
        output: summary.output,
      };
    } catch (error) {
      this.removeSubmission(currentSubmission);
      throw error;
    }
  }

  async invokeTextGenerationWorkflow(props: TextGenerationWorkflowProps): Promise<WorkflowInvocationJob> {
    const { asinItem, client, outputCount = 1, prompt = '', theme, isProductLessLifestyle = false, asinImageReferenceId } = props;
    const workflowId: WorkflowId = 'GUIDED_TEXT_GENERATION';
    const workflowOptions = {
      asin: asinItem?.asin,
      asin_ref: asinItem?.asin,
      feature_bullets: asinItem?.metadata?.featureBullets?.map((item) => sanitiseText(item)),
      prompt_count: outputCount,
      product_image: asinImageReferenceId,
      title: sanitiseText(asinItem?.metadata?.title || ''),
      ...(isProductLessLifestyle ? { prompt_type: { value: 'PRODUCT_LESS_LIFESTYLE' } } : {}),
      prompt: prompt,
      theme: theme || 'no_theme',
    };
    const input: SubmitWorkflowByIdInput = {
      workflowId: workflowId,
      studioRequest: true,
      body: {
        workflowOptions,
      },
    };
    const currentSubmission = new WorkflowSubmission({ workflowId, client, metrics: this.metrics, studioRequest: true }).verbose();
    this.currentSubmissions.push(currentSubmission);

    try {
      const summary = await currentSubmission.submitJob(workflowOptions);
      this.removeSubmission(currentSubmission);
      return {
        input,
        output: summary.output,
      };
    } catch (error) {
      this.removeSubmission(currentSubmission);
      throw error;
    }
  }

  private constructInputPrompt(textPrompt?: string, effects?: string): string {
    if (textPrompt && effects) {
      return [effects, textPrompt].join(', ');
    } else if (textPrompt) {
      return textPrompt;
    } else if (effects) {
      return effects;
    } else return '';
  }

  private removeSubmission(submission: WorkflowSubmission) {
    const index = this.currentSubmissions.indexOf(submission);
    if (index > -1) {
      this.currentSubmissions.splice(index, 1);
    }
  }

  abandon() {
    if (this.currentSubmissions.length > 0) {
      this.currentSubmissions.forEach((submission) => submission.abandon());
      this.currentSubmissions = [];
      return true;
    } else {
      return false;
    }
  }
}
