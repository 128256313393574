import { Icon, Dropdown, DropdownItem } from '@amzn/storm-ui';
import { IconDefinition } from '@amzn/storm-ui-icons';
import { faBoxOpen, faXmark } from '@fortawesome/free-solid-svg-icons';
import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import { ChangeEvent, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ProductThumbnail } from 'src/components/common/storm/ProductThumbnail';
import { AssetType } from 'src/components/imageModal/components/utils';
import { NoEffect } from 'src/components/pages/studio/effectSettings/effectSettings';
import ProductSelector from 'src/components/pages/studio/ProductSelector';
import { ContentItem, JobStatusEnum, StudioContext, StudioInputSettings, getProductImageDetails } from 'src/components/pages/studio/StudioContext';
import { WorkflowInvocationJob, WorkflowService } from 'src/components/pages/studio/WorkflowService';
import * as notifications from 'src/components/snackbar/notifications/ImageGenerationNotifications';
import { urlToFile } from 'src/components/utils/base64Encode';
import { getContentTypeByWorkflowId } from 'src/components/utils/getWorkflowNameById';
import { uploadImage } from 'src/components/utils/uploadImage';
import { generateLifestyleImageryWorkflowProps, generateTextToImageWorkflowProps } from 'src/components/utils/workflowHelpers';
import { Metrics, restrictedJobStatusMessage } from 'src/constants';
import { waitUntilImagesLoaded } from 'src/helpers';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { isProd } from 'src/util/util';
import { useNotificationActions } from 'src/v2/contexts/snackbar/actions/useNotificationActions';
import { SnackbarNotification } from 'src/v2/contexts/snackbar/types';
import style from './UserInputContainer.module.scss';
import { AppContext } from '../../../../AppContext';
import landScapeIcon from '../../../icons/aspectRatioFrames/landscape.png';
import portraitIcon from '../../../icons/aspectRatioFrames/portrait.png';
import rectangleIcon from '../../../icons/aspectRatioFrames/rectangle.png';
import { AnimatedButton } from '../../editor/UiContols/uiGeneratorControls/storm/UI/AnimatedButton';

// Arrays for placeholder generation
const PRODUCT_IMAGE_CONTENT_TYPE = 'product image';
const LIFESTYLE_IMAGE_CONTENT_TYPE = 'lifestyle image';
const ASIN_CONTENT_TYPE = 'ASIN';
const SIX_MIXED_CONTENT_TYPES = [...Array(3)].fill([PRODUCT_IMAGE_CONTENT_TYPE, LIFESTYLE_IMAGE_CONTENT_TYPE]).flat();
const SIX_ALL_LIFESTYLE_CONTENT_TYPES = [...Array(6)].fill(LIFESTYLE_IMAGE_CONTENT_TYPE);
const SIX_ALL_PRODUCT_CONTENT_TYPES = [...Array(6)].fill(PRODUCT_IMAGE_CONTENT_TYPE);
const SIX_ASIN_CONTENT_TYPE = [...Array(6)].fill(ASIN_CONTENT_TYPE);

export const PRODUCT_SELECTOR_TOGGLE_TEST_ID = 'studio-product-selector-toggle';
export const STUDIO_TEXT_PROMPT_INPUT_TEST_ID = 'studio-text-prompt-input';
export const STUDIO_EFFECT_APPLIED_TEST_ID = 'studio-effect-applied';
export const STUDIO_THEME_APPLIED_TEST_ID = 'studio-theme-applied';
export const STUDIO_ASPECT_RATIO_SELECTOR_TOGGLE_TEST_ID = 'studio-aspect-ratio-selector-toggle';
export const STUDIO_ASPECT_RATIO_SELECTOR_OPTION_TEST_ID = 'studio-aspect-ratio-selector-option';
export const STUDIO_GENERATE_BUTTON = 'studio-generate-button';

interface FormatSettingProps {
  id: string;
  icon: string;
  title: string;
  subText: string;
  isLastItem: boolean;
  selected?: boolean;
  clickCallback?: (id: string) => void;
}
export enum GenerateOptionType {
  GENERATE_PRODUCT_IMAGES = 'Generate product images',
  GENERATE_LIFESTYLE_IMAGES = 'Generate lifestyle images',
}

export type GenerateOptions = {
  [key in GenerateOptionType]?: boolean;
};

export let formatSettingItems = [
  {
    id: '1.91:1',
    title: 'Horizontal 1.91:1',
    subText: 'Sponsored Brands',
    icon: landScapeIcon,
  },
  {
    id: '3:1',
    title: 'Horizontal 3:1',
    subText: 'Stores',
    icon: landScapeIcon,
  },
  {
    id: '1:1',
    title: 'Square 1:1',
    subText: 'Posts, Stores',
    icon: rectangleIcon,
  },
  {
    id: '4:5',
    title: 'Vertical 4:5',
    subText: 'Posts',
    icon: portraitIcon,
  },
];

if (!isProd()) {
  formatSettingItems = [
    ...formatSettingItems,
    {
      id: '9:16',
      title: 'Vertical 9:16',
      subText: 'Posts, Sponsored Display',
      icon: portraitIcon,
    },
  ];
}

export const UserSelectableAspectRatios = formatSettingItems.map((item) => item.id);

export const DEFAULT_FORMAT = formatSettingItems.find((item) => item.id === '1.91:1') ?? formatSettingItems[0];

const FormatSettingItem = ({ icon, title, subText, isLastItem, selected }: FormatSettingProps) => {
  return (
    <div className={`${style.dropdownList} ${selected ? style.selected : ''} ${isLastItem ? style.lastItem : ''}`}>
      <div className={style.icon} style={{ backgroundImage: `url(${icon})` }}></div>
      <div className={style.description}>
        <div className={style.title}>{title}</div>
        <div className={style.subText}>{subText}</div>
      </div>
    </div>
  );
};

export interface UserInputContainerRef {
  setPrompt: (prompt: string | undefined) => void;
}

const UserInputContainer = forwardRef<UserInputContainerRef>((_, ref) => {
  const [showAsinPicker, setShowAsinPicker] = useState<boolean>();
  const asinPickerRef = useRef<HTMLDivElement>(null);
  const textInputRef = useRef<string>('');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const currentWorkflowService = useRef<WorkflowService | null>(null);
  const processingNotificationTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const studioContext = useContext(StudioContext);
  const appContext = useContext(AppContext);
  const backendClient = useAIBackendHubClient();
  const { addFailureNotification } = useNotificationActions();

  const selectedAsin = studioContext.asin;
  const productImageDetails = selectedAsin ? getProductImageDetails(selectedAsin) : undefined;
  const [generateOptions, setGenerateOptions] = useState<GenerateOptions>({
    [GenerateOptionType.GENERATE_PRODUCT_IMAGES]: false,
    [GenerateOptionType.GENERATE_LIFESTYLE_IMAGES]: false,
  });
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [selectedGenerateOption, setSelectedGenerateOption] = useState<GenerateOptionType | undefined>();

  const addAsinVisibilityHandler = (event: Event) => {
    if (asinPickerRef.current && !asinPickerRef.current.contains(event.target as Node)) {
      setShowAsinPicker(false);
    }
  };

  const setGenerateOptionsHandler = (generateOption: GenerateOptionType, value: boolean) => {
    setGenerateOptions((options: GenerateOptions) => ({
      ...options,
      [generateOption]: value,
    }));
  };

  useImperativeHandle(ref, () => ({
    setPrompt: (prompt: string | undefined) => {
      if (textAreaRef.current) {
        textAreaRef.current.value = prompt || '';
      }
    },
  }));

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (currentWorkflowService.current) {
        currentWorkflowService.current.abandon();
      }
      if (processingNotificationTimeoutRef.current) {
        clearTimeout(processingNotificationTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    studioContext.setFormat(DEFAULT_FORMAT.id);
    if (studioContext.autoGenerate) {
      if (studioContext.textPrompt && studioContext.textPrompt?.length > 0) {
        if (textAreaRef.current) {
          textAreaRef.current.value = studioContext.textPrompt;
        }
        studioContext.setAutoGenerate(false);
        submitGenerationRequest();
      } else {
        studioContext.setGenerationPlaceholders(
          SIX_ASIN_CONTENT_TYPE.map((contentType) => ({ contentType, loading: true, aspectRatio: studioContext.format })),
        );
        studioContext.setJobStatus({ status: JobStatusEnum.LOADING_ASIN });
      }
    } else {
      if (studioContext.jobStatus?.status === JobStatusEnum.LOADING_ASIN) {
        const asin = studioContext.asin;
        const hasValidMetadata = typeof asin?.metadata !== 'undefined';
        const hasAsinValue = Boolean(asin?.asin);

        if (studioContext.jobStatus.message === restrictedJobStatusMessage) {
          setFailedJobStatus(notifications.SubmitRestrictedAsinNotification, notifications.FAILURE_MESSAGE.RESTRICTED_ASIN);
          return;
        }

        if (hasAsinValue && hasValidMetadata) {
          submitGenerationRequest();
          return;
        }

        setFailedJobStatus(notifications.SubmitAsinFailureNotification, notifications.FAILURE_MESSAGE.NO_ASIN);
      }
    }

    document.addEventListener('mousedown', addAsinVisibilityHandler);
    return () => {
      document.removeEventListener('mousedown', addAsinVisibilityHandler);
    };
  }, [studioContext.autoGenerate]);

  useEffect(() => {
    if (!selectedAsin) {
      studioContext.setStyle(undefined);
    }
  }, [selectedAsin]);

  useEffect(() => {
    const isTextPromptEmpty = !studioContext.textPrompt || studioContext.textPrompt.length === 0;
    if (selectedAsin && studioContext.style) {
      setGenerateOptionsHandler(GenerateOptionType.GENERATE_LIFESTYLE_IMAGES, false);
      setGenerateOptionsHandler(GenerateOptionType.GENERATE_PRODUCT_IMAGES, true);
    } else if ((selectedAsin && selectedAsin.asin) || (selectedAsin?.customImage && !isTextPromptEmpty)) {
      setGenerateOptionsHandler(GenerateOptionType.GENERATE_LIFESTYLE_IMAGES, true);
      setGenerateOptionsHandler(GenerateOptionType.GENERATE_PRODUCT_IMAGES, true);
    } else if (!isTextPromptEmpty) {
      setGenerateOptionsHandler(GenerateOptionType.GENERATE_LIFESTYLE_IMAGES, true);
      setGenerateOptionsHandler(GenerateOptionType.GENERATE_PRODUCT_IMAGES, false);
    } else {
      setGenerateOptionsHandler(GenerateOptionType.GENERATE_LIFESTYLE_IMAGES, false);
      setGenerateOptionsHandler(GenerateOptionType.GENERATE_PRODUCT_IMAGES, false);
    }
  }, [selectedAsin, studioContext.textPrompt, studioContext.style]);

  useEffect(() => {
    if (shouldSubmit) {
      submitGenerationRequest(selectedGenerateOption);
      setShouldSubmit(false);
      setSelectedGenerateOption(undefined);
    }
  }, [shouldSubmit, studioContext.textPrompt, selectedGenerateOption]);

  const handleEffectRemoveClick = (effectId: string) => {
    const newEffects = new Map(studioContext.effects);
    newEffects.delete(effectId);
    studioContext.setEffects(newEffects);
  };

  const submitGenerationRequest = async (generateOptionOverride?: GenerateOptionType) => {
    if (!isGeneratedEnabled()) {
      return;
    }

    studioContext.setJobStatus({ status: JobStatusEnum.RUNNING });

    const baseStudioInputSettings: StudioInputSettings = {
      asin: _cloneDeep(studioContext.asin),
      effects: _cloneDeep(studioContext.effects),
      enablePromptRewriting: studioContext.enablePromptRewriting,
      format: studioContext.format,
      prompt: '',
      style: _cloneDeep(studioContext.style),
      // Only capture advanced mode state if it's enabled
      advanced: studioContext.enableAdvancedMode
        ? {
            negativePrompt: studioContext.negativePrompt,
            seed: studioContext.seed,
            temperature: studioContext.temperature,
          }
        : undefined,
    };

    const workflowService = (currentWorkflowService.current = new WorkflowService(appContext.metrics));
    const results: WorkflowInvocationJob[] = [];
    let asinImageReferenceId = '';
    let isJobRunning = true;

    // timeout increased for the case where we run both text generation and image generation workflows in sequence.
    const delayForTimeout = selectedAsin && !studioContext.textPrompt ? 150000 : 90000;
    const processingNotificationTimeout = (processingNotificationTimeoutRef.current = setTimeout(() => {
      if (isJobRunning) {
        isJobRunning = false;
        setFailedJobStatus(notifications.SubmitTimeoutNotification, notifications.FAILURE_MESSAGE.TIME_OUT);
      }
    }, delayForTimeout)); // Clear this when leaving page

    try {
      if (productImageDetails) {
        studioContext.setGenerationPlaceholders(
          SIX_ASIN_CONTENT_TYPE.map((contentType) => ({ contentType, loading: true, aspectRatio: studioContext.format })),
        );
        const asinImageFile = await urlToFile(productImageDetails.url);
        asinImageReferenceId = await uploadImage({
          file: asinImageFile,
          backendClient,
          contentCategory: 'PRODUCT_IMAGE',
        });
      }
      const effects = Array.from(studioContext.effects?.values() || [])
        .map((opt) => opt.value)
        .join(', ');
      const entityId = appContext.selectedAdvertisingAccount?.alternateIds?.[0];
      const textGenerationInputs = {
        asinItem: selectedAsin,
        asinImageReferenceId: asinImageReferenceId,
        client: backendClient,
        theme: studioContext.style?.prompt,
        outputCount: 6,
      };

      // Overrides for if generate options are used
      if (generateOptionOverride) {
        const placeholderType =
          generateOptionOverride === GenerateOptionType.GENERATE_LIFESTYLE_IMAGES ? SIX_ALL_LIFESTYLE_CONTENT_TYPES : SIX_ALL_PRODUCT_CONTENT_TYPES;

        studioContext.setGenerationPlaceholders(
          placeholderType.map((contentType) => ({
            contentType,
            loading: true,
            aspectRatio: baseStudioInputSettings.format,
          })),
        );

        let prompts = [];
        if (studioContext.textPrompt) {
          prompts = [studioContext.textPrompt];
        } else {
          const textGenerationResponses = await workflowService.invokeTextGenerationWorkflow({
            ...textGenerationInputs,
            isProductLessLifestyle: generateOptionOverride === GenerateOptionType.GENERATE_LIFESTYLE_IMAGES,
          });
          prompts = textGenerationResponses.output.body.jobs?.[0].urls || [];
        }

        let generationPromises = [];
        const outputCount = prompts.length === 1 ? 6 : 1;

        if (generateOptionOverride === GenerateOptionType.GENERATE_PRODUCT_IMAGES) {
          generationPromises = prompts.map((prompt) => {
            return workflowService.invokeTextToImageWorkflow(
              generateTextToImageWorkflowProps({
                backendClient,
                studioContext,
                effects,
                asinImageReferenceId,
                entityId,
                prompt,
                outputCount,
              }),
            );
          });
        } else {
          generationPromises = prompts.map((prompt) => {
            return workflowService.invokeLifestyleImageryWorkflow(
              generateLifestyleImageryWorkflowProps({
                backendClient,
                studioContext,
                effects,
                entityId,
                outputCount,
                asinImageReferenceId,
                prompt,
              }),
            );
          });
        }

        const imageGenerationResponses = await Promise.allSettled(generationPromises);
        results.push(
          ...(imageGenerationResponses
            .map((res) => (res.status === 'rejected' ? undefined : res.value))
            .filter((e) => e !== undefined) as WorkflowInvocationJob[]),
        );
      } else if (selectedAsin && studioContext.style) {
        // Generate 6 PlaceHolders for Product images
        studioContext.setGenerationPlaceholders(
          SIX_ALL_PRODUCT_CONTENT_TYPES.map((contentType) => ({ contentType, loading: true, aspectRatio: baseStudioInputSettings.format })),
        );
        results.push(
          await workflowService.invokeTextToImageWorkflow(
            generateTextToImageWorkflowProps({ backendClient, studioContext, effects, asinImageReferenceId, entityId }),
          ),
        );
      } else if (selectedAsin && studioContext.textPrompt) {
        // Generate 3 PlaceHolders for Product images and another 3 for lifestyle images
        studioContext.setGenerationPlaceholders(
          SIX_MIXED_CONTENT_TYPES.map((contentType) => ({ contentType, loading: true, aspectRatio: baseStudioInputSettings.format })),
        );
        const productBasedPromptToImageResponses = await Promise.allSettled([
          workflowService.invokeTextToImageWorkflow(
            generateTextToImageWorkflowProps({ backendClient, studioContext, effects, asinImageReferenceId, entityId, outputCount: 3 }),
          ),
          workflowService.invokeLifestyleImageryWorkflow(
            generateLifestyleImageryWorkflowProps({ backendClient, studioContext, effects, asinImageReferenceId, entityId, outputCount: 3 }),
          ),
        ]);
        results.push(
          ...(productBasedPromptToImageResponses
            .map((res) => (res.status === 'rejected' ? undefined : res.value))
            .filter((e) => e !== undefined) as WorkflowInvocationJob[]),
        );
      } else if (selectedAsin) {
        const outputCounts: number[] = [2, 3, 1];
        const textGenerationInputs = {
          asinItem: selectedAsin,
          asinImageReferenceId: asinImageReferenceId,
          client: backendClient,
        };
        // Generate 3 Placeholders for Product images and another 3 for lifestyle images
        studioContext.setGenerationPlaceholders(
          SIX_MIXED_CONTENT_TYPES.map((contentType) => ({ contentType, loading: true, aspectRatio: baseStudioInputSettings.format })),
        );
        // Generate 6 prompts for ASIN
        const textGenerationResponses = await Promise.allSettled([
          // 2 with product no theme
          workflowService.invokeTextGenerationWorkflow({
            ...textGenerationInputs,
            theme: studioContext.style?.prompt,
            outputCount: outputCounts[0],
          }),
          // 3 product-less
          workflowService.invokeTextGenerationWorkflow({
            ...textGenerationInputs,
            theme: studioContext.style?.value,
            outputCount: outputCounts[1],
            isProductLessLifestyle: true,
          }),
          // 1 with product abstract theme
          workflowService.invokeTextGenerationWorkflow({
            ...textGenerationInputs,
            theme: 'abstract',
            outputCount: outputCounts[3],
          }),
        ]);

        const guidedTextPrompts = textGenerationResponses.map((res, inx) => {
          if (res.status === 'rejected') return [];
          else return res.value?.output?.body?.jobs?.[0].urls?.slice(0, outputCounts[inx]) || [];
        });

        const imageGenerationResponses = await Promise.allSettled([
          // 2 Product Images
          ...guidedTextPrompts[0].map((prompt: string) =>
            workflowService.invokeTextToImageWorkflow({
              asinItem: selectedAsin,
              aspectRatio: studioContext.format,
              asinCategory: selectedAsin.metadata.asinCategory,
              client: backendClient,
              effects,
              outputCount: guidedTextPrompts[0].length === 1 ? 2 : 1,
              productImage: asinImageReferenceId,
              prompt,
              theme: studioContext.style?.prompt,
              adsEntityId: entityId,
            }),
          ),
          // 3 Lifestyle Images
          ...guidedTextPrompts[1].map((prompt: string, index: number) =>
            workflowService.invokeLifestyleImageryWorkflow({
              asinItem: selectedAsin,
              asinCategory: selectedAsin.metadata.asinCategory,
              client: backendClient,
              effects,
              aspectRatio: studioContext.format,
              outputCount: guidedTextPrompts[1].length < outputCounts[1] && index === 0 ? outputCounts[1] - guidedTextPrompts[1].length : 1,
              prompt,
              theme: studioContext.style?.value,
              rewriteUserCustomPrompt: studioContext.enablePromptRewriting,
              adsEntityId: entityId,
              asinImageReferenceId,
            }),
          ),
          // 1 product with theme
          ...guidedTextPrompts[2].map((prompt: string) =>
            workflowService.invokeTextToImageWorkflow({
              asinItem: selectedAsin,
              aspectRatio: studioContext.format,
              asinCategory: selectedAsin.metadata.asinCategory,
              client: backendClient,
              effects,
              outputCount: 1,
              productImage: asinImageReferenceId,
              prompt,
              theme: 'abstract',
              adsEntityId: entityId,
            }),
          ),
        ]);
        results.push(
          ...(imageGenerationResponses
            .map((res) => (res.status === 'rejected' ? undefined : res.value))
            .filter((e) => e !== undefined) as WorkflowInvocationJob[]),
        );
      } else {
        // Generate 6 Placeholders for lifestyle images
        studioContext.setGenerationPlaceholders(
          SIX_ALL_LIFESTYLE_CONTENT_TYPES.map((contentType) => ({ contentType, loading: true, aspectRatio: baseStudioInputSettings.format })),
        );
        results.push(
          await workflowService.invokeLifestyleImageryWorkflow(
            generateLifestyleImageryWorkflowProps({ backendClient, studioContext, effects, entityId, outputCount: 6, asinImageReferenceId }),
          ),
        );
      }

      clearTimeout(processingNotificationTimeout);

      if (!isJobRunning) return;

      const contentItems = await prepareContentItemsForRender(baseStudioInputSettings, results);

      appContext.metrics.trackMetrics(Metrics.Methods.StudioWorkflow, {}, { [Metrics.Names.Effects]: effects !== '' ? 1 : 0 });

      await waitUntilImagesLoaded(contentItems.map((item) => item.content));
      studioContext.clearGenerationPlaceholders();

      if (contentItems.length === 0) return setFailedJobStatus(notifications.SubmitNoImageNotification, notifications.FAILURE_MESSAGE.NO_IMAGE);

      studioContext.prependResults(contentItems);
      studioContext.setJobStatus({ status: JobStatusEnum.COMPLETED, results: contentItems });
      if (contentItems.length < 6) {
        addFailureNotification({ SnackbarContent: notifications.SubmitPartialFailureNotification });
      }
    } catch (error) {
      console.error(error);
      clearTimeout(processingNotificationTimeout);
      if (isJobRunning) {
        isJobRunning = false;
        setFailedJobStatus(notifications.SubmitSystemFailureNotification, error as string);
      }
    }
  };

  const prepareContentItemsForRender = async (
    baseStudioInputSettings: StudioInputSettings,
    completedJobs: WorkflowInvocationJob[],
  ): Promise<ContentItem[]> => {
    const results: ContentItem[] = [];
    for (const completedJob of completedJobs) {
      const contentType = getContentTypeByWorkflowId(completedJob.input.workflowId);
      const prompt = completedJob.prompt;
      const studioInputSettings = _merge(_cloneDeep(baseStudioInputSettings), {
        prompt,
      }) as StudioInputSettings;

      if (completedJob.output.body.jobs && completedJob.output.body.jobs.length > 0) {
        for (const job of completedJob.output.body.jobs) {
          if (job.urls) {
            for (let i = 0; i < job.urls.length; i++) {
              const contentFile = await urlToFile(job.urls[i], 'image/png');
              results.push({
                originalUri: job.urls[i],
                content: URL.createObjectURL(contentFile),
                referenceId: job.ids?.[i],
                aspectRatio: studioInputSettings.format,
                loading: false,
                contentHeadline: completedJob.prompt,
                contentType,
                studioInputSettings,
                workflowId: completedJob.input.workflowId,
                imageId: job.assets?.[i].imageId,
                batchId: completedJob.output.body.batchId,
                assetType: completedJob.output.body.type as AssetType,
              });
            }
          }
        }
      }
    }

    return results;
  };

  const setFailedJobStatus = (snackbarMessage: SnackbarNotification['SnackbarContent'], tooltipMessage: string = '') => {
    const dummyPlaceholders = studioContext.asin
      ? SIX_MIXED_CONTENT_TYPES
      : studioContext.textPrompt
        ? SIX_ALL_LIFESTYLE_CONTENT_TYPES
        : SIX_ASIN_CONTENT_TYPE;
    studioContext.setGenerationPlaceholders(
      dummyPlaceholders.map((contentType) => ({ contentType, loading: false, message: '' + tooltipMessage, aspectRatio: studioContext.format })),
    );
    studioContext.setJobStatus({ status: JobStatusEnum.FAILED, message: '' + tooltipMessage });
    addFailureNotification({ SnackbarContent: snackbarMessage });
    setTimeout(() => studioContext.clearGenerationPlaceholders(), 4500);
  };

  const isGeneratedEnabled = () => {
    if (studioContext.jobStatus?.status === JobStatusEnum.RUNNING) return false;
    if (studioContext.asin?.customImage) {
      return studioContext.textPrompt;
    } else return studioContext.asin || studioContext.textPrompt;
  };

  const textPromptChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    textInputRef.current = e.target.value;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout to update the debounced value after 500ms
    timeoutRef.current = setTimeout(() => {
      studioContext.setTextPrompt(textInputRef.current);
    }, 500);

    // temporarily down.
    // studioContext.setEnablePromptRewriting(e.target.value.length > 0);
  };

  const handleGenerateAction = (option?: GenerateOptionType) => {
    studioContext.setTextPrompt(textAreaRef.current?.value);
    setShouldSubmit(true);
    if (option) {
      setSelectedGenerateOption(option);
    }
  };

  const handleDropdownChange = (value: string) => {
    studioContext.setFormat(value);
  };

  const OverrideLabel = (dropdownItem: string, value: string) => {
    if (value) {
      return dropdownItem;
    }
    return studioContext.format;
  };

  // const magicPromptClickHandler = () => {
  //   studioContext.setEnablePromptRewriting((curr) => !curr);
  // };

  const getInputPlaceholder = () => {
    let inputPlaceholder = 'Add a product or describe the image you want.';

    if (studioContext.asin?.customImage) {
      inputPlaceholder = 'Describe the image you want to see.';
    } else if (studioContext.asin?.asin) {
      inputPlaceholder = 'Generate an image now or add a descriptive prompt.';
    }
    return inputPlaceholder;
  };

  return (
    <div className={style.container}>
      <div className={style.promptArea}>
        <div
          onClick={() => setShowAsinPicker(true)}
          className={!productImageDetails?.url && style.inputArea}
          data-testid={PRODUCT_SELECTOR_TOGGLE_TEST_ID}
        >
          {productImageDetails?.url ? (
            <ProductThumbnail imageUrl={productImageDetails?.url} size={75} bgColor={'--secondary-button-color-variant-2'}></ProductThumbnail>
          ) : (
            <>
              <div className={style.inputAreaText}>Add Product</div>
              <Icon type={faBoxOpen as IconDefinition} />
            </>
          )}
        </div>
        <div className={style.textArea}>
          <textarea
            ref={textAreaRef}
            //value={studioContext.textPrompt}
            onChange={textPromptChangeHandler}
            rows={3}
            placeholder={getInputPlaceholder()}
            onKeyDown={(event) => event.key === 'Enter' && studioContext.textPrompt && studioContext.textPrompt?.length > 0 && handleGenerateAction()}
            data-testid={STUDIO_TEXT_PROMPT_INPUT_TEST_ID}
          />
          {(studioContext.style || (typeof studioContext.effects !== 'undefined' && studioContext.effects?.size > 0)) && (
            <div className={style.effectContainer}>
              {studioContext.style && (
                <div className={style.leftButton} data-testid={STUDIO_THEME_APPLIED_TEST_ID}>
                  <div className={style.leftButtonImage} style={{ backgroundImage: `url(${studioContext.style.icon})` }}></div>
                  <div className={style.leftButtonText}>
                    <span>THEME:</span> {studioContext.style.label}
                  </div>
                  <Icon
                    onClick={() => studioContext.setStyle(undefined)}
                    style={{ cursor: 'pointer', color: '#FFF' }}
                    type={faXmark as IconDefinition}
                  />
                </div>
              )}
              {Array.from(studioContext.effects?.entries() || []).map(
                (item) =>
                  item[1].label != NoEffect && (
                    <div className={style.leftButton} key={item[1].label} data-testid={STUDIO_EFFECT_APPLIED_TEST_ID}>
                      <div className={style.leftButtonText}>
                        <span>{item[0].toUpperCase()}:</span>
                      </div>
                      <div className={style.leftButtonText}>{item[1].label}</div>
                      <Icon
                        onClick={() => handleEffectRemoveClick(item[0])}
                        style={{ cursor: 'pointer', color: '#FFF' }}
                        type={faXmark as IconDefinition}
                      />
                    </div>
                  ),
              )}
            </div>
          )}
        </div>
        {/*<Tooltip trigger={<div onClick={magicPromptClickHandler}
                               className={`${style.sparkleIcon} ${studioContext.enablePromptRewriting ? style.sparkleIconEnabled : ''}`}>
          <SparkleIcon />
        </div>}>
          <div className={style.sparkleIconTooltip}>
            <div className={style.sparkleIconTooltipTitle}>Prompt rewriting</div>
            <span className={style.sparkleIconTooltipDescription}>When selected, we will automatically rewrite your prompt for better results. If you want more control, unselect this option and we'll leave your prompt as is.</span>
          </div>
        </Tooltip>*/}
      </div>
      <div className={style.negativePrompt}>
        {studioContext.enableAdvancedMode && (
          <>
            <p>Negative prompt</p>
            <textarea
              value={studioContext.negativePrompt}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => studioContext.setNegativePrompt(e.target.value)}
              rows={2}
              placeholder="Example: Bad quality, poor quality"
            />
          </>
        )}
      </div>
      <div className={style.buttons}>
        <div className={style.leftButtons}>
          <Dropdown
            data-testid={STUDIO_ASPECT_RATIO_SELECTOR_TOGGLE_TEST_ID}
            style={{
              backgroundColor: 'var(--secondary-button-color-variant-2)',
              boxShadow: 'none',
              borderRadius: '12px',
              minWidth: '220px',
              width: '100%',
              border: 'none',
            }}
            onChange={(value) => handleDropdownChange(value)}
            fullWidth={false}
            selectedValue={studioContext.format}
            onOverrideLabel={(dropdownItem, value) => OverrideLabel(dropdownItem, value)}
            mobileFullScreen={false}
            secondaryViewCloseButtonLabel="Done"
          >
            {formatSettingItems.map((item, index) => (
              <DropdownItem
                key={item.id}
                style={{
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                }}
                value={item.id}
                data-testid={`${STUDIO_ASPECT_RATIO_SELECTOR_OPTION_TEST_ID}-${item.id}`}
              >
                <FormatSettingItem
                  id={item.id}
                  selected={item.id == studioContext.format}
                  icon={item.icon}
                  title={item.title}
                  subText={item.subText}
                  isLastItem={index === formatSettingItems.length - 1}
                />
              </DropdownItem>
            ))}
          </Dropdown>
          {studioContext.enableAdvancedMode && window.innerWidth > 730 && (
            <div className={style.seedInput}>
              <input
                placeholder="00000"
                maxLength={5}
                value={studioContext.seed}
                onChange={(event) => studioContext.setSeed(event.target.value.replace(/\D/g, ''))}
              />
            </div>
          )}
        </div>
        <div className={style.rightButtons}>
          {/*<Switch*/}
          {/*  id="advancedMode"*/}
          {/*  className={style.customSwitch}*/}
          {/*  toggled={studioContext.enableAdvancedMode}*/}
          {/*  onChange={() => studioContext.setEnableAdvancedMode((curr) => !curr)}*/}
          {/*  label="Advanced mode"*/}
          {/*  small={true}*/}
          {/*/>*/}
          <div className={`${style.generateButtonContainer} ${isGeneratedEnabled() ? style.enabled : ''}`}>
            <AnimatedButton
              animate={studioContext.jobStatus?.status === JobStatusEnum.RUNNING || studioContext.jobStatus?.status === JobStatusEnum.LOADING_ASIN}
              disabled={!isGeneratedEnabled()}
              clickHandler={handleGenerateAction}
              text={
                studioContext.jobStatus?.status === JobStatusEnum.RUNNING || studioContext.jobStatus?.status === JobStatusEnum.LOADING_ASIN
                  ? 'Generating'
                  : 'Generate'
              }
              generateOptions={generateOptions}
              dataTestId={STUDIO_GENERATE_BUTTON}
            />
          </div>
        </div>
      </div>
      <div style={{ visibility: showAsinPicker ? 'visible' : 'hidden' }} ref={asinPickerRef} tabIndex={1} className={style.asinSelector}>
        <ProductSelector closeProductSelector={() => setShowAsinPicker(false)} isVisible={showAsinPicker} />
      </div>
    </div>
  );
});
UserInputContainer.displayName = 'UserInputContainer';

export default UserInputContainer;
