import { StringMetrics } from 'src/metrics';

export const getWorkflowOptionsMetrics = (options?: object | null): StringMetrics => {
  const output: StringMetrics = {};
  if (options) {
    Object.entries(options).forEach(([key, value]) => {
      output[`WorkflowOptions.${key}`] = String(value);
    });
  }

  return output;
};
