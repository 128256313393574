import { ASINMetadata } from '@amzn/genaihub-typescript-client';

export enum ProductType {
  ASIN = 'asin',
  CUSTOM_IMAGE = 'customImage',
}

interface ProductBase<TProductType extends ProductType> {
  id: string;
  type: TProductType;
}

export interface CustomImageDetails {
  url: string;
  name: string;
  size: number;
}

export interface AsinProduct extends ProductBase<ProductType.ASIN> {
  asin: string;
  metadata: ASINMetadata;
  selectedImageIndex?: number;
  customProductImageUrl?: string;
}

export interface CustomImageProduct extends ProductBase<ProductType.CUSTOM_IMAGE> {
  customImage: CustomImageDetails;
}

export type Product = AsinProduct | CustomImageProduct;
