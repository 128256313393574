import { TextAreaFormGroup } from '@amzn/storm-ui';
import { ChangeEvent, MouseEventHandler, useContext, useEffect, useState } from 'react';
import { AnimatedButton } from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/AnimatedButton';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import ImageEditingCanvas from 'src/components/imageModal/components/inputs/ImageEditingCanvas';
import { ImageModalContext } from 'src/components/imageModal/ImageModalContext';
import { WORKFLOW_TYPES } from 'src/components/imageModal/types';
import { HEADING_TEXT_SIZE } from 'src/constants';
import { sanitiseText } from 'src/helpers';
import { WorkflowSubmission } from 'src/components/utils/WorkflowSubmission';
import { ImageCategory, InsertAction, RemoveAction, useWorkflow, WorkflowId } from '../utils';
import styles from './AddRemoveControls.module.scss';
import { AppContext } from 'src/../AppContext';

/**
 * @description This component is responsible for rendering the add or remove controls
 * @returns {JSX.Element}
 */

interface AddRemoveControlsProps {
  taskType: WORKFLOW_TYPES;
}

export const AddRemoveControls = ({ taskType }: AddRemoveControlsProps) => {
  const [imageMask, setImageMask] = useState<File>();
  const [baseImage, setBaseImage] = useState<string>();
  const [workflowState, setWorkflowState] = useState<string>(WorkflowSubmission.IDLE);
  const [prompt, setPrompt] = useState<string>('');

  const { activePage, userDetails } = useContext(AppContext);
  const { clearActiveEdit, setActiveEditResults, setActiveEditErrorMessage, setPendingGeneration } = useContext(ImageModalContext);

  const addTask = taskType === WORKFLOW_TYPES.INPAINTING;
  const removeTask = taskType === WORKFLOW_TYPES.REMOVAL;

  const addTypeControlLabel = {
    controlLabel: 'Add object',
    description: 'To add an object, describe what you want to add, and use the drawing tools to show where you want to add it.',
    controlName: 'Image mask',
  };

  const removeTypeControlLabel = {
    controlLabel: 'Remove object',
    description: 'To remove something from your image, use the drawing tools to select the part you want to remove.',
    controlName: 'Image mask',
  };

  const controlLabel = {
    ...(addTask && addTypeControlLabel),
    ...(removeTask && removeTypeControlLabel),
    controlName: 'Image mask',
    controlLabel: addTask ? addTypeControlLabel.controlLabel : removeTypeControlLabel.controlLabel,
    description: addTask ? addTypeControlLabel.description : removeTypeControlLabel.description,
    titleSize: HEADING_TEXT_SIZE.SMALL,
  };

  const handleTextPrompt = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPrompt(e.target.value);
  };

  const {
    submissionQuery: { data: result },
    updateWorkflowOptions,
    submitWorkflow,
    isPending,
    isError,
    error,
  } = useWorkflow<InsertAction | RemoveAction>({
    workFlowId: WorkflowId.IMAGE_EDITOR,
    userAlias: userDetails?.alias,
    pageName: activePage,
    defaultWorkflowOptions: {
      task_type: 'removal',
      image_ref: '2770e255-1253-46a0-8524-32fc98a757a3', // needed for testing purposes only on local environment - (goes with ./dev-test-image.png)
      mask_ref: '', // can be empty, will be replaced on submit
      seed: 47,
      image_count: 4,
    },
    setPendingGeneration,
  });

  const canSubmitWorkflow =
    (!isPending && taskType === WORKFLOW_TYPES.INPAINTING && imageMask && prompt) || (!isPending && taskType === WORKFLOW_TYPES.REMOVAL && imageMask);

  const handleGenerate = () => {
    if (canSubmitWorkflow) {
      clearActiveEdit();
      submitWorkflow({
        mask_ref: {
          urlOrFile: imageMask,
          contentCategory: ImageCategory.MASK_IMAGE,
        },
        image_ref: {
          urlOrFile: baseImage!,
          contentCategory: ImageCategory.REFERENCE_IMAGE,
          fileTypeOverride: 'image/png',
        },
      });
    }
  };

  useEffect(() => {
    if (isError) {
      setWorkflowState(WorkflowSubmission.FAILED);
    } else if (isPending) {
      setWorkflowState(WorkflowSubmission.RUNNING);
    } else {
      setWorkflowState(WorkflowSubmission.IDLE);
    }
  }, [isError, isPending]);

  useEffect(() => {
    const sanitizedPrompt = prompt && sanitiseText(prompt);
    if (taskType === WORKFLOW_TYPES.INPAINTING && sanitizedPrompt) {
      updateWorkflowOptions({ task_type: WORKFLOW_TYPES.INPAINTING, prompt });
    } else if (taskType === WORKFLOW_TYPES.REMOVAL) {
      updateWorkflowOptions({ task_type: WORKFLOW_TYPES.REMOVAL });
    }
  }, [taskType, prompt]);

  useEffect(() => {
    if (result) {
      setActiveEditResults(result);
    } else {
      setActiveEditResults(undefined);
    }
  }, [result]);

  useEffect(() => {
    if (isError) {
      setActiveEditErrorMessage(error?.message);
      console.error('Error while generating results', error);
    }
  }, [isError]);

  return (
    <div className={styles.addRemoveControlWrapper}>
      <ControlLabel title={controlLabel.controlLabel} subTitle={controlLabel.description} titleSize={controlLabel.titleSize} />
      <ImageEditingCanvas setImageMask={setImageMask} setBaseImage={setBaseImage} />
      {taskType === WORKFLOW_TYPES.INPAINTING && (
        <div className={styles.promptInputWrapper}>
          <ControlLabel title={'Prompt'} titleSize={HEADING_TEXT_SIZE.SMALL} />
          <div className={styles.promptInput}>
            <TextAreaFormGroup
              id="studio-edit-insert-prompt"
              data-testid="studio-edit-insert-prompt"
              onBlur={handleTextPrompt}
              onMouseLeave={handleTextPrompt as unknown as MouseEventHandler<HTMLTextAreaElement>}
              label=""
              hideLabel={true}
              inline={false}
              placeholder="Describe the changes you'd like to make to your image."
            />
          </div>
        </div>
      )}
      <div className={styles.generateButtonWrapper}>
        <AnimatedButton
          dataTestId="studio-edit-generate-button"
          style={{ minWidth: '150px' }}
          animate={workflowState === WorkflowSubmission.RUNNING}
          disabled={!canSubmitWorkflow}
          clickHandler={handleGenerate}
          text={workflowState === WorkflowSubmission.RUNNING ? 'Generating' : 'Generate'}
          fullscreen={false}
        ></AnimatedButton>
      </div>
    </div>
  );
};
