import React, { useContext, useCallback } from 'react';
import { SnackbarDispatchContext } from 'src/v2/contexts/snackbar/SnackbarContext';
import { SnackbarType, SnackbarActionType } from 'src/v2/contexts/snackbar/types';
import { v6 as uuidV6 } from 'uuid';

const PROCESSING_TIMEOUT = 3000;

export const useNotificationActions = () => {
  const dispatch = useContext(SnackbarDispatchContext);
  const addProcessingNotification = useCallback(
    (props: { SnackbarContent: React.FC; processingTimeoutOverride?: number }) => {
      const notification = {
        id: uuidV6(),
        type: SnackbarType.LOADING,
        disableAutoDismissal: true,
        SnackbarContent: props.SnackbarContent,
      };

      const processingTimeout = setTimeout(() => {
        dispatch({
          type: SnackbarActionType.ADD_NOTIFICATION,
          payload: notification,
        });
      }, props.processingTimeoutOverride || PROCESSING_TIMEOUT);

      return () => {
        clearTimeout(processingTimeout);
        dispatch({
          type: SnackbarActionType.REMOVE_NOTIFICATION,
          payload: notification.id,
        });
      };
    },
    [dispatch],
  );

  const addFailureNotification = useCallback(
    (props: { SnackbarContent: React.FC }) => {
      const notification = {
        id: uuidV6(),
        type: SnackbarType.ERROR,
        SnackbarContent: props.SnackbarContent,
      };

      dispatch({
        type: SnackbarActionType.ADD_NOTIFICATION,
        payload: notification,
      });
    },
    [dispatch],
  );

  const addSuccessNotification = useCallback(
    (props: { SnackbarContent: React.FC }) => {
      const notification = {
        id: uuidV6(),
        type: SnackbarType.SUCCESS,
        SnackbarContent: props.SnackbarContent,
      };

      dispatch({
        type: SnackbarActionType.ADD_NOTIFICATION,
        payload: notification,
      });
    },
    [dispatch],
  );

  return {
    addProcessingNotification,
    addFailureNotification,
    addSuccessNotification,
  };
};
