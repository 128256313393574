import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/v2/redux/store';
import { Asset } from 'src/v2/redux/types';

export const feedContentAdapter = createEntityAdapter<Asset>();

// Helper function to handle feed contents upsert logic
const upsertFeedContentsHelper = (
  state: EntityState<Asset, string>,
  feedContents: Asset[],
  insertNewContents: (currentFeedContents: Asset[], newContents: Asset[]) => Asset[],
) => {
  // Separate existing feed contents from new contents
  const existingFeedContents = feedContents.filter((feedContents) => state.ids.includes(feedContents.id));
  const newContents = feedContents.filter((feedContents) => !state.ids.includes(feedContents.id));

  // Update existing feed contents
  if (existingFeedContents.length > 0) {
    feedContentAdapter.upsertMany(state, existingFeedContents);
  }

  // Insert new contents
  if (newContents.length > 0) {
    const currentFeedContents = Object.values(state.entities);
    feedContentAdapter.setAll(state, insertNewContents(currentFeedContents, newContents));
  }
};

export const feedContentSlice = createSlice({
  name: 'feedContent',
  initialState: feedContentAdapter.getInitialState(),
  reducers: {
    prependFeedContents: (state: EntityState<Asset, string>, action: PayloadAction<Asset | Asset[]>) => {
      const feedContents = Array.isArray(action.payload) ? action.payload : [action.payload];
      upsertFeedContentsHelper(state, feedContents, (currentFeedContents, newContents) => [...newContents, ...currentFeedContents]);
    },

    appendFeedContents: (state: EntityState<Asset, string>, action: PayloadAction<Asset | Asset[]>) => {
      const feedContents = Array.isArray(action.payload) ? action.payload : [action.payload];
      upsertFeedContentsHelper(state, feedContents, (currentFeedContents, newContents) => [...currentFeedContents, ...newContents]);
    },

    removeFeedContents: (state, action: PayloadAction<string | string[]>) => {
      const ids = Array.isArray(action.payload) ? action.payload : [action.payload];
      feedContentAdapter.removeMany(state, ids);
    },

    // Replaces all existing feed contents
    replaceFeedContents: feedContentAdapter.setAll,
  },
});

// Export actions
export const { prependFeedContents, appendFeedContents, removeFeedContents, replaceFeedContents } = feedContentSlice.actions;

// Export selectors
export const {
  selectById: getFeedContentById,
  selectAll: getAllFeedContents,
  selectIds: getFeedContentIds,
} = feedContentAdapter.getSelectors((state: RootState) => state.feedContent);

export default feedContentSlice.reducer;
