import { Text, Button } from '@amzn/storm-ui';
import { useContext, useEffect, useState } from 'react';
import { AnimatedButton } from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/AnimatedButton';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import { ImageCategory, ResizeAction, WorkflowId } from 'src/components/imageModal/components/utils';
import { ASPECT_RATIO, ResizeDirection } from 'src/components/imageModal/components/utils/types';
import { ImageModalContext } from 'src/components/imageModal/ImageModalContext';
import { HEADING_TEXT_SIZE } from 'src/constants';
import { useWorkflow } from 'src/hooks/useWorkflow';
import landScapeIcon from 'src/icons/aspectRatioFrames/landscape.png';
import portraitIcon from 'src/icons/aspectRatioFrames/portrait.png';
import rectangleIcon from 'src/icons/aspectRatioFrames/rectangle.png';
/* ENABLE when prompt is ready for implementation */
// import { sanitiseText as sanitizeText } from 'src/helpers';
import ExpandHorizontalLeft from 'src/icons/expansionDirections/horizontal-left.png';
import ExpandHorizontalMiddle from 'src/icons/expansionDirections/horizontal-middle.png';
import ExpandHorizontalRight from 'src/icons/expansionDirections/horizontal-right.png';
import ExpandVerticalDown from 'src/icons/expansionDirections/vertical-down.png';
import ExpandVerticalMiddle from 'src/icons/expansionDirections/vertical-middle.png';
import ExpandVerticalUp from 'src/icons/expansionDirections/vertical-up.png';
import styled from 'styled-components';
import styles from './ReframeControls.module.scss';
import { getResizeDirection } from './utils';
import { AppContext } from '../../../../../AppContext';

export const ControlButton = styled(Button)`
  && {
    background-color: var(--secondary-button-color-variant-2);
    color: var(--text-color-variant-1);
    border: none;
  }

  &&:hover {
    background-color: var(--secondary-button-color-variant-2);
    color: var(--text-color-variant-1);
  }

  &&:disabled {
    background-color: var(--disabled-button-color);
  }
`;

// NOTE: Any disabled aspect ratios is due to either no support at this time or other issues that need attention
export const aspectRatios = [
  {
    id: '1.91:1',
    title: 'Horizontal 1.91:1',
    subText: 'Sponsored Brands',
    icon: landScapeIcon,
  },
  // {
  //   id: '3:1',
  //   title: 'Horizontal 3:1',
  //   subText: 'Stores',
  //   icon: landScapeIcon,
  // },
  {
    id: '1:1',
    title: 'Square 1:1',
    subText: 'Posts, Stores',
    icon: rectangleIcon,
  },
  // {
  //   id: '4:5',
  //   title: 'Vertical 4:5',
  //   subText: 'Posts',
  //   icon: portraitIcon,
  // },
  {
    id: '9:16',
    title: 'Vertical 9:16',
    subText: 'Posts, Sponsored Display',
    icon: portraitIcon,
  },
];

interface ReframeControlsProps {
  reframeAspectRatio: (ratio: string | undefined) => void;
  reframeDirection: (direction: string | undefined) => void;
}

export const ReframeControls = ({ reframeAspectRatio, reframeDirection }: ReframeControlsProps) => {
  const [selectedAspectRatio, setSelectedAspectRatio] = useState<string | undefined>();
  const [selectedDirection, setSelectedDirection] = useState<string | undefined | ResizeDirection>();
  const [prompt, setPrompt] = useState<string>();
  const {
    clearActiveEdit,
    imageReferenceId,
    imageUrl,
    setActiveEditResults,
    setActiveEditErrorMessage,
    studioInputSettings,
    setPendingGeneration,
    savedEditsImageUrl,
    setActiveEditsAspectRatio,
    savedEditsImageAspectRatio,
  } = useContext(ImageModalContext);
  const originalImageAspectRatio = savedEditsImageAspectRatio || studioInputSettings?.format;

  // logic needed to determine what direction buttons to show (horizontal or vertical)
  const isSelectedVerticalMode =
    (selectedAspectRatio && ['4:5', '9:16'].includes(selectedAspectRatio)) ||
    (selectedAspectRatio === '1:1' && originalImageAspectRatio === '1.91:1') ||
    originalImageAspectRatio === '3:1';

  const imageRef = savedEditsImageUrl === '' && !imageUrl.includes('catwalk-generated-assets') ? imageReferenceId : savedEditsImageUrl || imageUrl;
  const { activePage, userDetails } = useContext(AppContext);

  const {
    updateWorkflowOptions,
    submissionQuery: { data: result },
    submitWorkflow,
    isPending,
    error,
    isError,
  } = useWorkflow<ResizeAction>({
    workFlowId: WorkflowId.GENERATIVE_RESIZING,
    userAlias: userDetails?.alias,
    pageName: activePage,
    defaultWorkflowOptions: {
      aspect_ratio: ASPECT_RATIO['1:1'],
      layout: ResizeDirection.HORIZONTAL_MIDDLE,
      prompt: '',
      theme: 'no_theme',
      imageRef: '2770e255-1253-46a0-8524-32fc98a757a3', // needed for testing purposes only on local environment
    },
    setPendingGeneration,
  });

  const expansionDirectionData = () => {
    if (isSelectedVerticalMode) {
      return [
        { id: 'vertical_down', title: 'Downwards direction', icon: ExpandVerticalDown },
        { id: 'vertical_middle', title: 'Both upwards and downwards direction', icon: ExpandVerticalMiddle },
        { id: 'vertical_up', title: 'Upwards direction', icon: ExpandVerticalUp },
      ];
    }
    return [
      { id: 'horizontal_right', title: 'Right direction', icon: ExpandHorizontalRight },
      { id: 'horizontal_middle', title: 'Both left and right direction', icon: ExpandHorizontalMiddle },
      { id: 'horizontal_left', title: 'Left direction', icon: ExpandHorizontalLeft },
    ];
  };

  const handleSelectedAspectRatio = (ratio: string) => {
    setSelectedAspectRatio(ratio);
    reframeAspectRatio(ratio.replace(':', '/')); // replacing character for CSS aspect ratio spec
  };

  const handleSelectedDirection = (direction: string) => {
    setSelectedDirection(direction);
    reframeDirection(direction);
  };

  /* ENABLE when prompt is ready for implementation */
  // const handleTextPrompt = (e: any) => {
  //   const sanitizedPrompt = sanitizeText(e.target.value);
  //   setPrompt(sanitizedPrompt);
  // };

  const generateResults = async () => {
    clearActiveEdit();
    // Must capture the active edit aspect ratio just before the job submission to ensure it's
    // available when selecting a generation result
    setActiveEditsAspectRatio(selectedAspectRatio);
    await submitWorkflow({
      imageRef: {
        urlOrFile: imageRef,
        contentCategory: ImageCategory.REFERENCE_IMAGE,
        fileTypeOverride: 'image/png',
      },
    });
  };

  const handleCleanup = () => {
    setSelectedAspectRatio(undefined);
    reframeAspectRatio(undefined);
    setSelectedDirection(undefined);
    reframeDirection(undefined);
    setPrompt(undefined);
  };

  // prepare for workflow submission
  useEffect(() => {
    updateWorkflowOptions({
      aspect_ratio: ASPECT_RATIO[selectedAspectRatio as ASPECT_RATIO],
      layout: getResizeDirection(selectedDirection) as ResizeDirection,
      prompt,
    });
  }, [selectedAspectRatio, selectedDirection, prompt]);

  // handles auto selection of centre expansion direction button (both directions) when changing orientations
  useEffect(() => {
    if (!selectedAspectRatio) {
      return;
    }
    handleSelectedDirection(isSelectedVerticalMode ? 'vertical_middle' : 'horizontal_middle');
  }, [isSelectedVerticalMode]);

  // handles auto selection of center expansion direction button if its not set yet
  useEffect(() => {
    if (selectedAspectRatio && !selectedDirection) {
      handleSelectedDirection(isSelectedVerticalMode ? 'vertical_middle' : 'horizontal_middle');
    }
  }, [selectedAspectRatio]);

  // sets the active edit image after generation
  useEffect(() => {
    if (result) {
      setActiveEditResults(result);
    } else {
      setActiveEditResults(undefined);
    }
  }, [result?.jobs?.[0].status]);

  // clear the controls and start over (after generation and save)
  useEffect(() => {
    if (!savedEditsImageUrl) return;
    // needed to apply new aspect ratio of the saved image on the studio context item
    handleCleanup();
  }, [savedEditsImageUrl]);

  // clean up for reframe unmount (ex: reset UI when changing edit modes)
  useEffect(() => {
    return () => {
      handleCleanup();
    };
  }, []);

  /* print out debug details of the workflow updates to the console
  - enable when debugging */
  // useEffect(() => {
  //   console.debug('Reframe - Workflow Options Update', workflowOptions);
  // }, [workflowOptions]);

  // sets any error message that may have occurred
  useEffect(() => {
    if (isError) {
      setActiveEditErrorMessage(error?.message);
    }
  }, [isError]);

  return (
    <div className={styles.reframeWrapper}>
      {/* Page title */}
      <ControlLabel
        title={'Extend background'}
        titleSize={HEADING_TEXT_SIZE.SMALL}
        subTitle="To extend the background of your image, choose an aspect ratio and the direction you want to go. "
      />

      {/* Aspect Ratio Format */}
      <div className={styles.controlSection}>
        <ControlLabel title={'Aspect ratio'} titleSize={HEADING_TEXT_SIZE.SMALL} />
        <div className={styles.aspectRatio} data-testid={`aspect-ratio-button-wrapper`}>
          {aspectRatios
            .filter((item) => item.id !== originalImageAspectRatio)
            .map((ratio) => (
              <ControlButton
                data-testid={`aspect-ratio-button-${ratio.id}`}
                key={ratio.id}
                className={`${styles.aspectRatioItem} ${ratio.id === selectedAspectRatio ? styles.selected : ''}`}
                id={ratio.id}
                title={ratio.title}
                onClick={() => handleSelectedAspectRatio(ratio.id)}
              >
                <div className={styles.iconWrapper}>
                  <img data-testid={`aspect-ratio-button-image-${ratio.id}`} className={styles.icon} src={ratio.icon} alt={ratio.title} />
                </div>
                <div className={styles.textWrapper}>
                  <Text data-testid={`aspect-ratio-button-title-${ratio.id}`} className={styles.title}>
                    {ratio.title}
                  </Text>
                  <Text data-testid={`aspect-ratio-button-subText-${ratio.id}`} className={styles.subText}>
                    {ratio.subText}
                  </Text>
                </div>
              </ControlButton>
            ))}
        </div>
      </div>

      {/* Expand Direction */}
      <div className={styles.controlSection}>
        <ControlLabel title={'Direction'} titleSize={HEADING_TEXT_SIZE.SMALL} />
        <div className={styles.expandDirection} data-testid={`expand-direction-button-wrapper`}>
          {expansionDirectionData().map((expansion) => (
            <ControlButton
              data-testid={`expand-direction-button-${expansion.id}`}
              key={expansion.id}
              className={`${styles.expandDirectionItem} ${expansion.id === selectedDirection ? styles.selected : ''}`}
              style={isSelectedVerticalMode ? { padding: '5px 10px' } : undefined} // needed padding override as per mocks
              id={expansion.id}
              title={expansion.title}
              onClick={() => handleSelectedDirection(expansion.id)}
            >
              <div className={styles.iconWrapper}>
                <img data-testid={`expand-direction-button-image-${expansion.id}`} src={expansion.icon} alt={expansion.title} />
              </div>
            </ControlButton>
          ))}
        </div>
      </div>

      {/* Prompt input - UNCOMMENT this when ready for implementation */}
      {/* <div className={styles.controlSection}>
        <ControlLabel
          title={'Prompt (optional)'}
          titleSize={HEADING_TEXT_SIZE.MEDIUM}
        />
        <div className={styles.promptInputWrapper}>
          <TextAreaFormGroup
            className={styles.promptInput}
            id="studio-edit-reframe-prompt"
            data-testid="studio-edit-reframe-prompt"
            onBlur={handleTextPrompt}
            onMouseLeave={handleTextPrompt}
            label=""
            hideLabel={true}
            inline={false}
            placeholder="Describe the changes you'd like to make to your image"
          />
        </div>
      </div> */}

      {/* Generate Button */}
      <div className={styles.controlSection}>
        <AnimatedButton
          dataTestId="studio-edit-generate-button"
          animate={isPending}
          disabled={isPending || (!selectedAspectRatio && !selectedDirection)}
          clickHandler={generateResults}
          text={isPending ? 'Generating' : 'Generate'}
          fullscreen
        ></AnimatedButton>
      </div>
    </div>
  );
};
