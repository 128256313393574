import { AppContextState } from 'src/../AppContext';
import { ContentType } from '../redux/types';
import { WorkflowNames } from 'src/constants';
import { LOCAL_STORAGE_KEY_THEME_PREFERENCE } from 'src/constants';
import { Themes } from 'src/v2/redux/slices/theme/themeTypes';

export const getIsManagerAccount = (appContext: AppContextState): boolean => {
  if (appContext.accountType === 'external' && !appContext.selectedAdvertisingAccount) {
    return true;
  }
  return false;
};

// removes empty or undefined classes based on re-usable component props data and keeps attribute cleaner
export const getNormalizedClassNames = (classNames: string[]) => {
  return classNames.filter((item) => item !== undefined && item.length > 0).join(' ');
};

// verifies proper color string format and returns a default if invalid
export const checkColorStringFormat = (color: string) => {
  if (color.length === 0 || (!color.startsWith('#') && !color.startsWith('rgb') && !color.startsWith('rgba'))) {
    console.warn('Invalid color string format. Please provide a valid color string. Defaulting to black #000000');
    return '#000000';
  }
  return color;
};

// replaces spaces if present and returns test id
export const getDataTestId = (testId: string | undefined): string => {
  return testId && testId?.length > 0 ? testId.replace(/ /g, '-') : '';
};

export const getFeedContentTypeByWorkflowId = (workflowId?: string) => {
  switch (workflowId) {
    case WorkflowNames.LIFESTYLE_IMAGERY:
    case WorkflowNames.LIFESTYLE_IMAGERY_PROMPT_ONLY:
      return ContentType.LIFESTYLE_IMAGE;
    case WorkflowNames.TEXT_TO_IMAGE:
      return ContentType.PRODUCT_IMAGE;
    case WorkflowNames.PARALLAX_MOTION:
      return ContentType.LIVE_IMAGE;
    case WorkflowNames.IMPORT_IMAGE:
      return ContentType.UPLOADED_IMAGE;
    case WorkflowNames.GENERATIVE_RESIZING:
    case WorkflowNames.IMAGE_EDITOR:
    case WorkflowNames.IMAGE_THEMING:
      return ContentType.EDITED_IMAGE;
    default:
      return '';
  }
};

// Get user's OS Theme system preference
export const getThemeFromSystemPreference = (): Themes => {
  if (typeof window !== 'undefined' && window.matchMedia) {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? Themes.DARK : Themes.LIGHT;
  }
  return Themes.LIGHT;
};

// Get user's theme preference from local storage
export const getThemeFromLocalStorage = (): Themes | undefined => {
  const theme = localStorage.getItem(LOCAL_STORAGE_KEY_THEME_PREFERENCE);
  if (theme && Object.values(Themes).includes(theme as Themes)) {
    return theme as Themes;
  }
};
