export function getCookieByName(name: string) {
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    cookie = cookie.trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1).replace(/"/g, '');
    }
  }
  return null;
}

export interface CookieOptions {
  expires?: Date | string | number;
  path?: string;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: 'Strict' | 'Lax' | 'None';
}

export function setCookie(name: string, value: string, options?: CookieOptions): void {
  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  if (options) {
    if (options.expires) {
      let expires: Date;
      if (typeof options.expires === 'number') {
        const date = new Date();
        date.setTime(Date.now() + options.expires * 24 * 60 * 60 * 1000);
        expires = date;
      } else {
        expires = new Date(options.expires);
      }
      cookieString += `; expires=${expires.toUTCString()}`;
    }

    if (options.path) {
      cookieString += `; path=${options.path}`;
    }

    if (options.domain) {
      cookieString += `; domain=${options.domain}`;
    }

    if (options.secure) {
      cookieString += `; secure`;
    }

    if (options.httpOnly) {
      cookieString += `; httpOnly`;
    }

    if (options.sameSite) {
      cookieString += `; SameSite=${options.sameSite}`;
    }
  }

  document.cookie = cookieString;
}
