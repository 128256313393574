import { createEntityAdapter, createSlice, Update } from '@reduxjs/toolkit';
import { AspectRatioType } from 'src/v2/redux/types';
import { ProductLayout } from './userInputTypes';

export const productLayoutAdapater = createEntityAdapter<ProductLayout>();
const { selectById, selectAll } = productLayoutAdapater.getSelectors();
export const userInputSlice = createSlice({
  name: 'userInput',
  initialState: {
    aspectRatio: AspectRatioType.HORIZONTAL_191_TO_1,
    productLayouts: productLayoutAdapater.getInitialState(),
  },
  reducers: (create) => ({
    setUserInputAspectRatio: create.reducer<AspectRatioType>((state, action) => {
      state.aspectRatio = action.payload;
    }),
    appendProductLayout: create.reducer<ProductLayout>((state, action) => {
      productLayoutAdapater.addOne(state.productLayouts, action.payload);
    }),
    removeProductLayout: create.reducer<ProductLayout['id']>((state, action) => {
      productLayoutAdapater.removeOne(state.productLayouts, action.payload);
    }),
    removeAllProductLayouts: create.reducer((state) => {
      productLayoutAdapater.removeAll(state.productLayouts);
    }),
    updateProductLayout: create.reducer<Update<ProductLayout, ProductLayout['id']>>((state, action) => {
      productLayoutAdapater.updateOne(state.productLayouts, action.payload);
    }),
  }),
  selectors: {
    getAspectRatio: (state) => state.aspectRatio,
    getAllProductLayouts: (state) => selectAll(state.productLayouts),
    getProductLayoutById: (state, id: string) => selectById(state.productLayouts, id),
  },
});
export const { setUserInputAspectRatio, removeAllProductLayouts, appendProductLayout, removeProductLayout, updateProductLayout } =
  userInputSlice.actions;
export const userInputReducer = userInputSlice.reducer;
export const { getAspectRatio, getAllProductLayouts, getProductLayoutById } = userInputSlice.selectors;
