import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/v2/redux/store';
import { getThemeFromLocalStorage, getThemeFromSystemPreference } from 'src/v2/utils/utils';
import { Themes, ThemeState } from './themeTypes';

const initialState: ThemeState = {
  activeTheme: getThemeFromLocalStorage() || getThemeFromSystemPreference() || Themes.LIGHT,
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.activeTheme = state.activeTheme === Themes.LIGHT ? Themes.DARK : Themes.LIGHT;
    },
    setTheme: (state, action: PayloadAction<Themes>) => {
      state.activeTheme = action.payload;
    },
  },
  selectors: {
    getActiveTheme: (state) => state.activeTheme,
    isDarkModeActive: (state) => state.activeTheme === Themes.DARK,
  },
});

export const { toggleTheme, setTheme } = themeSlice.actions;
export const themeReducer = themeSlice.reducer;
export const { getActiveTheme, isDarkModeActive } = themeSlice.selectors;

export const setupThemeListener = (dispatch: AppDispatch) => {
  if (typeof window !== 'undefined' && window.matchMedia) {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = () => {
      const theme = getThemeFromSystemPreference();
      dispatch(setTheme(theme));
    };

    handleChange();
    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }
};
