import { SyntheticEvent } from 'react';
import { getQueryStringParam } from 'src/components/utils/getQueryStringParams';

export const API_URLS = {
  beta: 'https://beta.aistudio.amazon/beta',
  gamma: 'https://gamma.aistudio.amazon/gamma',
  prod: 'https://aistudio.amazon/prod',
  beta_legacy: 'https://beta.ailab.amazon/beta',
  gamma_legacy: 'https://gamma.ailab.amazon/gamma',
  prod_legacy: 'https://ailab.amazon/prod',
};

export function getApiBaseUrl() {
  const hostname = window.location.hostname;
  if (process.env.NODE_ENV === 'development') {
    return retrieveApiUrlBasedOnDeveloperAccount(API_URLS.beta);
  } else if (hostname.includes('beta.ailab.amazon')) {
    return retrieveApiUrlBasedOnDeveloperAccount(API_URLS.beta_legacy);
  } else if (hostname.includes('gamma.ailab.amazon')) {
    return API_URLS.gamma_legacy;
  } else if (hostname.includes('ailab.amazon')) {
    return API_URLS.prod_legacy;
  } else if (hostname.includes('beta.aistudio.amazon')) {
    return retrieveApiUrlBasedOnDeveloperAccount(API_URLS.beta);
  } else if (hostname.includes('gamma.aistudio.amazon')) {
    return API_URLS.gamma;
  } else if (hostname.includes('aistudio.amazon')) {
    return API_URLS.prod;
  } else {
    return API_URLS.prod;
  }
}

export function isProd() {
  const prodUrls = ['https://ailab.amazon/prod', 'https://aistudio.amazon/prod'];
  return prodUrls.includes(getApiBaseUrl());
}

function retrieveApiUrlBasedOnDeveloperAccount(baseUrl: string) {
  const devAlias = (typeof process !== 'undefined' && process?.env?.ALIAS) || getQueryStringParam('devBackend');
  return devAlias ? `${baseUrl}-${devAlias}` : baseUrl;
}

export const handleStormOuterModalClick = (event: SyntheticEvent, setterAction: () => void): void => {
  const element = event.nativeEvent.target as HTMLElement;
  if (!element) return;
  const isModalOuterContainer = element.getAttribute('role') === 'presentation';
  if (isModalOuterContainer) {
    setterAction();
  }
};
