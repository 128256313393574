import Genaihubbackend, {
  BatchResultJobsItemStatus,
  Document,
  RetrieveResultByWorkflowIdAndBatchIdOutput,
  WorkflowId,
} from '@amzn/genaihub-typescript-client';
import { Publisher } from '@amzn/katal-metrics';
import { FAILURE_MESSAGE } from 'src/components/snackbar/notifications/ImageGenerationNotifications';
import { getErrorMessage } from 'src/components/utils/getErrorMessage';
import { getWorkflowOptionsMetrics } from 'src/components/utils/getWorkflowOptionsMetrics';
import { WorkflowNames, Metrics } from 'src/constants';
import { CounterMetrics, MetricsProvider, StringMetrics } from 'src/metrics';

const RETRY_MAX = 2;

export interface WorkflowSubmissionProps {
  workflowId: WorkflowId;
  client: Genaihubbackend;
  entityId?: string;
  metrics?: MetricsProvider;
  studioRequest?: boolean;
}

export type WorkflowStatus = BatchResultJobsItemStatus | 'ABANDONED' | 'IDLE';
export type WorkflowOptions = Record<string, unknown>;
export type WorkflowSummery = {
  status: WorkflowStatus;
  message?: string;
  output: RetrieveResultByWorkflowIdAndBatchIdOutput;
};

export class WorkflowSubmission {
  static readonly COMPLETED = 'COMPLETED';
  static readonly RUNNING = 'RUNNING';
  static readonly HALTED = 'HALTED';
  static readonly FAILED = 'FAILED';
  static readonly PENDING = 'PENDING';
  static readonly ABANDONED = 'ABANDONED';
  static readonly IDLE = 'IDLE';

  private workflowId: WorkflowId;
  private workflowOptions?: WorkflowOptions;
  private batchId?: string;
  private entityId?: string;
  private status: WorkflowStatus;
  private message?: string;
  private results: RetrieveResultByWorkflowIdAndBatchIdOutput | null;

  private metrics?: MetricsProvider;
  private publisher?: Publisher;
  private interval?: ReturnType<typeof setInterval>;
  private genAIBackendClient: Genaihubbackend;
  private submissionTime: number;
  private timeout: number;
  private computedWorkflowTime: number;
  private inProgress: boolean = false;
  private isVerbose: boolean = false;
  private studioRequest: boolean = false;
  private retryCount: number = 0;

  constructor({ workflowId, client, entityId, metrics, studioRequest }: WorkflowSubmissionProps) {
    this.workflowId = workflowId;
    this.metrics = metrics;
    this.genAIBackendClient = client;
    this.entityId = entityId;
    this.studioRequest = !!studioRequest;

    this.results = null;
    this.status = WorkflowSubmission.IDLE;
    this.timeout = 90000;
    this.submissionTime = 0;
    this.computedWorkflowTime = 0;
  }

  withClient(client: Genaihubbackend) {
    this.genAIBackendClient = client;
    return this;
  }

  withMetrics(metrics: MetricsProvider) {
    this.metrics = metrics;
    this.publisher = undefined;
    return this;
  }

  verbose() {
    this.isVerbose = true;
    return this;
  }

  private trackMetrics(strings?: StringMetrics, counters?: CounterMetrics) {
    if (this.metrics) {
      if (!this.publisher) {
        this.publisher = this.metrics.trackMetrics(Metrics.Methods.WorkflowMetrics, strings, counters);
      } else {
        this.metrics.trackMetricsWithPublisher(this.publisher, strings, counters);
      }
    }
    return this;
  }

  async submitJob(workflowOptions: Document, timeout?: number, interval?: number) {
    this.results = null;
    this.workflowOptions = workflowOptions as WorkflowOptions;
    this.submissionTime = Date.now();
    this.inProgress = true;
    this.retryCount = 0;
    if (timeout) {
      this.timeout = timeout;
    }
    const input = {
      workflowId: this.workflowId,
      studioRequest: this.studioRequest,
      body: {
        workflowOptions,
      },
    };

    this.trackMetrics(
      {
        [Metrics.Names.WorkflowId]: this.workflowId ?? Metrics.Values.Unknown,
        ...(this.isVerbose ? getWorkflowOptionsMetrics(workflowOptions) : {}),
      },
      { [Metrics.Counters.Count]: 1 },
    );

    try {
      const response = await this.genAIBackendClient.submitWorkflowById(input);
      if (response.body.batchId) {
        this.batchId = response.body.batchId;
        if (this.isVerbose) {
          this.trackMetrics({ [Metrics.Names.BatchId]: response.body.batchId });
        }
      } else {
        throw 'Job submission failed';
      }
    } catch (error) {
      console.error(error);
      this.stopJob();
      this.status = WorkflowSubmission.FAILED;
      this.message = await getErrorMessage(error);
      this.trackMetrics({ [Metrics.Names.Error]: this.message }, { [Metrics.Counters.Failure]: 1 });
      throw error;
    }

    // There must be a batchId so good to poll but don't catch it's errors
    return await this.getJobStatus(interval);
  }

  getJobStatus(interval: number = 5000) {
    return new Promise<WorkflowSummery>((resolve, reject) => {
      // If there is no batchid and not in progress there's no job
      if (this.batchId && this.inProgress) {
        this.interval = setInterval(async () => {
          try {
            // If the batchId was messed with then throw an error
            if (!this.batchId) {
              throw 'Job submission failed';
            }
            // Check if it was abandoned
            if (this.status === WorkflowSubmission.ABANDONED) {
              throw 'Abandoned';
            }

            const response = await this.genAIBackendClient.retrieveResultByWorkflowIdAndBatchId({
              batchId: this.batchId,
              workflowId: this.workflowId,
              ...(this.entityId ? { entityId: this.entityId } : {}),
              studioRequest: this.studioRequest,
            });

            // Check for jobs
            if (response?.body?.jobs?.length && response.body.jobs[0].status) {
              const workflowTime = this.computeWorkflowTime();
              this.status = response.body.jobs[0].status;
              this.message = response.body.jobs[0].message;
              this.retryCount = 0; // If no errors then reset retry count

              // Check to see if the job has timed out first
              if (workflowTime > this.timeout && this.status !== WorkflowSubmission.COMPLETED) {
                this.status = WorkflowSubmission.FAILED;
                this.message = FAILURE_MESSAGE.TIME_OUT;
                this.trackMetrics({}, { [Metrics.Names.Time]: workflowTime, [Metrics.Counters.Timeout]: 1 });
                throw FAILURE_MESSAGE.TIME_OUT;
              } else if (this.status === WorkflowSubmission.FAILED || this.status === WorkflowSubmission.HALTED) {
                throw this.message;
              } else if (this.status === WorkflowSubmission.COMPLETED) {
                this.results = response;
                this.stopJob();
                this.message = 'Job submission complete';
                this.trackMetrics({}, { [Metrics.Counters.Success]: 1, [Metrics.Names.Time]: workflowTime });

                // Image workflows only
                if (this.isVerbose && (this.workflowId === WorkflowNames.LIFESTYLE_IMAGERY || this.workflowId === WorkflowNames.TEXT_TO_IMAGE)) {
                  // Was an ASIN selected?
                  const asin = this.workflowOptions?.asin || '';
                  const product_image = this.workflowOptions?.product_image || '';
                  const selectedAsin = asin !== '' && product_image !== '';

                  // Track images generated total and by generation type
                  this.trackMetrics(
                    {},
                    {
                      [Metrics.Counters.ImagesGenerated]: response.body.jobs[0].urls?.length || 0,
                      [Metrics.Names[
                        selectedAsin
                          ? this.workflowId === WorkflowNames.LIFESTYLE_IMAGERY
                            ? 'AsinToLifestyleImage'
                            : 'AsinToProductImage'
                          : 'TextToLifestyleImage'
                      ]]: response.body.jobs[0].urls?.length || 0,
                    },
                  );
                }

                resolve({
                  status: this.status,
                  message: this.message,
                  output: this.results,
                });
              }
            } else {
              // No Jobs
              throw 'No jobs found';
            }
          } catch (error) {
            console.error(error);
            // Check to see if the submission should retry
            if (
              this.status !== WorkflowSubmission.ABANDONED &&
              this.status !== WorkflowSubmission.FAILED &&
              this.status !== WorkflowSubmission.HALTED &&
              this.retryCount < RETRY_MAX
            ) {
              // If it wasn't a job failure then retry
              this.status = WorkflowSubmission.RUNNING;
              this.message = 'Retrying';
              this.retryCount++;
            } else {
              // Otherwise the workflow has failed so stop it
              this.stopJob();
              this.status = WorkflowSubmission.FAILED;
              this.message = await getErrorMessage(error);
              this.trackMetrics(
                { [Metrics.Names.Error]: this.message },
                { [Metrics.Names.Time]: this.computeWorkflowTime(), [Metrics.Counters.Failure]: 1 },
              );
              reject(this.message);
            }
          }
        }, interval);
      } else {
        switch (this.status) {
          case WorkflowSubmission.COMPLETED:
            if (this.results) {
              return resolve({
                status: this.status,
                message: this.message,
                output: this.results,
              });
            } else {
              return reject('No results');
            }
          case WorkflowSubmission.FAILED:
          case WorkflowSubmission.HALTED:
            return reject(this.message);
          case WorkflowSubmission.ABANDONED:
            return reject('Abandoned');
          default:
            return reject('No current job');
        }
      }
    });
  }

  get jobInProgress() {
    return this.inProgress;
  }

  private computeWorkflowTime() {
    this.computedWorkflowTime = window.Date.now() - this.submissionTime;
    return this.computedWorkflowTime;
  }

  get workflowTime() {
    return this.computedWorkflowTime;
  }

  abandon() {
    if (this.inProgress) {
      this.stopJob();
      this.status = WorkflowSubmission.ABANDONED;
      this.message = 'Abandoned';
      this.trackMetrics({ [Metrics.Names.Error]: Metrics.Values.Abandoned }, { [Metrics.Values.Abandoned]: 1 });
      return true;
    }
    return false;
  }

  private stopJob() {
    clearInterval(this.interval);
    this.inProgress = false;
  }
}
