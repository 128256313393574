import { Icon } from 'src/v2/components/Icon/Icon';
import { getDataTestId, getNormalizedClassNames } from 'src/v2/utils/utils';
import { ButtonStylesWrapper } from './Button.styles';
import { getButtonIconColor } from './helpers';
import { ButtonStyleClasses, ButtonProps, ButtonSizes, ButtonTypes } from './types';

export const Button = (props: ButtonProps) => {
  const {
    borderRadius = '8px',
    darkMode = false,
    dataTestId,
    disabled = false,
    fullWidth = false,
    icon,
    iconOnly = false,
    iconOnlyBackgroundColor,
    iconOnlyColor,
    iconOnlyNoBackground = false,
    iconOnlyPaddingPercent,
    iconOnlyRounded = false,
    iconSize,
    label,
    loading = false,
    onClick,
    size = ButtonSizes.Medium,
    style = undefined,
    type = ButtonTypes.Primary,
  } = props;

  if (!label && !icon) {
    return <></>;
  }

  const showIconOnly = icon && iconOnly;
  const buttonFullWidth = fullWidth ? ButtonStyleClasses.fullWidth : '';
  const buttonSize = size?.toLowerCase();
  const buttonType = type?.toLowerCase();
  const disabledStyles = disabled ? ButtonStyleClasses.disabled : '';
  const iconOnlyRoundedStyle = !iconOnlyNoBackground && showIconOnly && iconOnlyRounded ? ButtonStyleClasses.iconOnlyRounded : '';
  const iconColor = getButtonIconColor({
    darkMode,
    disabled,
    icon,
    iconOnly,
    iconOnlyNoBackground,
    loading,
    type,
  });
  const loadingStyles = loading ? ButtonStyleClasses.loading : '';
  const darkModeStyle = darkMode ? ButtonStyleClasses.darkMode : ''; // TODO: read users' system level dark mode setting and update this line

  const handleClick = () => onClick();

  const classNames = getNormalizedClassNames([
    buttonFullWidth,
    buttonSize,
    buttonType,
    darkModeStyle,
    disabledStyles,
    iconColor,
    iconOnlyRoundedStyle,
    loadingStyles,
  ]);

  return (
    <ButtonStylesWrapper
      borderRadius={borderRadius}
      className={classNames}
      data-testid={getDataTestId(dataTestId)}
      darkMode={darkMode}
      disabled={disabled}
      iconOnlyColor={iconOnlyColor}
      iconOnlyBackgroundColor={iconOnlyBackgroundColor}
      loading={loading}
      onClick={disabled || loading ? undefined : handleClick}
      size={size}
      small={true}
      style={style}
    >
      {!loading && icon && (
        <Icon
          backgroundColor={iconOnlyBackgroundColor}
          color={iconOnlyColor}
          dataTestId={`${getDataTestId(dataTestId)}-icon`}
          paddingPercent={iconOnlyPaddingPercent}
          showBackground={showIconOnly && iconOnlyRounded}
          size={iconSize}
          type={icon}
        />
      )}
      {!showIconOnly && <span data-testid={`${getDataTestId(dataTestId)}-label`}>{label}</span>}
    </ButtonStylesWrapper>
  );
};
