import { Tools, Controls } from '@amzn/genaihub-typescript-client';
import { Card, Section, Text } from '@amzn/storm-ui';

import { useState, useEffect, useContext, Fragment, useRef, Context } from 'react';
import { AppContext } from 'src/../AppContext';
import { EditorContextP1, EditorContextStateP1 } from 'src/components/editor/EditorContext';
import ModuleFeedback from 'src/components/editor/ModuleFeedback';
import * as ControlComponents from 'src/components/editor/UiContols/uiGeneratorControls';
import * as notifications from 'src/components/snackbar/notifications/ImageGenerationNotifications';
import batchStateUpdate from 'src/components/utils/batchStateUpdate';
import { getAllQueryStringParams } from 'src/components/utils/getQueryStringParams';
import { runBeforeJobSubmission } from 'src/components/utils/workflowHelpers';
import Stack from 'src/customUIComponents/Stack';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { WorkflowSubmission } from 'src/components/utils/WorkflowSubmission';
import { useNotificationActions } from 'src/v2/contexts/snackbar/actions/useNotificationActions';
import { StyleSettingPlayground } from './components/StyleControls/StyleSetting';
import { AnimatedButton } from './uiGeneratorControls/storm/UI/AnimatedButton';

export interface ControlPanelProps {
  controls: Controls;
  editorContext: Context<EditorContextStateP1>;
  setGeneratedResults: (results: object) => void;
  appendGeneratedResults: (results: object) => void;
  appendGeneratedTextResults: (results: object) => void;
  bulkUpdateCallback: (controls: Controls) => void;
  type: string;
}

const ControlPanel = ({ controls, appendGeneratedResults, appendGeneratedTextResults, bulkUpdateCallback, ...rest }: ControlPanelProps) => {
  const appContext = useContext(AppContext);
  const { metrics, selectedTool, tools } = appContext;
  const editorContext = useContext(EditorContextP1);
  const [workflowState, setWorkflowState] = useState<string>(WorkflowSubmission.IDLE);
  const currentWorkflow = useRef<WorkflowSubmission | null>();
  const isMounted = useRef<boolean>(false);
  const controlObject = useRef(controls);
  const { addFailureNotification } = useNotificationActions();

  const [isThemeControlPresent, setIsThemeControlPresent] = useState<boolean>(false);

  useEffect(() => {
    // TODO: Override controls, remove this bodge.
    if (controlObject.current) {
      const styleControlIndex = controlObject.current.findIndex((control: { controlName: string }) => control.controlName == 'theme');
      const filteredControls = controlObject.current.filter((control) => control !== controls[styleControlIndex]);
      const restyleSelected = selectedTool === 'IMAGE_THEMING';

      controlObject.current = filteredControls;

      setIsThemeControlPresent(styleControlIndex && restyleSelected ? true : false);
    }
  }, []);

  const controlComponents: any = ControlComponents;
  const [controlValidation, setControlValidation] = useState<any>([]);
  const [validated, setValidated] = useState<boolean>(false);
  const [controlTitle, setControlTitle] = useState<string>('');
  const [toolDesc, setToolDesc] = useState<string>('');
  const [runNextJob, setRunNextJob] = useState<boolean>(false);
  let controlValues = editorContext.workflowOptions;
  const client = useAIBackendHubClient();
  const urlProps = getAllQueryStringParams();
  const [autoGenerate, setAutoGenerate] = useState(false);
  const [generatedPrompt, setGeneratedPrompt] = useState<string>();
  const [generationTheme, setGenerationTheme] = useState<string>('no_theme');

  const onThemeSelectedHandler = (theme: string) => {
    setGenerationTheme(theme);
  };

  useEffect(() => {
    setControlTitle(tools.filter((tool: Tools) => tool.type === selectedTool).map((item: Tools) => item.title)?.[0]);
    setToolDesc(tools.filter((tool: Tools) => tool.type === selectedTool).map((item: Tools) => item.description.split('|')?.[1])?.[0]);
    isMounted.current = true;

    if (urlProps.generate && urlProps.prompt) {
      const controlData: any = {};
      controlData['prompt'] = { value: urlProps.prompt };
      batchStateUpdate(controlData, bulkUpdateCallback, 500);
      setAutoGenerate(true);
    }

    // Unmount clean up for workflows
    return () => {
      // Mark as unmounted so no workflows continue
      isMounted.current = false;

      // If a workflow is currently running; abandon it
      if (currentWorkflow.current?.jobInProgress) {
        currentWorkflow.current.abandon();
      }
    };
  }, []);

  const addControlValidation = (controlName: any) => {
    const index = controlObject.current.findIndex((obj: any) => obj['controlName'] === controlName);
    const control = controlObject.current[index];
    if (control) {
      control.mandatory = true;
    }
  };

  const removeControlValidation = (controlName: any) => {
    const index = controlObject.current.findIndex((obj: any) => obj['controlName'] === controlName);
    const control = controlObject.current[index];
    if (control) {
      control.mandatory = false;
    }
  };

  useEffect(() => {
    validateControls();
  }, [controlValues]);

  useEffect(() => {
    if (workflowState !== WorkflowSubmission.RUNNING) {
      if (controlValidation.length) {
        setValidated(false);
      } else {
        setValidated(true);
        autoGenerateWorkflow();
      }
    } else {
      setValidated(false);
    }
  }, [controlValidation, workflowState]);

  const autoGenerateWorkflow = () => {
    // generate called from outside component to automatically invoke job
    if (autoGenerate) {
      setAutoGenerate(false);
      clickHandler();
    }
  };

  const selectControlComponent = (controlType: string) => {
    return controlType in controlComponents ? controlComponents[controlType] : () => {};
  };

  const setDefaultControlValue = () => {};

  const createControlCard = (item: any, index: number) => {
    if (!item) return <>/</>;

    const ControlComponent = selectControlComponent(item.controlType);
    return (
      <Fragment key={index}>
        {!item.visible && setDefaultControlValue()}
        {item.visible && (
          <>
            <Card
              paddingBlockEnd="none"
              paddingBlockStart="none"
              paddingInlineEnd="none"
              paddingInlineStart="none"
              style={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none', textAlign: 'left' }}
            >
              <ControlComponent
                control={item}
                bulkUpdateCallback={bulkUpdateCallback}
                workflowId={selectedTool}
                autoGenerate={urlProps?.generate}
                {...rest}
              />
            </Card>
          </>
        )}
      </Fragment>
    );
  };

  const validateControls = () => {
    const keys = Object.keys(controlValues);
    // check if in radio group control - remove object: prompt disable

    if (Object.keys(controlValues).includes('task_type')) {
      checkRadioGroupValidation(controlValues['task_type']);
    }
    const mandatoryControls = controlObject.current.filter((item: any) => item.mandatory).map((item: any) => item.controlName);
    keys.map((key) => {
      const val = mandatoryControls.indexOf(key);
      if (val !== -1) {
        mandatoryControls.splice(val, 1);
      }
    });
    setControlValidation([...mandatoryControls]);
  };

  const checkRadioGroupValidation = (value: any) => {
    if (value.value === 'removal') {
      return removeControlValidation('prompt');
    } else {
      return addControlValidation('prompt');
    }
  };

  const clickHandler = async () => {
    if (selectedTool === 'LIFESTYLE_IMAGERY' && (controlValues.prompt === undefined || controlValues.prompt.value === '')) {
      // run guided text workflow
      const controlData: any = {};
      controlData['prompt_type'] = { value: 'PRODUCT_LESS_LIFESTYLE' };
      controlValues = { ...controlValues, ...controlData };
      await runJob('GUIDED_TEXT_GENERATION', true);
    } else {
      if (selectedTool === 'LIFESTYLE_IMAGERY' && controlValues.prompt && controlValues.prompt.value.length > 0) {
        const controlData: any = {};
        controlData['rewriteUserCustomPrompt'] = { value: 'false' };
        controlValues = { ...controlValues, ...controlData };
      }
      await runJob('', false);
    }
  };

  useEffect(() => {
    if (runNextJob) {
      const controlData: any = {};
      controlData['rewriteUserCustomPrompt'] = { value: 'false' };
      controlData['prompt'] = { value: generatedPrompt };
      controlValues = { ...controlValues, ...controlData };

      runJob('', true);
    }
  }, [runNextJob]);

  const runJob = async (toolToRun: any, showTextResult: boolean) => {
    if (workflowState !== WorkflowSubmission.RUNNING) {
      // Set the statuses
      //window.scrollTo(0, 0);
      setWorkflowState(WorkflowSubmission.RUNNING);
      appendGeneratedResults({ status: WorkflowSubmission.RUNNING });

      // Uploads image for workflow
      await runBeforeJobSubmission(selectedTool ?? '', controlValues, client);

      // If the component unmounted during upload the stop workflow
      if (!isMounted.current) {
        console.log('Workflow canceled (Unmounted)');
        return;
      }

      // If another job is still currning, abandon it
      if (currentWorkflow.current?.jobInProgress) {
        currentWorkflow.current.abandon();
      }

      // Stores current workflow in a ref incase of unmount during API calls
      const workflowId = toolToRun !== '' ? toolToRun : selectedTool || '';
      currentWorkflow.current = new WorkflowSubmission({ workflowId, client, metrics }).verbose();

      // Then start and wait for workflow to finish
      try {
        const controlValuesWithTheme = {
          ...controlValues,
          theme: {
            value: isThemeControlPresent ? (generationTheme ?? 'autumn') : controlValues.theme ? controlValues.theme : 'no_theme',
          },
        };
        const workflowOptions = convertControlValuesToWorkflowOptions(controlValuesWithTheme);
        const summary = await currentWorkflow.current.submitJob(workflowOptions);
        const {
          status,
          message,
          output,
          output: {
            body: { type },
          },
        } = summary;
        const urls = output.body.jobs?.[0].urls; // WorkflowSubmission verifies that a job exists

        // Update statuses with status
        setWorkflowState(status);
        editorContext.setRawJobResponse(output);

        if (toolToRun === 'GUIDED_TEXT_GENERATION') {
          setGeneratedPrompt(urls?.[0]);
          appendGeneratedTextResults({ status, urls, type, message });
        } else {
          // clear text results if rewriteUserCustomPrompt field true
          if (!showTextResult) {
            appendGeneratedTextResults({
              status: 'COMPLETED',
              urls: [],
              type: '',
              message: '',
            });
          }

          appendGeneratedResults({ status, urls, type, message });
          setRunNextJob(false);
        }

        // If it's not running then clear the current workflow
        if (status !== WorkflowSubmission.RUNNING) {
          if (status === 'FAILED' || (type === 'VIDEO' && (!urls || urls?.length === 0))) {
            handleFailedJob(notifications.SubmitSystemFailureNotification);
          } else if (!urls || urls?.length === 0) {
            handleFailedJob(notifications.SubmitNoImageNotification);
          } else if (type === 'IMAGE' && (!urls || urls?.length < 4)) {
            addFailureNotification({ SnackbarContent: notifications.SubmitPartialFailureNotification });
          }

          currentWorkflow.current = null;
          if (toolToRun === 'GUIDED_TEXT_GENERATION') {
            setRunNextJob(true);
          }
        }
      } catch (error) {
        if (toolToRun === 'GUIDED_TEXT_GENERATION') {
          appendGeneratedTextResults({
            status: 'FAILED',
            message: `The Server responded with error status: ${error || notifications.FAILURE_MESSAGE.SYSTEM_FAILURE}`,
          });
        }
        appendGeneratedResults({
          status: 'FAILED',
          message: `The Server responded with error status: ${error || notifications.FAILURE_MESSAGE.SYSTEM_FAILURE}`,
        });
        currentWorkflow.current = null;
        setWorkflowState('FAILED');
        handleFailedJob(notifications.SubmitSystemFailureNotification);
      }
    }
  };

  const convertControlValuesToWorkflowOptions = (controlValues: any) => {
    const workflowOptions: { [key: string]: string } = {};
    for (const key in controlValues) {
      workflowOptions[key] = controlValues[key].value ?? '';
    }
    return workflowOptions;
  };

  const handleFailedJob = (snackbarMessage: any) => {
    addFailureNotification({ SnackbarContent: snackbarMessage });
    setTimeout(() => appendGeneratedResults({}), 4500);
  };

  return (
    <div style={{ width: 'inherit' }} data-testid="control-panel">
      <Section
        style={{
          color: '#232F3F',
          justifyContent: 'flex-start',
          width: 'inherit',
          minHeight: '60vh',
          maxHeight: '72vh',
          //backgroundColor: '#FAFAFA',
          padding: '0px',
          position: 'relative',
          //overflowY: 'scroll',
          // paddingRight: '10px',
          // paddingLeft: '5px',
        }}
      >
        <Text
          style={{
            fontFamily: 'Ember Modern Display Standard',
            letterSpacing: '1px',
            fontSize: '24px',
            lineHeight: '34px',
            fontWeight: '800',
            alignSelf: 'start',
            marginBottom: '12px',
          }}
        >
          {controlTitle}
        </Text>
        <Text
          style={{
            fontFamily: 'Amazon Ember',
            fontSize: '15px',
            fontWeight: '400',
            lineHeight: '150%',
            letterSpacing: '0.01em',
            alignSelf: 'start',
            textAlign: 'left',
          }}
        >
          {toolDesc}
        </Text>
        <Stack style={{ flexDirection: 'column', marginTop: '26px', gap: '44px', marginBottom: '44px' }}>
          {controlObject.current.length === 0 && <></>}
          {controlObject.current.length > 0 &&
            controlObject.current
              .sort((a: any, b: any) => a.order - b.order)
              .map((item: any, index: number) => {
                return createControlCard(item, index);
              })}
        </Stack>

        {isThemeControlPresent && (
          <>
            <StyleSettingPlayground onThemeSelected={onThemeSelectedHandler} style={{ fontSize: '15px', fontWeight: '600' }} />
          </>
        )}
        <AnimatedButton
          dataTestId="generate-button"
          style={{ marginTop: '20px' }}
          animate={workflowState == WorkflowSubmission.RUNNING}
          disabled={!validated}
          clickHandler={clickHandler}
          text={workflowState == WorkflowSubmission.RUNNING ? 'Generating' : 'Generate'}
          fullscreen
        ></AnimatedButton>
        <Stack style={{ marginTop: '20px', marginBottom: '30px' }}>
          <ModuleFeedback />
        </Stack>
      </Section>
    </div>
  );
};

export default ControlPanel;
