import { Draft } from 'immer';
import { Dispatch } from 'react';
import { SnackbarState, SnackbarAction, SnackbarActionType, TimeoutRef, ActionHandlerReturn } from 'src/v2/contexts/snackbar/types';

interface HandleActionSetExpandedProps {
  action: Extract<SnackbarAction, { type: SnackbarActionType.SET_EXPANDED }>;
  state: Draft<SnackbarState>;
  timeoutsRef: TimeoutRef;
  dispatch: Dispatch<SnackbarAction>;
}

export const handleActionSetExpanded = ({ action, state, timeoutsRef, dispatch }: HandleActionSetExpandedProps): ActionHandlerReturn => {
  const newExpandedState = action.payload;
  state.expanded = newExpandedState;

  if (!newExpandedState) {
    const notificationsToRemove = state.notifications.filter((notification) => timeoutsRef[notification.id]?.readyToClear);

    notificationsToRemove.forEach((notification) => {
      dispatch({
        type: SnackbarActionType.REMOVE_NOTIFICATION,
        payload: notification.id,
      });
    });
  }
};
