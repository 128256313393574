import { ASINMetadata } from '@amzn/genaihub-typescript-client';
import { createEntityAdapter, createListenerMiddleware, createSlice, EntityState, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { LOCAL_STORAGE_KEY_PRODUCTS } from 'src/constants';
import { AsinProduct, ProductType } from 'src/v2/redux/slices/product/productTypes';
import type { RootState } from 'src/v2/redux/store';

export const createAsinProduct = ({ asin, metadata }: { asin: string; metadata: ASINMetadata }): AsinProduct => ({
  id: asin,
  asin,
  metadata,
  type: ProductType.ASIN,
});

const initialState: AsinProduct[] = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_PRODUCTS) || '[]') as AsinProduct[];

export const productAdapter = createEntityAdapter<AsinProduct>({});

export const productSlice = createSlice({
  name: 'product',
  initialState: productAdapter.getInitialState({}, initialState),
  reducers: {
    upsertProduct: (state: EntityState<AsinProduct, string>, action: PayloadAction<AsinProduct>) => {
      if (state.ids.includes(action.payload.id)) {
        // update existing product
        productAdapter.upsertOne(state, action.payload);
      } else {
        // insert new product in order at front of list
        productAdapter.setAll(state, [action.payload, ...Object.values(state.entities)]);
      }
    },
    removeProduct: productAdapter.removeOne,
    setProducts: productAdapter.setAll,
    moveProductToTopById: (state, action: PayloadAction<string>) => {
      const product = state.entities[action.payload];
      if (!product) return;
      const index = state.ids.findIndex((id) => id === product.id);

      state.ids.splice(index, 1);
      state.ids.unshift(product.id);
    },
  },
});

export const { upsertProduct, removeProduct, setProducts, moveProductToTopById } = productSlice.actions;

export const { selectAll: getProducts, selectById: getProductById } = productAdapter.getSelectors((state: RootState) => state.product);

// store product data updates local storage for session persistence
export const productListenerMiddleware = createListenerMiddleware();
productListenerMiddleware.startListening({
  matcher: isAnyOf(upsertProduct, removeProduct, setProducts),
  effect: (_, listenerApi) => {
    const validProducts = Object.values((listenerApi.getState() as RootState).product.entities).flatMap((item) => {
      if (
        item.type !== ProductType.ASIN ||
        (item.type === ProductType.ASIN && (!item.metadata || Object.keys(item.metadata as ASINMetadata).length === 0))
      ) {
        return [];
      }

      // TODO: custom images are stored as blobs and cannot not be persisted across sessions.
      // https://taskei.amazon.dev/tasks/HB-860
      return {
        ...item,
        customImage: undefined,
        customProductImageUrl: undefined,
        selectedImageIndex: item.selectedImageIndex && item.selectedImageIndex > -1 ? item.selectedImageIndex : 0,
      };
    });

    localStorage.setItem(LOCAL_STORAGE_KEY_PRODUCTS, JSON.stringify(validProducts));
  },
});

export default productSlice.reducer;
