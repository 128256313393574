import { WorkflowId } from '@amzn/genaihub-typescript-client';
import type { AssetType } from 'src/components/imageModal/components/utils/types';
import type { StudioInputSettings } from 'src/components/pages/studio/StudioContext';

export enum ContentType {
  PRODUCT_IMAGE = 'Product image',
  LIFESTYLE_IMAGE = 'Lifestyle image',
  LIVE_IMAGE = 'Live image',
  UPLOADED_IMAGE = 'Uploaded image',
  EDITED_IMAGE = 'Edited image',
}

export enum AspectRatioType {
  HORIZONTAL_191_TO_1 = '1.91:1',
  HORIZONTAL_3_TO_1 = '3:1',
  SQUARE_1_TO_1 = '1:1',
  VERTICAL_4_TO_5 = '4:5',
  VERTICAL_9_TO_16 = '9:16',
}

export interface Asset {
  id: string;
  aspectRatio: AspectRatioType;
  contentType: ContentType;
  headline: string;
  url: string;
  workflowId: WorkflowId;
  studioInputSettings: StudioInputSettings;
  batchId: string;
  imageId: string;
  assetType: AssetType;
}
