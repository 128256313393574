import Genaihubbackend from '@amzn/genaihub-typescript-client';
import { uploadImage } from 'src/components/utils/uploadImage';
import { StudioContextType } from '../pages/studio/StudioContext';
import { LifestyleImageryWorkflowProps, TextToImageWorkflowProps } from '../pages/studio/WorkflowService';

export const generateLifestyleImageryWorkflowProps: (options: {
  backendClient: Genaihubbackend;
  studioContext: StudioContextType;
  effects: string;
  entityId: string | undefined;
  outputCount: number;
  asinImageReferenceId?: string;
  prompt?: string;
}) => LifestyleImageryWorkflowProps = ({ backendClient, studioContext, effects, entityId, outputCount, asinImageReferenceId, prompt }) => {
  return {
    client: backendClient,
    aspectRatio: studioContext.format,
    effects,
    outputCount: outputCount || 6,
    prompt: prompt || studioContext.textPrompt,
    theme: studioContext.style?.value,
    rewriteUserCustomPrompt: studioContext.enablePromptRewriting,
    adsEntityId: entityId,
    asinImageReferenceId,
    asinCategory: studioContext.asin?.metadata?.asinCategory,
    asinItem: studioContext.asin,
  } as LifestyleImageryWorkflowProps;
};

export const generateTextToImageWorkflowProps: (options: {
  backendClient: Genaihubbackend;
  studioContext: StudioContextType;
  effects: string;
  asinImageReferenceId: string;
  entityId?: string;
  outputCount?: number;
  prompt?: string;
}) => TextToImageWorkflowProps = ({ prompt, backendClient, studioContext, effects, asinImageReferenceId, entityId, outputCount }) => {
  return {
    asinItem: studioContext.asin,
    asinCategory: studioContext.asin?.metadata?.asinCategory,
    aspectRatio: studioContext.format,
    client: backendClient,
    effects,
    outputCount: outputCount || 6,
    productImage: asinImageReferenceId,
    prompt: prompt || studioContext.textPrompt,
    theme: studioContext.style?.value,
    adsEntityId: entityId,
  } as TextToImageWorkflowProps;
};

export const runBeforeJobSubmission = async (
  workflowId: string,
  workflowOptions: Record<string, { value: number | string; file?: File }>,
  backendClient: Genaihubbackend,
) => {
  if (!workflowId) {
    throw new Error('Workflow ID is required');
  }

  if (workflowId === 'IMAGE_EDITOR' || workflowId === 'PARALLAX_MOTION') {
    if (workflowOptions['mask_ref']) {
      workflowOptions['mask_ref'].value = await uploadImage({
        file: workflowOptions['mask_ref'].file as File,
        backendClient,
        contentCategory: 'MASK_IMAGE',
      });
    }
    workflowOptions['seed'] = {
      value: 47,
    };
    workflowOptions['image_count'] = {
      value: 4,
    };
  }
};
