import { Asset, AssetList } from '@amzn/genaihub-typescript-client';
import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Metrics } from 'src/constants';
import Arrow from 'src/images/icons/arrow.svg';
import AssetContentCard from './AssetContentCard';
import AssetDetails from './AssetDetails';
import { AppContext } from 'src/../AppContext';
import './AssetGallery.scss';

export const TEST_ID_ASSET_GALLERY = 'asset-gallery';
export const TEST_ID_ASSET_GALLERY_LEFT_ARROW = 'asset-gallery-left-arrow';
export const TEST_ID_ASSET_GALLERY_RIGHT_ARROW = 'asset-gallery-right-arrow';

export interface AssetGalleryProps {
  assets: AssetList;
  columns: number;
  fillRows?: boolean;
  loading?: boolean;
  masonry?: boolean;
  spacing?: number;
  detailsClickHandler?: (asset: Asset) => void;
}

export default function AssetGallery(props: AssetGalleryProps) {
  const { assets, columns, masonry, spacing, detailsClickHandler } = props;
  const { metrics } = useContext(AppContext);
  const [currentAsset, setCurrentAsset] = useState<Asset>();
  const [showDetails, setShowDetails] = useState<boolean>(false);

  // open the asset details modal
  const assetClick = (asset: Asset, index: number) => {
    metrics.trackMetrics(
      Metrics.Methods.InperationPreview,
      { [Metrics.Names.Action]: Metrics.Actions.Open, index: index },
      { [Metrics.Counters.Clicks]: 1 },
    );
    setCurrentAsset(asset);
    setShowDetails(true);
  };

  // change the current asset details being shown
  const navigationCallback = (direction: string) => {
    if (currentAsset) {
      let index = assets.indexOf(currentAsset);
      switch (direction) {
        case 'left':
          index = index > 0 ? index - 1 : assets.length - 1;
          break;
        case 'right':
          index = index < assets.length - 1 ? index + 1 : 0;
          break;
      }
      setCurrentAsset(assets[index]);
    }
  };

  // check for left an right arrow press to change asset details
  const navigationKeyListener = (event: KeyboardEvent) => {
    if (navigationCallback) {
      switch (event.key) {
        case 'ArrowLeft':
          navigationCallback('left');
          break;
        case 'ArrowRight':
          navigationCallback('right');
          break;
      }
    }
  };

  // set key listeners so that arrow keys work
  useEffect(() => {
    window.addEventListener('keydown', navigationKeyListener);
    return () => {
      window.removeEventListener('keydown', navigationKeyListener);
    };
  }, [navigationKeyListener]);

  return (
    <div id="Asset-gallery" className={`${masonry ? ' masonry' : ''}`} data-testid={TEST_ID_ASSET_GALLERY}>
      <Grid id="gallery" container columns={12} spacing={spacing}>
        {assets.map((asset, index) => (
          <Grid item key={asset.uri} className="item" xs={12 / columns}>
            <AssetContentCard
              asset={asset}
              fixedSize
              showOverlay
              autoPlayTime={3000}
              clickHandler={() => {
                assetClick(asset, index);
              }}
            />
          </Grid>
        ))}
      </Grid>
      {showDetails && currentAsset && (
        <>
          <AssetDetails asset={currentAsset} showDetails={showDetails} setShowDetails={setShowDetails} clickHandler={detailsClickHandler} />
          {assets.length > 1 && (
            <>
              <div data-testid={TEST_ID_ASSET_GALLERY_LEFT_ARROW} className={`navigation left`} onClick={() => navigationCallback('left')}>
                <Arrow />
              </div>
              <div data-testid={TEST_ID_ASSET_GALLERY_RIGHT_ARROW} className={`navigation right`} onClick={() => navigationCallback('right')}>
                <Arrow />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
