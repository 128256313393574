import { Button, Icon } from '@amzn/storm-ui';
import { download } from '@amzn/storm-ui-icons';
import { useContext } from 'react';
import { downloadFromUrl } from 'src/components/utils/LinkUtils';
import { Metrics } from 'src/constants';
import styled from 'styled-components';
import { AppContext } from 'src/../AppContext';

export const PrimaryButton = styled(Button)`
  && {
    border: none;
    background-color: var(--text-color-variant-6);
    color: var(--standard-button-color-dark);
  }

  &&:hover {
    background-color: var(--AI-purple, #4305f4);
    color: white;
  }

  &&:disabled {
    background-color: var(--disabled-button-color);
  }
`;

export const DOWNLOAD_IMAGE_BUTTON_TEST_ID = 'download-image-button';
export interface DownloadImageButtonProps {
  url: string;
  disabled?: boolean;
}

export const DownloadImageButton = ({ url, disabled }: DownloadImageButtonProps) => {
  const appContext = useContext(AppContext);
  const clickHandler = () => {
    if (!disabled) {
      downloadFromUrl({ url, fileName: `ailab-${crypto.randomUUID()}` });
      appContext.metrics.trackMetrics(Metrics.Methods.ButtonClick, {}, { [Metrics.Counters.Download]: 1 });
    }
  };

  return (
    <>
      <PrimaryButton disabled={disabled} onClick={clickHandler} data-testid={DOWNLOAD_IMAGE_BUTTON_TEST_ID}>
        <Icon type={download} /> Download
      </PrimaryButton>
    </>
  );
};
