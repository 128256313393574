import { useEffect } from 'react';
import { Metrics } from 'src/constants';
import { logger } from 'src/logger';
import { MetricsProvider } from 'src/metrics';

export const useErrorHandler = (metrics: MetricsProvider) => {
  useEffect(() => {
    window.addEventListener('error', windowErrorHandler);
    window.addEventListener('unhandledrejection', globalErrorHandler);

    return () => {
      window.removeEventListener('error', windowErrorHandler);
      window.removeEventListener('unhandledrejection', globalErrorHandler);
    };
  }, []);

  const windowErrorHandler = function (e: ErrorEvent) {
    metrics.trackMetrics(Metrics.Methods.Fatal, {}, { [Metrics.Counters.UiError]: 1 });
    logger.fatal('unexpected UI error', errorEventToError(e), {});
  };
  const globalErrorHandler = function (e: PromiseRejectionEvent) {
    metrics.trackMetrics(Metrics.Methods.Fatal, {}, { [Metrics.Counters.ApiError]: 1 });
    logger.fatal('unexpected API error occurred', e?.reason, {});
    /*
    navigate('/error', {
      state: {
        name: 'Go back',
        route: pathname,
        message: 'We’re sorry, this request failed, please try again',
      },
    });
     */
  };
};

function errorEventToError(errorEvent: ErrorEvent) {
  const error = new Error(errorEvent?.message);
  error.name = errorEvent?.error?.name || 'Error';
  error.stack = errorEvent?.error?.stack;
  return error;
}
