import { RetrieveAssetsOutput } from '@amzn/genaihub-typescript-client';
import React, { ReactNode, memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ImageModal, ImageModalContext, useImageModal } from 'src/components/imageModal';
import ContentTile from 'src/components/pages/studio/contentTile/ContentTile';
import { convertFeed, fetchAsins } from 'src/components/pages/studio/contentTile/convertFeed';
import { ContentItem, JobStatusEnum, StudioContext } from 'src/components/pages/studio/StudioContext';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import usePreview from 'src/hooks/usePreview';
import { AICS_WEBLAB_FEEDS_DEACTIVATION, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { useAppDispatch, useAppSelector } from 'src/v2/redux/hooks';
import { getProducts } from 'src/v2/redux/slices/product/productSlice';
import style from './ContentTileContainer.module.scss';
import { AppContext } from '../../../../../AppContext';

const INITIAL_PAGE_SIZE = 12;
const SUBSEQUENT_PAGE_SIZE = 12;

const ContentTilesContainer = () => {
  const studioContext = useContext(StudioContext);
  const appContext = useContext(AppContext);
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const genAIBackendClient = useAIBackendHubClient();
  const products = useAppSelector(getProducts);

  // Asset Feed States
  const [assetsNextToken, setAssetsNextToken] = useState<string | undefined>('');
  const [callFeeds, setCallFeeds] = useState<boolean>(false);
  const [emptyPlaceholders, setEmptyPlaceholders] = useState<ContentItem[]>([]);
  const [isFeedsCallRunning, setIsFeedsCallRunning] = useState(false);
  const [secondFeedsCall, setSecondFeedsCall] = useState(false);
  const [isManagerAccount, setIsManagerAccount] = useState(false);

  // Preview Modal States
  const [currentResultInPreview, setCurrentResultInPreview] = useState<ContentItem>();
  const [indexOfCurrentResultInPreview, setIndexOfCurrentResultInPreview] = useState<number>(-1);
  const { isOpen, closeModal, openModal } = useImageModal();
  const [currentTab, setCurrentTab] = useState<number>(0);

  // Asset Feed Effects
  const generateEmptyPlaceholders = (aspectRatio: string | undefined, num: number) => {
    return Array<ContentItem>(num).fill({ loading: false, aspectRatio, isEmptyPlaceholder: true });
  };

  const getAssets = async (nextToken?: string) => {
    if (isWeblabInTreatment(AICS_WEBLAB_FEEDS_DEACTIVATION, WeblabTreatment.T1) || isManagerAccount) {
      return setCallFeeds(false);
    }

    if (isFeedsCallRunning || nextToken === undefined) return;

    let response: RetrieveAssetsOutput;
    setIsFeedsCallRunning(true);

    try {
      if (nextToken != '') {
        setEmptyPlaceholders(generateEmptyPlaceholders(studioContext.format, SUBSEQUENT_PAGE_SIZE));
        response = await genAIBackendClient.retrieveAssets({
          pageSize: SUBSEQUENT_PAGE_SIZE,
          cursor: nextToken,
          entityId: appContext.selectedAdvertisingAccount?.alternateIds?.[0],
        });
        setSecondFeedsCall(false);
      } else {
        setEmptyPlaceholders(generateEmptyPlaceholders(studioContext.format, INITIAL_PAGE_SIZE));
        response = await genAIBackendClient.retrieveAssets({
          pageSize: INITIAL_PAGE_SIZE,
          entityId: appContext.selectedAdvertisingAccount?.alternateIds?.[0],
        });
        setSecondFeedsCall(true);
      }

      // filter out unexpected nulls in response
      const results = response.body.assets?.filter((item) => !!item) || [];
      const asinMap = await fetchAsins(genAIBackendClient, dispatch, products, results);
      const convertedContents = await convertFeed(genAIBackendClient, results, asinMap);

      setAssetsNextToken(response.body.nextToken);
      studioContext.appendResults(convertedContents);
    } catch (error) {
      console.error('Feeds: Error while fetching assets for feed', error);
    } finally {
      setIsFeedsCallRunning(false);
      // Scroll up by 1 px to prevent an edge case where user reach the end of scollable area
      // while there are more content to load
      const container = containerRef.current;
      if (container && Math.ceil(container.scrollTop + container.clientHeight) >= container.scrollHeight) {
        container.scrollTop -= 1;
      }
    }
  };

  const getNextAsset = async () => {
    if (!isFeedsCallRunning && secondFeedsCall && assetsNextToken) {
      getAssets(assetsNextToken);
    }
  };

  useEffect(() => {
    // update the aspect ratio of the tile whenever user change the selection
    setEmptyPlaceholders(generateEmptyPlaceholders(studioContext.format, SUBSEQUENT_PAGE_SIZE));
  }, [studioContext.format]);

  // call feeds only if account is non-manager type and/or there's already a local account saved
  useEffect(() => {
    if (appContext.accountType === 'external' && !appContext.selectedAdvertisingAccount) {
      setIsManagerAccount(true);
      return;
    }
    setIsManagerAccount(false);
    setCallFeeds(true);
  }, [appContext.accountType, appContext.selectedAdvertisingAccount]);

  useEffect(() => {
    getNextAsset();
  }, [isFeedsCallRunning]);

  useEffect(() => {
    if (studioContext.jobStatus?.status == JobStatusEnum.RUNNING || studioContext.jobStatus?.status == JobStatusEnum.READY) {
      containerRef.current?.scrollTo(0, 0);
    } else if (studioContext.jobStatus?.status == JobStatusEnum.COMPLETED || studioContext.jobStatus?.status == JobStatusEnum.FAILED) {
      setCallFeeds(true);
    }
  }, [studioContext.jobStatus, isManagerAccount]);

  useEffect(() => {
    if (callFeeds) getAssets('');
  }, [callFeeds]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { currentTarget } = event;
    const distance = (currentTarget.scrollTop + currentTarget.clientHeight) / currentTarget.scrollHeight;
    const limit = 1 - SUBSEQUENT_PAGE_SIZE / memoizedResults.length;
    if (distance > limit) {
      getAssets(assetsNextToken);
    }
  };

  // Previw Modal Effects
  const setContentResultInPreview = (index: number, openPreviewModal?: true) => {
    setIndexOfCurrentResultInPreview(index);

    if (studioContext.results && index >= 0 && index < studioContext.results.length) {
      setCurrentResultInPreview(studioContext.results[index]);
    } else {
      setCurrentResultInPreview(undefined);
      closeModal();
    }

    if (openPreviewModal) {
      openModal();
    }
  };

  const handleOnCloseModal = () => {
    setIndexOfCurrentResultInPreview(-1);
  };

  const { previewContext } = usePreview({
    contentItem: currentResultInPreview,
    handleReuseSettings: (props) => {
      studioContext.handleReuseSettings?.(props);
      closeModal();
    },
    handleSwitchToPrevContentItem: () => {
      const prevIndex = indexOfCurrentResultInPreview;
      const index = prevIndex > 0 ? prevIndex - 1 : (studioContext.results?.length ?? 0) - 1;
      setContentResultInPreview(index, true);
    },
    handleSwitchToNextContentItem: () => {
      const prevIndex = indexOfCurrentResultInPreview;
      const index = prevIndex < studioContext.results.length - 1 ? prevIndex + 1 : studioContext.results?.length ? 0 : -1;
      setContentResultInPreview(index, true);
    },
  });

  // Asset Processing
  const getContentTiles = (): ReactNode[] => {
    return studioContext.results.map((item: ContentItem, index: number) => {
      return (
        <ContentTile
          key={`ContentTile_${item.referenceId}_${index}`}
          index={index}
          contentItem={item}
          onClick={(currentTab: number) => {
            setContentResultInPreview(index, true);
            setCurrentTab(currentTab);
          }}
        />
      );
    });
  };

  const memoizedResults = useMemo(() => getContentTiles(), [studioContext.results]);
  const bottomElement = useMemo(() => <ContentTile contentItem={{ loading: false, aspectRatio: '1:1.2' }} />, []);

  return (
    <>
      <div onScroll={handleScroll} className={style.container} ref={containerRef} data-testid="studio-content-tile-container">
        {studioContext.placeholders?.map((item, index) => <ContentTile key={`Placeholder_${index}`} contentItem={item} />)}
        {memoizedResults}
        {isFeedsCallRunning && emptyPlaceholders.map((item, index) => <ContentTile key={`FeedPlaceHolders_${index}`} contentItem={item} />)}
        {/* Clear the bottom so that last tile renders correctly */}
        {bottomElement}
      </div>
      <ImageModalContext.Provider value={previewContext}>
        <ImageModal
          currentItem={currentResultInPreview}
          isOpen={isOpen}
          currentTab={currentTab}
          closeModal={closeModal}
          onCloseModal={handleOnCloseModal}
        />
      </ImageModalContext.Provider>
    </>
  );
};

export default memo(ContentTilesContainer);
