import { Heading, Icon } from '@amzn/storm-ui';
import { opportunity } from '@amzn/storm-ui-icons';
import _cloneDeep from 'lodash/cloneDeep';
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { Feedback, FeedbackContext, SubmittedFeedback } from 'src/components/feedback/FeedbackContext';
import { FeedbackThumbsControl } from 'src/components/feedback/FeedbackThumbsControl';
import { getImageFromUrl, LoadedImage } from 'src/components/utils/ImageUtils';
import styles from './ImageWithThumbnails.module.scss';
import { LoadingAnimation } from './LoadingAnimation';
import { AssetType, WorkflowId } from '../imageModal/components/utils';

export enum ImageStatus {
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR',
}

export enum ImageGroup {
  GENERATED = 'GENERATED',
  ORIGINAL = 'ORIGINAL',
}

export interface Image {
  originalUrl: string;
  referenceId: string;
  url: string;
}

export interface ImageWithThumbnailsRef {
  setGeneratedImages: (images: Image[]) => void;
  setOriginalImage: (image: Image) => void;
}

export interface ImageWithThumbnailsProps {
  assetType: AssetType;
  workflowId: WorkflowId;
  onChangeIsLoading?: (isLoading: boolean) => void;
  onChangeSelectedImage?: (selectedImage: ImageState | undefined) => void;
  onSubmittedFeedback?: (submittedFeedback: SubmittedFeedback) => void;
}

export interface ImageState {
  status: ImageStatus;
  image: Image;
  group: ImageGroup;
  feedback?: Feedback;
  loadedImage?: LoadedImage;
}

const SPACE_ASPECT_RATIO = 100 / 70;

export const ImageWithThumbnails = forwardRef<ImageWithThumbnailsRef, ImageWithThumbnailsProps>((props, ref) => {
  const [generatedImages, setGeneratedImages] = useState<Image[]>([]);
  const [originalImage, setOriginalImage] = useState<Image>();
  const [selectedImageState, setSelectedImageState] = useState<ImageState>();
  const [loadingImageWidth, setLoadingImageWidth] = useState<string>('');
  const [loadingImageHeight, setLoadingImageHeight] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [imageStates, setImageStates] = useState<ImageState[]>([]);
  const [originalImageStates, setOriginalImageStates] = useState<ImageState[]>([]);
  const [generatedImageStates, setGeneratedImageStates] = useState<ImageState[]>([]);

  const { setOnSubmittedFeedback, isFeedbackPopoverOpen, setFeedbackMetadata, setFeedbackComment, setFeedbackSentiment, closeAndSubmitFeedback } =
    useContext(FeedbackContext);

  useEffect(() => {
    setOnSubmittedFeedback(() => (submittedFeedback: SubmittedFeedback) => {
      // Assign submitted feedback to the image
      if (submittedFeedback) {
        setImageStates((value: ImageState[]) =>
          value.map((imageState) =>
            imageState.image.referenceId === submittedFeedback.feedbackMetadata?.referenceId
              ? {
                  ...imageState,
                  feedback: _cloneDeep(submittedFeedback.feedback),
                }
              : imageState,
          ),
        );
        props.onSubmittedFeedback?.(submittedFeedback);
      }
    });
  }, []);

  useImperativeHandle(ref, () => ({
    setGeneratedImages,
    setOriginalImage,
  }));

  useEffect(() => {
    props.onChangeIsLoading?.(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (!generatedImages?.length) {
      setIsLoading(false);
      setImageStates([]);
      return;
    }

    let apply = true;
    let aspectRatioSet = false;

    const newImageStates: ImageState[] = [];

    if (originalImage !== undefined) {
      newImageStates.push({ image: originalImage, status: ImageStatus.LOADING, group: ImageGroup.ORIGINAL });
    }

    newImageStates.push(
      ...(generatedImages || []).map((image: Image) => ({
        image: { ...image },
        status: ImageStatus.LOADING,
        group: ImageGroup.GENERATED,
      })),
    );

    const updateImageState = (updatedImageState: ImageState) => {
      if (!apply) return;
      setImageStates((value) =>
        value.map((imageState) => (imageState.image.referenceId === updatedImageState.image.referenceId ? updatedImageState : imageState)),
      );
    };

    const updateAspectRatio = ({ width, height }: { width: number; height: number }) => {
      if (!apply) return;
      if (!aspectRatioSet) {
        aspectRatioSet = true;
        const newAspectRatio = width / height;
        let newLoadingImageWidth, newLoadingImageHeight;
        if (newAspectRatio < SPACE_ASPECT_RATIO) {
          newLoadingImageWidth = '100%';
          newLoadingImageHeight = `${100 * (1 / newAspectRatio)}%`;
        } else {
          newLoadingImageWidth = `${100 * newAspectRatio}%`;
          newLoadingImageHeight = '100%';
        }
        setLoadingImageWidth(newLoadingImageWidth);
        setLoadingImageHeight(newLoadingImageHeight);
      }
    };

    const fetchImages = async () => {
      await Promise.all(newImageStates.map((imageState) => fetchImage(imageState)));
      if (apply) {
        setIsLoading(false);
      }
    };

    const fetchImage = async (imageState: ImageState) => {
      try {
        if (props.assetType === AssetType.VIDEO) {
          updateImageState({
            ...imageState,
            status: ImageStatus.READY,
          });
        } else {
          // preload for image only; video assets natively preload on the <video /> element
          const loadedImage = await getImageFromUrl({
            url: imageState.image.url,
            onFetchDimensions: updateAspectRatio,
          });
          updateAspectRatio({
            width: loadedImage.width,
            height: loadedImage.height,
          });
          updateImageState({
            ...imageState,
            loadedImage,
            status: ImageStatus.READY,
          });
        }
      } catch (error) {
        console.error('Failed to load image', error);
        updateImageState({
          ...imageState,
          status: ImageStatus.ERROR,
        });
      }
    };

    setImageStates(newImageStates);
    setSelectedImageState(originalImage ? newImageStates[1] : newImageStates[0]);
    setIsLoading(true);
    fetchImages();

    // cancel application of this useEffect instance's fetchImage if useEffect cleaned up.
    return () => {
      apply = false;
    };
  }, [generatedImages]);

  useEffect(() => {
    if (isFeedbackPopoverOpen) {
      closeAndSubmitFeedback();
    }

    if (selectedImageState) {
      setFeedbackComment(selectedImageState.feedback?.comment);
      setFeedbackSentiment(selectedImageState.feedback?.sentiment);
      setFeedbackMetadata({
        assetUrl: selectedImageState.image.originalUrl,
        prompt: undefined,
        referenceId: selectedImageState.image.referenceId,
        workflowId: props.workflowId,
        assetType: props.assetType,
      });
      props.onChangeSelectedImage?.(selectedImageState);
    } else {
      setFeedbackComment(undefined);
      setFeedbackSentiment(undefined);
      setFeedbackMetadata(undefined);
      props.onChangeSelectedImage?.(undefined);
    }
  }, [selectedImageState]);

  useEffect(() => {
    setOriginalImageStates(imageStates.filter((imageState) => imageState.group === ImageGroup.ORIGINAL));
    setGeneratedImageStates(imageStates.filter((imageState) => imageState.group === ImageGroup.GENERATED));
  }, [imageStates]);

  const onClickThumbnail = (imageState: ImageState) => {
    if (!isLoading) {
      setSelectedImageState(imageState);
    }
  };

  return (
    <div className={`${styles.imageWithThumbnails}`}>
      <div className={`${styles.selectedImage}`}>
        <div className={`${styles.imgWrapper} ${!isLoading ? styles.show : ''} ${isFeedbackPopoverOpen ? styles.feedbackPopoverOpen : ''}`}>
          {selectedImageState && (
            <>
              {props.assetType === AssetType.VIDEO ? (
                <video
                  preload="auto"
                  controls={true}
                  autoPlay
                  playsInline
                  muted
                  loop
                  src={selectedImageState.image.url}
                  data-testid="image-thumbnails-selected-video"
                />
              ) : (
                <img src={selectedImageState.image.url} data-testid="image-thumbnails-selected-image" />
              )}
            </>
          )}
          {props.assetType !== AssetType.VIDEO && selectedImageState?.group !== ImageGroup.ORIGINAL && (
            <div className={styles.overlay}>
              <FeedbackThumbsControl />
            </div>
          )}
        </div>
        <LoadingAnimation
          className={`${styles.loadingAnimation} ${isLoading ? styles.show : ''}`}
          primaryMessage="Loading"
          style={{
            width: loadingImageWidth || '100%',
            height: loadingImageHeight || '100%',
          }}
        />
      </div>
      {props.assetType !== AssetType.VIDEO && (
        <div className={`${styles.thumbnailContainer}`}>
          <div className={`${styles.thumbnailGroups}`}>
            {originalImageStates.length >= 1 && (
              <div className={`${styles.thumbnailGroup}`}>
                <div className={styles.headerDetails}>
                  <Heading className={styles.headerText}>Original</Heading>
                </div>
                <div
                  className={`${styles.thumbnails}`}
                  style={{
                    minHeight: '60px',
                  }}
                >
                  {originalImageStates.map((imageState) => (
                    <div
                      key={imageState.image.referenceId}
                      className={`${styles.thumbnail} ${selectedImageState?.image.referenceId === imageState.image.referenceId ? styles.selected : ''}`}
                      onClick={() => onClickThumbnail(imageState)}
                    >
                      <div className={`${styles.imgWrapper} ${!isLoading ? styles.show : ''}`}>
                        {imageState.status === ImageStatus.READY ? (
                          <img src={imageState.image.url} data-testid={'original-image-thumbnail'} />
                        ) : (
                          <></>
                        )}
                      </div>
                      <LoadingAnimation className={`${styles.loadingAnimation} ${isLoading ? styles.show : ''}`} />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {generatedImageStates.length >= 1 && (
              <div className={`${styles.thumbnailGroup}`}>
                <div className={styles.headerDetails}>
                  <Icon type={opportunity} className={styles.headerIcon} />
                  <Heading className={styles.headerText}>Generated</Heading>
                </div>
                <div
                  className={`${styles.thumbnails}`}
                  style={{
                    minHeight: '60px',
                  }}
                >
                  {generatedImageStates.map((imageState) => (
                    <div
                      key={imageState.image.referenceId}
                      className={`${styles.thumbnail} ${selectedImageState?.image.referenceId === imageState.image.referenceId ? styles.selected : ''}`}
                      onClick={() => onClickThumbnail(imageState)}
                    >
                      <div className={`${styles.imgWrapper} ${!isLoading ? styles.show : ''}`}>
                        {imageState.status === ImageStatus.READY ? <img src={imageState.image.url} data-testid={'image-results-thumbnail'} /> : <></>}
                      </div>
                      <LoadingAnimation className={`${styles.loadingAnimation} ${isLoading ? styles.show : ''}`} />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
});

ImageWithThumbnails.displayName = 'ImageWithThumbnails';
