import { Button, Icon, Snackbar } from '@amzn/storm-ui';
import { close } from '@amzn/storm-ui-icons';
import { animated, useTransition } from '@react-spring/web';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import styles from 'src/components/snackbar/SnackbarManager.module.scss';
import { SnackbarStateContext, SnackbarDispatchContext } from 'src/v2/contexts/snackbar/SnackbarContext';
import { SnackbarActionType, SnackbarNotification } from 'src/v2/contexts/snackbar/types';
import styled from 'styled-components';

// TODO: fix things like this to use proper theme colors later when storm-ui version 4 "tokens" system is set up
const Badge = styled('div')`
  color: #ffffff;
  background: #d82624;
`;

export const SnackbarManagerTestIds = {
  ROOT: 'snackbar-manager-root',
  NOTIFICATION_ROOT_PREFIX: 'notification-root-',
  NOTIFICATION_SNACKBAR_PREFIX: 'notification-snackbar-',
  NOTIFICATION_CLOSE_BUTTON_PREFIX: 'notification-close-button-',
  NOTIFICATION_BADGE_PREFIX: 'notification-badge-',
};

export const generateTestIdForNotificationRoot = (id: string) => `${SnackbarManagerTestIds.NOTIFICATION_ROOT_PREFIX}${id}`;
export const generateTestIdForNotificationSnackbar = (id: string) => `${SnackbarManagerTestIds.NOTIFICATION_SNACKBAR_PREFIX}${id}`;
export const generateTestIdForNotificationCloseButton = (id: string) => `${SnackbarManagerTestIds.NOTIFICATION_CLOSE_BUTTON_PREFIX}${id}`;
export const generateTestIdForNotificationBadge = (id: string) => `${SnackbarManagerTestIds.NOTIFICATION_BADGE_PREFIX}${id}`;

export const SnackbarManager: FC = () => {
  const snackbarState = useContext(SnackbarStateContext);
  const snackbarDispatch = useContext(SnackbarDispatchContext);
  const notifications = snackbarState.notifications;

  const refMap = useMemo(() => new WeakMap(), []);
  const [items, setItems] = useState<SnackbarNotification[]>([]);

  const itemsTransition = useTransition(items, {
    from: { opacity: 0, maxHeight: 0, y: -40 },
    keys: (item) => item.id,
    enter: (item: SnackbarNotification) => async (next) => {
      await next({ opacity: 1, maxHeight: refMap.get(item).offsetHeight, y: 0 });
    },
    leave: { opacity: 0, maxHeight: 0, y: -40 },
    config: () => {
      return () => {
        return {
          duration: 500,
        };
      };
    },
  });

  useEffect(() => {
    if (notifications.length) {
      if (snackbarState.expanded) {
        setItems(notifications);
      } else {
        setItems([notifications[notifications.length - 1]]);
      }
    } else {
      setItems([]);
    }
  }, [notifications, snackbarState.expanded]);

  const notificationBadgeLabel = notifications.length > 9 ? '9+' : `${notifications.length}`;

  const onClickCloseButton = (notification: SnackbarNotification) => {
    if (!snackbarState.expanded) {
      snackbarDispatch({
        type: SnackbarActionType.REMOVE_ALL_NOTIFICATIONS,
      });
    } else {
      snackbarDispatch({
        type: SnackbarActionType.REMOVE_NOTIFICATION,
        payload: notification.id,
      });
    }
  };

  const toggleNotifications = () => {
    snackbarDispatch({
      type: SnackbarActionType.SET_EXPANDED,
      payload: !snackbarState.expanded,
    });
  };

  return (
    <div data-testid={SnackbarManagerTestIds.ROOT} className={`${styles.snackbarManager} ${snackbarState.expanded ? styles.expanded : ''}`}>
      <div className={styles.snackbarList}>
        {itemsTransition((style, item, t, index) => (
          <animated.div data-testid={generateTestIdForNotificationRoot(item.id)} className={styles.snackbarListItem} style={style}>
            <div className={styles.snackbarListItemContent} ref={(ref: HTMLDivElement) => ref && refMap.set(item, ref)}>
              <Snackbar
                data-testid={generateTestIdForNotificationSnackbar(item.id)}
                id={`snackbar-manager-notification-${item.id}`}
                className={styles.snackbar}
                variant={item.type}
                {...(index + 1 === items.length ? { onClick: toggleNotifications } : {})}
              >
                <item.SnackbarContent />
              </Snackbar>
              <div className={styles.spacer}></div>
              <Button
                data-testid={generateTestIdForNotificationCloseButton(item.id)}
                className={styles.closeButton}
                small={true}
                variant="primary"
                type="button"
                onClick={() => onClickCloseButton(item)}
              >
                <Icon type={close} />
              </Button>
              {
                <Badge
                  data-testid={generateTestIdForNotificationBadge(item.id)}
                  className={`
                    ${styles.badge}
                    ${notifications.length > 1 ? styles.show : ''}
                  `}
                >
                  {notificationBadgeLabel}
                </Badge>
              }
            </div>
          </animated.div>
        ))}
      </div>
    </div>
  );
};
