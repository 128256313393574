import { Draft } from 'immer';
import React, { Dispatch } from 'react';
import { SnackbarState, SnackbarAction, SnackbarActionType, TimeoutRef, ActionHandlerReturn } from 'src/v2/contexts/snackbar/types';

interface HandleActionAddNotificationProps {
  action: Extract<SnackbarAction, { type: SnackbarActionType.ADD_NOTIFICATION }>;
  state: Draft<SnackbarState>;
  timeoutsRef: TimeoutRef;
  dismissalTimeout: number;
  dispatch: Dispatch<SnackbarAction>;
  expandedRef: React.MutableRefObject<boolean>;
}

export const handleActionAddNotification = ({
  action,
  state,
  timeoutsRef,
  dismissalTimeout,
  dispatch,
  expandedRef,
}: HandleActionAddNotificationProps): ActionHandlerReturn => {
  const notification = action.payload;

  if (state.notifications.findIndex((n) => n.id === notification.id) >= 0) {
    console.error(`A notification already exists with id '${notification.id}'`);
    return;
  }

  state.notifications.push(notification);

  if (!notification.disableAutoDismissal) {
    timeoutsRef[notification.id] = {
      readyToClear: false,
      id: setTimeout(() => {
        if (expandedRef.current) {
          timeoutsRef[notification.id].readyToClear = true;
        } else {
          dispatch({
            type: SnackbarActionType.REMOVE_NOTIFICATION,
            payload: notification.id,
          });
        }
      }, dismissalTimeout),
    };
  }
};
