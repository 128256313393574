import { Button as StormUiButton } from '@amzn/storm-ui';
import styled from 'styled-components';
import { ButtonStyleClasses, ButtonProps, ButtonSizes } from './types';

export const ButtonStylesWrapper = styled(StormUiButton)<Partial<ButtonProps>>`
  display: flex;
  width: min-content;
  justify-content: center;
  align-items: center;
  border-radius: ${({ borderRadius }) => borderRadius};
  box-sizing: border-box;
  cursor: pointer;
  border: 1.5px solid var(--button-secondary-border, #2d3239);
  background-color: transparent;
  color: var(--text-ui-primary);
  font-family: 'Amazon Ember', Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.2px */
  transition: all 150ms ease-in-out;
  user-select: none;

  // overrides embedded storm-ui button inner DIV affecting layout alignment
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  /************************************
  Button sizing styles
  *************************************/
  ${`&&.${ButtonStyleClasses.fullWidth}`} {
    width: 100%;
  }

  ${`&&.${ButtonStyleClasses.small}`} {
    padding: 0px 8px;
    min-height: 24px;
    font-size: 12px;
  }

  ${`&&.${ButtonStyleClasses.medium}`} {
    padding: 2px 12px;
    min-height: 32px;
    font-size: 14px;
  }

  ${`&&.${ButtonStyleClasses.large}`} {
    padding: 8px 12px;
    min-height: 42px;
    font-size: 16px;
  }

  /************************************
  Primary button type styles
  *************************************/

  ${`&.${ButtonStyleClasses.primary}`} {
    color: ${({ darkMode }) => (darkMode ? 'var(--button-default-dark-text, #161d26)' : 'var(--button-default-text, #ffffff)')};
    background-color: ${({ darkMode }) =>
      darkMode ? 'var(--button-default-dark-background, #ffffff)' : 'var(--button-default-background, #161d26)'};
    text-decoration: none;

    &:hover:not(.${ButtonStyleClasses.disabled}) {
      color: ${({ darkMode }) => (darkMode ? 'var(--button-default-dark-hover-text, #161d26)' : 'var(--button-default-hover-text, #ffffff)')};
      background-color: ${({ darkMode }) =>
        darkMode ? 'var(--button-default-dark-hover-background, #f5f3ef)' : 'var(--button-default-hover-background, #161d26)'};
      box-shadow: ${({ darkMode }) => (darkMode ? 'none' : '0px 11px 14px -10px rgba(22, 29, 38, 0.50)')};
    }

    &:focus:not(.${ButtonStyleClasses.disabled}) {
      color: ${({ darkMode }) => (darkMode ? 'var(--button-default-dark-hover-text, #161d26)' : 'var(--button-default-hover-text, #ffffff)')};
    }

    &:active:not(.${ButtonStyleClasses.disabled}) {
      background-color: ${({ darkMode }) =>
        darkMode ? 'var(--button-default-dark-active-background, #f5f3ef)' : 'var(--button-default-active-background, #000000)'};
      box-shadow: none;
    }

    ${`&&.${ButtonStyleClasses.disabled}`} {
      color: var(--button-default-dark-unavailable-text, #2d3239);
      background: var(--button-default-dark-unavailable-background, #aaabac);

      & path {
        fill: var(--button-default-unavailable-text, #2d3239);
      }
    }

    ${`&&.${ButtonStyleClasses.loading}`} {
      color: var(--button-default-unavailable-text, #2d3239);
      background-color: var(--button-default-unavailable-background, #aaabac);
      border: none;

      & path {
        fill: var(--button-default-unavailable-text, #2d3239);
      }

      span {
        color: var(--button-default-unavailable-text, #2d3239);
        background-color: var(--button-default-unavailable-background, #aaabac);
      }
    }
  }

  /************************************
  Secondary button type styles
  *************************************/
  ${`&.${ButtonStyleClasses.secondary}`} {
    color: ${({ darkMode }) => (darkMode ? 'var(--button-secondary-dark-text, #f5f3ef)' : 'var(--button-secondary-text, #2d3239)')};
    background-color: transparent;
    text-decoration: none;
    border-color: ${({ darkMode }) => (darkMode ? 'var(--button-secondary-dark-border, #f5f3ef)' : 'var(--button-secondary-border, #2d3239)')};

    &:hover:not(.${ButtonStyleClasses.disabled}) {
      background-color: ${({ darkMode }) =>
        darkMode ? 'var(--button-secondary-dark-hover-background, #161d26)' : 'var(--button-secondary-hover-background, #f5f3ef)'};
      color: ${({ darkMode }) => (darkMode ? 'var(--button-secondary-dark-hover-text, #f5f3ef)' : 'var(--button-secondary-hover-text, #2d3239)')};
      box-shadow: ${({ size }) => (size === ButtonSizes.Small ? 'none' : '0px 0px 16px 0px rgba(22, 29, 38, 0.12)')};
    }

    &:focus:not(.${ButtonStyleClasses.disabled}) {
      color: ${({ darkMode }) => (darkMode ? 'var(--button-secondary-dark-hover-text, #f5f3ef)' : 'var(--button-secondary-hover-text, #2d3239)')};
    }

    &:active:not(.${ButtonStyleClasses.disabled}) {
      background: ${({ darkMode }) =>
        darkMode ? 'var(--button-secondary-dark-active-background, #2d3239)' : 'var(--button-secondary-active-background, #f9f8f6)'};
      box-shadow: none;
    }

    ${`&.${ButtonStyleClasses.disabled}`} {
      background: transparent;
      border: 1.5px solid var(--button-secondary-unavailable-border, #aaabac);

      & path {
        color: var(--button-secondary-unavailable-text, #676b6f);
      }
    }

    ${`&&.${ButtonStyleClasses.loading}`} {
      color: var(--button-secondary-unavailable-text, #676b6f);
      border-color: var(--button-secondary-unavailable-border, #aaabac);
      background: transparent;
    }
  }

  /************************************
  Tertiary button type styles
  *************************************/
  ${`&.${ButtonStyleClasses.tertiary}`} {
    color: ${({ darkMode }) => (darkMode ? 'var(--button-tertiary-dark-text, #b38aff)' : 'var(--button-tertiary-text, #4305f4)')};
    background-color: none;
    text-decoration: none;
    border: none;

    button {
      font-size: ${({ size }) => (size === ButtonSizes.Small ? '12px' : 'inherit')};
    }

    & path {
      fill: var(--button-tertiary-text, #4305f4);
    }

    a:link,
    a:visited,
    a:hover,
    a:active {
      text-decoration: none;
    }

    &:hover:not(.${ButtonStyleClasses.disabled}) {
      color: ${({ darkMode }) => (darkMode ? 'var(--button-tertiary-dark-hover-text, #caadff)' : 'var(--button-tertiary-hover-text, #2e02ad)')};
      background: ${({ darkMode }) =>
        darkMode ? 'var(--button-tertiary-dark-hover-background, #454a4f)' : 'var(--button-tertiary-hover-background, #f5f3ef)'};
      text-decoration: none;
    }

    &:focus:not(.${ButtonStyleClasses.disabled}) {
      color: ${({ darkMode }) => (darkMode ? 'var(--button-tertiary-dark-hover-text, #caadff)' : 'var(--button-tertiary-hover-text, #2e02ad)')};
    }

    &&:active {
      background: ${({ darkMode }) =>
        darkMode ? 'var(--button-tertiary-dark-active-background, #2d3239)' : 'var(--button-tertiary-active-background, #f9f8f6)'};
      box-shadow: none;
    }

    ${`&.${ButtonStyleClasses.disabled}`} {
      background: none;
      cursor: not-allowed;
      color: ${({ darkMode }) =>
        darkMode ? 'var(--button-tertiary-dark-unavailable-text, #b7b8b8)' : 'var(--button-tertiary-unavailable-text, #676b6f)'};
      & path {
        fill: ${({ darkMode }) =>
          darkMode ? 'var(--button-tertiary-dark-unavailable-text, #b7b8b8)' : 'var(--button-tertiary-unavailable-text, #676b6f)'};
      }
    }

    ${`&&.${ButtonStyleClasses.loading}`} {
      background: none;
      cursor: not-allowed;
      color: var(--button-tertiary-unavailable-text, #676b6f);
      & path {
        fill: var(--button-tertiary-unavailable-text, #676b6f);
      }
    }
  }

  /************************************
  Other button style cases
  *************************************/
  ${`&.${ButtonStyleClasses.disabled}`} {
    border: none;
  }

  ${`&.${ButtonStyleClasses.iconDark}:not(.${ButtonStyleClasses.tertiary})`} {
    & path {
      fill: var(--standard-button-color-dark);
    }
  }

  ${`&.${ButtonStyleClasses.iconLight}`} {
    & path {
      fill: white;
    }
  }

  ${`&.${ButtonStyleClasses.iconAccent}`} {
    & path {
      fill: #b38aff;
    }
  }

  ${`&&.${ButtonStyleClasses.iconOnlyNoBackground}`} {
    && path {
      fill: ${({ darkMode }) => (darkMode ? 'var(--standard-button-color-light, #ffffff)' : 'var(--standard-button-color-dark, #161d26)')};
    }
    background-color: none;
    border: none;
    background: none;
    padding: 0;
    min-height: 0;
    box-shadow: none;
    &&:hover {
      background: none;
      box-shadow: none;
    }
  }

  ${`&&.${ButtonStyleClasses.iconOnlyRounded}`} {
    padding: 0;
    margin: 0;
    min-height: none;
    background: none;
    box-shadow: none;
    border: none;
    outline: none;

    &&:hover,
    :active,
    :focus {
      background: none;
      box-shadow: none;
    }

    & path {
      fill: ${({ iconOnlyColor }) => iconOnlyColor || 'var(--standard-button-color-dark, #161d26)'};
    }
  }
`;
