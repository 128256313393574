import { AssetUrls, BatchResult as BackendBatchResult, BatchResultJobsItem } from '@amzn/genaihub-typescript-client';
import { useMutation, useQuery, UseQueryResult, Query } from '@tanstack/react-query';
import { useState, useEffect, useContext, useRef } from 'react';
import { ImageCategory } from 'src/components/imageModal/components/utils';
import { AssetType, EditActions, WorkflowId } from 'src/components/imageModal/components/utils/types';
import { ImageModalContext } from 'src/components/imageModal/ImageModalContext';
import { getAssetTypeFromWorkflowId } from 'src/components/utils/assetUtils';
import { urlToFile } from 'src/components/utils/base64Encode';
import { uploadImage } from 'src/components/utils/uploadImage';
import { KeysOfPropertyWithType } from 'src/helpers';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { AppContext } from 'src/../AppContext';
import { Metrics } from 'src/constants';
import { CounterMetrics, StringMetrics } from 'src/metrics';
import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';
import { getWorkflowOptionsMetrics } from 'src/components/utils/getWorkflowOptionsMetrics';

export type useControlsOptions<T extends EditActions> = {
  workFlowId: T['workFlowId'];
  pollingInterval?: number;
  userAlias?: string;
  pageName?: string;
  timeout?: number;
  studioRequest?: boolean;
  defaultWorkflowOptions?: T['workflowOptions'];
  setPendingGeneration?: (pendingGeneration: boolean) => void;
};

interface Job extends BatchResultJobsItem {
  originalUrls?: AssetUrls;
}

export interface BatchResult extends BackendBatchResult {
  jobs: Job[];
}

export const useWorkflow = <T extends EditActions>(options: useControlsOptions<T>) => {
  type WorkflowOptionsType = T['workflowOptions'];
  type StringKeys = KeysOfPropertyWithType<WorkflowOptionsType, string>;
  type UploadFilesPayload = Partial<{
    [key in StringKeys]: {
      urlOrFile: string | File;
      contentCategory: ImageCategory;
      fileTypeOverride?: string;
    };
  }>;

  const {
    defaultWorkflowOptions,
    timeout = 120000,
    workFlowId: workflowId,
    pollingInterval = 2000,
    studioRequest = true,
    setPendingGeneration,
  } = options;
  const [submissionTime, setSubmissionTime] = useState<number>();
  const [workflowOptions, setWorkflowOptions] = useState<WorkflowOptionsType>(defaultWorkflowOptions!);
  const appContext = useContext(AppContext);
  const backendClient = useAIBackendHubClient();
  const { setActiveEditsWorkflowId, setActiveEditsAssetType } = useContext(ImageModalContext);
  useEffect(() => {
    setActiveEditsWorkflowId?.(workflowId);
    setActiveEditsAssetType?.(getAssetTypeFromWorkflowId(workflowId));
  }, [workflowId]);

  const publisher = useRef<KatalMetricsPublisher | null>(appContext.metrics?.trackMetrics(Metrics.Methods.WorkflowMetrics));
  const trackMetrics = (strings?: StringMetrics, counters?: CounterMetrics) => {
    if (appContext.metrics) {
      if (!publisher.current) {
        publisher.current = appContext.metrics.trackMetrics(Metrics.Methods.WorkflowMetrics, strings, counters);
      } else {
        appContext.metrics.trackMetricsWithPublisher(publisher.current, strings, counters);
      }
    }
  };

  const submitWorkflowQuery = useMutation({
    mutationFn: async (workflowOptions: WorkflowOptionsType) => {
      publisher.current = null;
      trackMetrics(
        {
          [Metrics.Names.WorkflowId]: workflowId ?? Metrics.Values.Unknown,
          ...getWorkflowOptionsMetrics(workflowOptions),
        },
        { [Metrics.Counters.Count]: 1 },
      );

      const response = await backendClient.submitWorkflowById({
        workflowId: workflowId,
        body: {
          workflowOptions: {
            ...workflowOptions,
          },
        },
      });
      const { batchId } = response.body;
      if (batchId) {
        trackMetrics({ [Metrics.Names.BatchId]: batchId });
        return batchId;
      } else {
        console.log('workflowmetrics No batchId found');
        trackMetrics({ [Metrics.Names.Error]: 'No batchId found' }, { [Metrics.Counters.Failure]: 1 });
        throw new Error('No batchId found');
      }
    },
    onSuccess: () => {
      setSubmissionTime(Date.now());
    },
  });
  const batchId = submitWorkflowQuery.data;

  const uploadFileQuery = useMutation({
    mutationFn: async (options: UploadFilesPayload) => {
      const keys = Object.keys(options) as StringKeys[];
      const results: Partial<Record<StringKeys, string>> = {};
      const promises = keys.map(async (key) => {
        const { urlOrFile, fileTypeOverride, contentCategory } = options[key]!;

        // this block is ONLY for images with a valid imageReferenceId and not images in the generated results
        if (
          typeof urlOrFile === 'string' &&
          !urlOrFile.startsWith('http') &&
          !urlOrFile.startsWith('blob') &&
          (workflowId === WorkflowId.IMAGE_THEMING || contentCategory === ImageCategory.REFERENCE_IMAGE)
        ) {
          const response = await backendClient.retrieveAsset({
            id: urlOrFile,
            entityId: appContext?.selectedAdvertisingAccount?.alternateIds?.[0],
          });
          const url = response?.body.asset?.uri || '';
          const fileTypeOverride = 'image/png';
          if (url) {
            results[key] = await uploadImage({
              file: await urlToFile(url, fileTypeOverride),
              backendClient,
              contentCategory,
            });
            return;
          } else {
            throw new Error('No url found for the asset');
          }
        }

        const file = typeof urlOrFile === 'string' ? await urlToFile(urlOrFile, fileTypeOverride) : urlOrFile;
        results[key] = await uploadImage({
          file,
          backendClient,
          contentCategory,
        });
      });
      await Promise.all(promises);
      return results as Partial<WorkflowOptionsType>;
    },
    onSuccess: (ref) => {
      const payload: WorkflowOptionsType = { ...workflowOptions, ...ref };
      submitWorkflowQuery.mutate(payload);
    },
  });
  const batchJobQuery: UseQueryResult<BatchResult> = useQuery({
    queryKey: ['useWorkflow', 'retrieveResultByWorkflowIdAndBatchId', submitWorkflowQuery.data],
    refetchInterval: (query) => {
      if (!submitWorkflowQuery.data) return false;
      if (!submissionTime) return false;
      try {
        const {
          state: { data },
        } = query;

        const status = data?.jobs?.[0].status;
        return status === 'RUNNING' ? pollingInterval : false;
      } catch {
        return false;
      }
    },
    refetchIntervalInBackground: true,
    queryFn: async ({ signal }) => {
      const workflowTime = Date.now() - submissionTime!;
      signal.onabort = () => {
        trackMetrics({ [Metrics.Names.Error]: Metrics.Values.Abandoned }, { [Metrics.Names.Time]: workflowTime, [Metrics.Values.Abandoned]: 1 });
        setSubmissionTime(undefined);
        submitWorkflowQuery.reset();
        uploadFileQuery.reset();
      };
      if (Date.now() - submissionTime! > timeout) {
        trackMetrics({}, { [Metrics.Names.Time]: workflowTime, [Metrics.Counters.Timeout]: 1 });
        setSubmissionTime(undefined);
        throw new Error('Job timed out');
      }
      const { body } = <{ body: BatchResult }>await backendClient!.retrieveResultByWorkflowIdAndBatchId({
        workflowId: workflowId,
        batchId: batchId!,
        studioRequest,
      });

      // load images at background before complete the query
      if (body.jobs?.[0].status === 'COMPLETED') {
        for (let i = 0; i < body.jobs.length; i++) {
          body.jobs[i].originalUrls = body.jobs[i].urls || [];
          body.jobs[i].urls = await Promise.all(
            (body.jobs[i].urls || []).map(async (url) =>
              URL.createObjectURL(await urlToFile(url, body.type === AssetType.VIDEO ? 'video/mp4' : 'image/png')),
            ),
          );
        }

        const urls = body.jobs?.[0]?.urls || [];

        // preload for image only; video assets natively preload on the <video /> element
        if (body.type !== AssetType.VIDEO) {
          const promises = urls.map((url) => {
            const img = new Image();
            img.src = url;
            return new Promise((resolve) => (img.onload = resolve));
          });
          await Promise.all(promises);
        }

        trackMetrics(
          {},
          {
            [Metrics.Counters.ImagesGenerated]: urls.length,
            [Metrics.Names.Time]: workflowTime,
            [Metrics.Counters.Success]: 1,
          },
        );
      }

      if (body.jobs?.[0].status !== 'RUNNING') {
        if (body.jobs?.[0].status == 'FAILED' || body.jobs?.[0].status == 'HALTED') {
          console.log('workflowmetrics failed', body.jobs[0].message, body.jobs?.[0].status);
          trackMetrics({ [Metrics.Names.Error]: body.jobs[0].message || '' }, { [Metrics.Names.Time]: workflowTime, [Metrics.Counters.Failure]: 1 });
        }
        setSubmissionTime(undefined);
      }

      return body;
    },
    enabled: (query: Query<BatchResult>) => {
      const {
        state: { data },
      } = query;
      const status = data?.jobs?.[0].status;
      return !!submitWorkflowQuery.data && submissionTime !== undefined && status !== 'COMPLETED';
    },
  });

  useEffect(() => {
    setPendingGeneration?.(uploadFileQuery.isPending || submitWorkflowQuery.isPending || !!submissionTime);
  }, [uploadFileQuery.isPending, submitWorkflowQuery.isPending, submissionTime]);

  return {
    workflowOptions,
    updateWorkflowOptions: (value: Partial<WorkflowOptionsType>) => setWorkflowOptions({ ...workflowOptions, ...value }),
    submitWorkflow: async (payload?: UploadFilesPayload) => {
      if (!workflowOptions) {
        return;
      }
      if (payload) uploadFileQuery.mutate(payload);
      else submitWorkflowQuery.mutate(workflowOptions);
    },
    uploadFileQuery,
    workflowQuery: submitWorkflowQuery,
    submissionQuery: batchJobQuery,
    isPending: uploadFileQuery.isPending || submitWorkflowQuery.isPending || !!submissionTime,
    isError: uploadFileQuery.isError || submitWorkflowQuery.isError || batchJobQuery.isError,
    error: uploadFileQuery.error || submitWorkflowQuery.error || batchJobQuery.error,
  };
};
