import { Draft } from 'immer';
import { SnackbarState, TimeoutRef, ActionHandlerReturn } from 'src/v2/contexts/snackbar/types';

interface HandleActionRemoveAllNotificationsProps {
  state: Draft<SnackbarState>;
  timeoutsRef: TimeoutRef;
}

export const handleActionRemoveAllNotifications = ({ state, timeoutsRef }: HandleActionRemoveAllNotificationsProps): ActionHandlerReturn => {
  Object.keys(timeoutsRef).forEach((id) => {
    clearTimeout(timeoutsRef[id].id);
    delete timeoutsRef[id];
  });
  state.notifications = [];
  state.expanded = false;
};
