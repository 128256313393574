import { Text } from '@amzn/storm-ui';
import { memo, useEffect, useState } from 'react';
import { filterStudioImageUploadFiles } from 'src/components/utils/ImageUtils';
import UploadImageIcon from 'src/icons/uploadImage.svg';
import { checkVisibility } from 'src/v2/utils/DomUtils';
import { getFilesFromFileSystemEntry } from 'src/v2/utils/FileSystemEntry.utils';
import styles from './StudioFileUploadDropzone.module.scss';

export const TEST_ID_STUDIO_FILE_UPLOAD_DROPZONE = 'studio-file-upload-dropzone';
export const CLASS_DRAG_IN_PROGRESS = 'drag-in-progress';
export const BLOCK_STUDIO_FILE_UPLOAD_DROPZONE_CLASSNAME = 'block-studio-file-upload-dropzone-class';

const StudioDropzone = memo((props: { show: boolean }) => {
  return (
    <div className={`${styles.studioFileUploadDropzone} ${props.show ? styles.show : ''}`} data-testid={TEST_ID_STUDIO_FILE_UPLOAD_DROPZONE}>
      <div className={styles.innerContainer}>
        <UploadImageIcon />
        <Text className={styles.text} renderAs="span">
          Add to feed
        </Text>
      </div>
    </div>
  );
});
StudioDropzone.displayName = 'StudioDropzone';

interface StudioFileUploadDropzoneProps {
  /**
   * Callback called after files dropped and processed.
   * @param props
   * @param props.files - list of filtered files
   * @param props.filtered - flag indicating if the dropped set of files was filtered
   * @param props.errored - flag indicating one or more errors occurred when handling the dropped files
   * @returns
   */
  onDropFiles?: (props: { files: File[]; filtered: boolean; errored: boolean }) => void;
}

export const StudioFileUploadDropzone = memo(({ onDropFiles }: StudioFileUploadDropzoneProps) => {
  const [showStudioDropzone, setShowStudioDropzone] = useState(false);

  const handleDragEnter = (dragEvent: DragEvent) => {
    const blockerEls = document.querySelectorAll(`.${BLOCK_STUDIO_FILE_UPLOAD_DROPZONE_CLASSNAME}`);
    let isBlockerVisible = false;
    for (let i = 0; i < blockerEls.length; i++) {
      const blockerEl = blockerEls.item(i);
      if (checkVisibility(blockerEl as HTMLElement)) {
        isBlockerVisible = true;
        break;
      }
    }

    if (isBlockerVisible) return;

    dragEvent.preventDefault();
    dragEvent.stopPropagation();
    document.body.classList.add(CLASS_DRAG_IN_PROGRESS);
    if (dragEvent.target === document.body) {
      setShowStudioDropzone(true);
    }
  };

  const handleDragLeave = (dragEvent: DragEvent) => {
    if (!document.body.classList.contains(CLASS_DRAG_IN_PROGRESS)) return;
    if (dragEvent.target === document.body) {
      dragEvent.preventDefault();
      dragEvent.stopPropagation();
      document.body.classList.remove(CLASS_DRAG_IN_PROGRESS);
      setShowStudioDropzone(false);
    }
  };

  const handleDragOver = (dragEvent: DragEvent) => {
    if (!document.body.classList.contains(CLASS_DRAG_IN_PROGRESS)) return;
    // Prevent file being opened by the browser
    dragEvent.preventDefault();
  };

  const handleDrop = async (dragEvent: DragEvent) => {
    if (!document.body.classList.contains(CLASS_DRAG_IN_PROGRESS)) return;
    dragEvent.preventDefault();
    document.body.classList.remove(CLASS_DRAG_IN_PROGRESS);
    setShowStudioDropzone(false);

    // Gather Files
    let files: File[] = [];
    let filtered = false;
    let errored = false;
    const items = dragEvent?.dataTransfer?.items;
    if (items) {
      const fileSystemEntries = [];
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const fileSystemEntry = item.webkitGetAsEntry();
        if (fileSystemEntry) fileSystemEntries.push(fileSystemEntry);
      }
      const results = await Promise.allSettled(fileSystemEntries.map((fileSystemEntry) => getFilesFromFileSystemEntry(fileSystemEntry)));
      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          files.push(...result.value.files);
          errored = errored || result.value.errored;
        } else if (result.status === 'rejected') {
          errored = true;
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      files = Array.from(dragEvent?.dataTransfer?.files ?? []);
    }

    // Filter
    if (files.length) {
      const result = filterStudioImageUploadFiles({ files });
      if (result.filtered) {
        filtered = true;
      }
      files = result.files;
    }

    onDropFiles?.({ files, filtered, errored });
  };

  useEffect(() => {
    document.addEventListener('dragenter', handleDragEnter);
    document.addEventListener('dragleave', handleDragLeave);
    document.addEventListener('dragover', handleDragOver);
    document.addEventListener('drop', handleDrop);
    return () => {
      document.body.classList.remove(CLASS_DRAG_IN_PROGRESS);
      document.removeEventListener('dragenter', handleDragEnter);
      document.removeEventListener('dragleave', handleDragLeave);
      document.removeEventListener('dragover', handleDragOver);
      document.removeEventListener('drop', handleDrop);
    };
  }, []);

  return (
    <>
      <StudioDropzone show={showStudioDropzone} />
    </>
  );
});
StudioFileUploadDropzone.displayName = 'StudioFileUploadDropzone';
